import * as React from 'react';

export default () => {

    return (
        <div className="container">
            <h1>404</h1>
        </div>
    )
}
