import {Link, useHistory} from "react-router-dom";
import React from "react";
import * as UserApi from "../../http/UserApi";
import {connect} from 'react-redux';
import { useTranslation, Trans } from "react-i18next";

const DestroyProfileConfirmation = (props) => {

    let history = useHistory();
    const { t } = useTranslation();

    async function deleteProfile(e) {
        e.preventDefault();
        await UserApi.deleteUser();
        history.push("/deleted");
    }

    function cancelDeletion(e) {
        e.preventDefault();
        history.push("/");
    }

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/cancel_red.svg"}
                            alt={"Remove"}/>
                        <p className={"msg m-t-20"}>
                            <strong>
                                <Trans i18nKey="profile:delete.deleteConfirmation"><br /></Trans>
                            </strong>
                        </p>
                    </div>

                    <div className="message-buttons text-center">

                        <div className="mb-3">
                            <button className={"btn btn-primary waves-effect btn-wide"}
                                    onClick={deleteProfile}>
                                {t('profile:delete.deleteProfile')}
                            </button>
                        </div>

                        <a href="#" onClick={cancelDeletion} style={{"color": "red"}}>
                            {t('profile:delete.cancel')}
                        </a>

                    </div>

                </div>
            </div>
        </div>
    );
};



export default connect()(DestroyProfileConfirmation);