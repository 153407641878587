import * as daAuthNs from "./auth.json";
import * as daChangeEmailNs from "./changeEmail.json";
import * as daChangePasswordNs from "./changePassword.json";
import * as daCommonNs from "./common.json";
import * as daContactNs from "./contact.json";
import * as daCookiesNs from "./cookies.json";
import * as daDashboardNs from "./dashboard.json";
import * as daFAQNs from "./faq.json";
import * as daForgotPasswordNs from "./forgotPassword.json";
import * as daInterestsNs from "./interests.json";
import * as daNewslettersNs from "./newsletters.json";
import * as daMenuNs from "./sideMenu.json";
import * as daPaymentNs from "./payment.json";
import * as daProfileNs from "./profile.json";
import * as daShippingAddressNs from "./shippingAddress.json";
import * as daSubscriptionNs from "./subscription.json";
import * as daTermsNs from "./terms.json";
import * as daPackage from "./package.json";

export default {
    sideMenu: daMenuNs,
    auth: daAuthNs,
    changeEmail: daChangeEmailNs,
    changePassword: daChangePasswordNs,
    common: daCommonNs,
    contact: daContactNs,
    cookies: daCookiesNs,
    dashboard: daDashboardNs,
    faq: daFAQNs,
    forgotPassword: daForgotPasswordNs,
    interests: daInterestsNs,
    newsletters: daNewslettersNs,
    payment: daPaymentNs,
    profile: daProfileNs,
    shippingAddress: daShippingAddressNs,
    subscription: daSubscriptionNs,
    terms: daTermsNs,
    package: daPackage
}