export const validate = (t) => {
    return values => {
        const errors = {};

        if (!values.email) {
            // validate:emailRequired
            errors.email = t('forgotPassword:validate.emailRequired');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            // validate:invalidEmail
            errors.email = t('forgotPassword:validate.invalidEmail');
        }

        return errors;
    }
}
