export const validate = (t) => {

    return values => {
        const errors = {};

        if (!values.subject) {
            errors.subject = t('contact:validate.subjectRequired');
        }

        if (!values.emailBody) {
            errors.emailBody = t('contact:validate.bodyRequired');
        }

        return errors;
    };
}