import React from 'react';
import * as Utils from '../commonComponents/Utils';
import {useTranslation, Trans} from "react-i18next";

let PaymentInfoPanel = (props) => {

    const { t } = useTranslation();

    let mtShown = false;

    const iterasPackage = props.iterasPackage;
    let packageTitle = iterasPackage.packageName;
    let giftCard = false;
    if (iterasPackage.abonnements[0].campaignId == 'full' && iterasPackage.packagePrice == null && iterasPackage.annualPrice == null){
        giftCard = true;
    }
    return (
        <div className={"row"}>
            <div className={"col-sm-10 offset-sm-1"}>

                <div className={"row m-b-10"}>
                    <div className={"col"}>
                        <div className="card">
                            <div className="card-body">

                                <strong>{t("payment:yourPayment.package")}</strong> {Utils.capitalizeFirstLetter(iterasPackage.portalName)}, {packageTitle}

                            </div>
                        </div>
                    </div>
                </div>

                {
                    !iterasPackage.isPackageCanceled &&

                    <div className={"row m-b-10"}>
                        <div className={"col"}>

                            <div className={"card-deck"}>
                                <div className={"card card-left"}>
                                    <div className={"card-body"}>
                                        <strong>{t("payment:yourPayment.status")}</strong>
                                        {
                                            iterasPackage.subscriptionCreatedAt &&
                                            " " + t("payment:yourPayment.activeSince") + " " +
                                            Utils.formatToDanishDate(iterasPackage.subscriptionCreatedAt)
                                        }
                                    </div>
                                </div>
                                <div className={"card card-right"}>
                                    <div className={"card-body"}>
                                        {
                                            iterasPackage.abonnements[0].campaignId == 'full'
                                            && iterasPackage.packagePrice == null
                                            && iterasPackage.annualPrice == null
                                            &&
                                                <strong>{t("payment:yourPayment.paymentGiftCard")}</strong>
                                        }
                                        {
                                            iterasPackage.packagePrice != null
                                            &&
                                            <strong>{t("payment:yourPayment.nextDate")}</strong>
                                        }
                                        {
                                            iterasPackage.annualPrice != null
                                            &&
                                            <strong>{t("payment:yourPayment.nextDate")}</strong>
                                        }

                                        {
                                            iterasPackage.nextPaymentDate &&
                                            " " + Utils.formatToDanishDate(iterasPackage.nextPaymentDate)
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {
                    console.clear()
                }

                {
                    console.log(iterasPackage)
                }

                {
                    iterasPackage.isPackageCanceled &&
                    iterasPackage.portalName !== "maskinteknik" &&

                    <div className={"row m-b-10"}>
                        <div className={"col"}>
                            <div className={"card-deck"}>
                                <div className={"card card-left"}>
                                    <div className={"card-body"}>
                                        <strong>{t("payment:yourPayment.status")}</strong> {t("payment:yourPayment.dismissed")}
                                    </div>
                                </div>
                                <div className={"card card-right"}>
                                    <div className={"card-body"}>
                                        <strong>{t("payment:yourPayment.expirationDates")}</strong><br/>
                                        {
                                            iterasPackage
                                                .canceledCampaigns
                                                .filter(campaign => campaign.campaignId !== 'users_mb')
                                                .map((campaign, index) => {

                                                    let campaignName = campaign.campaignId;

                                                    if (campaign.campaignId.includes('full') || campaign.campaignId.includes('mt_')) {
                                                        campaignName = iterasPackage.packageName;
                                                    }

                                                    return (
                                                        <span key={index}>
                                                            {Utils.capitalizeFirstLetter(campaignName)}: {Utils.formatToDanishDate(campaign.endDate)}<br/>
                                                        </span>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    iterasPackage.isPackageCanceled &&
                    iterasPackage.portalName === "maskinteknik" &&

                    <div className={"row m-b-10"}>
                        <div className={"col"}>
                            <div className={"card-deck"}>
                                <div className={"card card-left"}>
                                    <div className={"card-body"}>
                                        <strong>{t("payment:yourPayment.status")}</strong> {t("payment:yourPayment.dismissed")}
                                    </div>
                                </div>
                                <div className={"card card-right"}>
                                    <div className={"card-body"}>
                                        <strong>{t("payment:yourPayment.expirationDates")}</strong><br/>
                                        {
                                            iterasPackage
                                                .canceledCampaigns
                                                .filter(campaign => campaign.campaignId !== 'users_mb')
                                                .filter(campaign => campaign.campaignId !== 'agro')
                                                .filter(campaign => campaign.campaignId !== 'bovi')
                                                .filter(campaign => campaign.campaignId !== 'hyo')
                                                .map((campaign, index) => {

                                                    if(mtShown) return ('')

                                                    let campaignName = campaign.campaignId;

                                                    if (campaign.campaignId.includes('full') || campaign.campaignId.includes('mt_')) {
                                                        campaignName = iterasPackage.packageName;
                                                    }

                                                    mtShown=true;

                                                    return (
                                                        <span key={index}>
                                                            {Utils.capitalizeFirstLetter(campaignName)}: {Utils.formatToDanishDate(campaign.endDate)}<br/>
                                                        </span>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={"row m-b-20"}>
                    <div className={"col"}>
                        <div className="card">
                            <div className="card-body">
                                <strong>{t("payment:yourPayment.paymentMethod")} </strong>

                                {giftCard === true
                                    ? <span>{t("payment:yourPayment.giftCard")}</span>
                                    : [
                                        (null === iterasPackage.paymentAgreement
                                                ? t("payment:yourPayment.invoice")
                                                : t("payment:yourPayment.auto")
                                        ),

                                        <br/>,
/*
                                        (null !== iterasPackage.paymentAgreement && iterasPackage.paymentAgreement.includes('XX')
                                                ? <div className="creditcard-change-wrapper">
                                                    <a className="creditcard-change-btn" href="/#/skift-betalingsmetode">blah{t("payment:yourPayment.changeCreditCard")}&nbsp;&nbsp;&nbsp;
                                                        <img className="edit-img" src="../../../public/assets/images/design/icons/pencil.svg" alt="Ret"/></a>
                                                </div>
                                                : ''
                                        )
*/
                                    ]
                                }

                                {' '} {iterasPackage.paymentAgreement}
                            </div>
                            {
                                null !== iterasPackage.paymentAgreement && iterasPackage.paymentAgreement.includes('XX')
                                    ? <div className="creditcard-change-wrapper"><a className="creditcard-change-btn" href="/#/skift-betalingsmetode">{t("payment:yourPayment.changeCreditCard")}&nbsp;&nbsp;&nbsp;<img className="edit-img" src="../../../public/assets/images/design/icons/pencil.svg" alt="Ret"/></a></div>
                                    : ''
                            }

                            {
                                null === iterasPackage.paymentAgreement
                                    ? <div className="creditcard-change-wrapper"><a className="creditcard-change-btn" href="/#/skift-betalingsmetode">{t("payment:yourPayment.changeToCreditCard")}&nbsp;&nbsp;&nbsp;<img className="edit-img" src="../../../public/assets/images/design/icons/pencil.svg" alt="Ret"/></a></div>
                                    : ''
                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PaymentInfoPanel;