import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as AuthAPI from "../../http/AuthAPI";
import * as tokenProviderService from "../../services/TokenProviderService";
import {loginSuccess} from "../../actions/authActions";
import {loginAttemptSuccess} from "../../actions/authActions";;
import OAuthLoginFailed from "./OAuthLoginFailed";

let LinkedInTokenLogin = (props) => {

    const API_ERR_USER_ALREADY_EXISTS = 1;
    const API_ERR_INVALID_SOCIAL_ACCOUNT = 2;

    const NOT_LOGGED_IN_STATUS = 0;
    const LOGIN_FAILED_STATUS = 1;
    const LOGIN_SUCCESS_STATUS = 2;

    let [loginState, setLoginState] = useState(NOT_LOGGED_IN_STATUS);
    let [errMsg, setErrMsg] = useState('');

    useEffect(()=> {
        (async () => {
            const accessToken = window.location.href.split('?')[1].replace('accessToken=', '');

            let response = await AuthAPI.linkedInLogin(accessToken);
            if (response.status === 200) {
                let credentials = await response.json();
                await tokenProviderService.setCredentials(
                    credentials.token,
                    credentials.refresh_token,
                    false
                );
                props.dispatch(loginSuccess());
                props.dispatch(loginAttemptSuccess());
                setLoginState(LOGIN_SUCCESS_STATUS);
            } else {
                let responseBody = await response.json();

                switch (responseBody.code) {
                    case API_ERR_INVALID_SOCIAL_ACCOUNT:
                        setErrMsg('This social account does not have attached email');
                        break;

                    case API_ERR_USER_ALREADY_EXISTS:
                        setErrMsg('User with this linkedin Id or email already exists');
                        break;

                    default:
                        setErrMsg('Unknown error');
                }

                setLoginState(LOGIN_FAILED_STATUS);
            }

        })();
    }, []);

    if (loginState === LOGIN_FAILED_STATUS) {
        return <OAuthLoginFailed errMsg={errMsg} />;
    }

    return (
        <div>Processing</div>
    )
};

export default connect()(LinkedInTokenLogin);
