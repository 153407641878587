import React, {useEffect, useState} from 'react';
import * as authActions from "../../actions/authActions";
import {connect} from 'react-redux';
import * as AuthAPI from "../../http/AuthAPI";
import * as tokenProviderService from "../../services/TokenProviderService";
import {loginSuccess} from "../../actions/authActions";
import {loginAttemptSuccess} from "../../actions/authActions";
import OAuthLoginFailed from "./OAuthLoginFailed";
import {updateLastLoginTime} from "../../http/UserApi";
import {useTranslation, Trans} from "react-i18next";

let FacebookTokenLogin = (props) => {

    const API_ERR_USER_ALREADY_EXISTS = 1;
    const API_ERR_INVALID_SOCIAL_ACCOUNT = 2;

    const NOT_LOGGED_IN_STATUS = 0;
    const LOGIN_FAILED_STATUS = 1;
    const LOGIN_SUCCESS_STATUS = 2;

    const { t } = useTranslation();
    let [loginState, setLoginState] = useState(NOT_LOGGED_IN_STATUS);
    let [errMsg, setErrMsg] = useState('');

    useEffect(()=> {
        (async () => {
            const accessToken = window.location.href.split('?')[1].replace('accessToken=', '');

            let response = await AuthAPI.facebookLogin(accessToken);
            if (response.status === 200) {
                let credentials = await response.json();
                await tokenProviderService.setCredentials(
                    credentials.token,
                    credentials.refresh_token,
                    false
                );
                props.dispatch(loginSuccess());
                props.dispatch(loginAttemptSuccess());
                await updateLastLoginTime();
                setLoginState(LOGIN_SUCCESS_STATUS);
            } else {
                let responseBody = await response.json();

                switch (responseBody.code) {
                    case API_ERR_INVALID_SOCIAL_ACCOUNT:
                        setErrMsg( t("auth:errors.noEmail") );
                        break;

                    case API_ERR_USER_ALREADY_EXISTS:
                        setErrMsg( t("auth:errors.userExists") );
                        break;

                    default:
                        setErrMsg( t("auth:errors.unknown") );
                }

                setLoginState(LOGIN_FAILED_STATUS);
            }

        })();
    }, []);

    if (loginState === LOGIN_FAILED_STATUS) {
        return <OAuthLoginFailed errMsg={errMsg} />;
    }

    return (
        <div>{ t("auth:fbWait") }</div>
    )
};

export default connect()(FacebookTokenLogin);
