import * as AuthApi from '../http/AuthAPI';
import * as PortalAPI from '../http/PortalAPI';

const ACCESS_TOKEN_NAME = 'jwt-access-token';
const REFRESH_TOKEN_NAME = 'jwt-refresh-token';
const CREDENTIALS_EXPIRATION_NAME = 'jwt-credentials-expiration-date';
const PORTAL_EXTERNAL_ID_NAME = 'jwt-portal-external-id';
const PORTAL_RETURN_URL = 'jwt-portal-return-url';

export function setCredentials(
    accessToken,
    refreshToken,
    rememberMe,
    portalExternalId = '',
    portalReturnUrl = ''
) {
    window.localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
    window.localStorage.setItem(PORTAL_EXTERNAL_ID_NAME, portalExternalId);
    window.localStorage.setItem(PORTAL_RETURN_URL, portalReturnUrl);

    // console.log('---------------- set credentials')
    // console.log('---------------- debug credentials');
    // console.log('ACCESS_TOKEN_NAME', window.localStorage.getItem(ACCESS_TOKEN_NAME));
    // console.log('REFRESH_TOKEN_NAME', window.localStorage.getItem(REFRESH_TOKEN_NAME));
    // console.log('CREDENTIALS_EXPIRATION_NAME', window.localStorage.getItem(CREDENTIALS_EXPIRATION_NAME));
    // console.log('PORTAL_EXTERNAL_ID_NAME', window.localStorage.getItem(PORTAL_EXTERNAL_ID_NAME));
    // console.log('PORTAL_RETURN_URL', window.localStorage.getItem(PORTAL_RETURN_URL));
    // console.log('---------------- end debug credentials');

    let tokensExpiredAfter = new Date();

    if (rememberMe) {
        tokensExpiredAfter.setDate(tokensExpiredAfter.getDate() + 5);
    } else {
        tokensExpiredAfter.setDate(tokensExpiredAfter.getDate() + 1)
    }

    window.localStorage.setItem(CREDENTIALS_EXPIRATION_NAME, tokensExpiredAfter.getTime().toString());
}

export function removeCredentials() {
    // console.log('---------------- remove credentials')
    // console.log('---------------- debug credentials');
    // console.log('ACCESS_TOKEN_NAME', window.localStorage.getItem(ACCESS_TOKEN_NAME));
    // console.log('REFRESH_TOKEN_NAME', window.localStorage.getItem(REFRESH_TOKEN_NAME));
    // console.log('CREDENTIALS_EXPIRATION_NAME', window.localStorage.getItem(CREDENTIALS_EXPIRATION_NAME));
    // console.log('PORTAL_EXTERNAL_ID_NAME', window.localStorage.getItem(PORTAL_EXTERNAL_ID_NAME));
    // console.log('PORTAL_RETURN_URL', window.localStorage.getItem(PORTAL_RETURN_URL));
    // console.log('---------------- end debug credentials');

    window.localStorage.removeItem(ACCESS_TOKEN_NAME);
    window.localStorage.removeItem(REFRESH_TOKEN_NAME);
    window.localStorage.removeItem(CREDENTIALS_EXPIRATION_NAME);
    window.localStorage.removeItem(PORTAL_EXTERNAL_ID_NAME);
    window.localStorage.removeItem(PORTAL_RETURN_URL);
}

export function getPortalReturnUrl() {
    let returnUrl = window.localStorage.getItem(PORTAL_RETURN_URL);

    if (!returnUrl) {
        return null;
    }

    return returnUrl;
}

export function getPortal() {
    let externalPortalId = window.localStorage.getItem(PORTAL_EXTERNAL_ID_NAME);

    if (!externalPortalId) {
        return null;
    }

    return externalPortalId;
}

export function getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN_NAME);
}

/**
 * Get locally stored access token or refresh new one
 * @returns {Promise<*>}
 */
export async function getAccessToken() {
    const localAccessToken = window.localStorage.getItem(ACCESS_TOKEN_NAME);
    const localRefreshToken = window.localStorage.getItem(REFRESH_TOKEN_NAME);
    const expirationOfCredentials = window.localStorage.getItem(CREDENTIALS_EXPIRATION_NAME);

    if (!localAccessToken || !localRefreshToken || !expirationOfCredentials) {
        //console.log('getAccessToken() - Token was removed, no data in localStorage')
        removeCredentials();
        return null;
    }

    const currentTimestamp = (new Date()).getTime();

    if (currentTimestamp >= expirationOfCredentials) {
        //console.log('getAccessToken() - timestamp expired');
        removeCredentials();
        return null;
    }

    const expirationTokenTimestamp = getAccessTokenExpirationDate(localAccessToken) || null;

    if ( currentTimestamp >= expirationTokenTimestamp  ) {
        const response = await AuthApi.refreshAccessToken(localRefreshToken);
        if (response.status !== 200) {
            //console.log('getAccessToken() - cannot refresh access token');
            removeCredentials();
            return null;
        }

        let credentials = await response.json();
        window.localStorage.setItem(ACCESS_TOKEN_NAME, credentials.token);
        window.localStorage.setItem(REFRESH_TOKEN_NAME, credentials.refresh_token);

        return credentials.token;
    }

    return localAccessToken;
}

export function getAccessTokenExpirationDate(jwtToken) {
    try {
        if (!jwtToken) {
            return null;
        }

        const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
        return jwt && jwt.exp && jwt.exp * 1000 || null;
    } catch (e) {
        return null;
    }
}



