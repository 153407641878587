import React, {useState} from "react";
import {connect} from "react-redux";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordInfo from "./ForgotPasswordInfo";
import ForgotPasswordFailedInfo from "./ForgotPasswordFailedInfo";
import * as ResetPasswordApi from "../../../http/ResetPasswordApi";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";

const ForgotPassword = () => {

    const RESET_LINK_NOT_CREATED = 0;
    const RESET_LINK_CREATION_FAILED = 1;
    const RESET_LINK_CREATION_SUCCESS = 2;

    const TOO_MANY_REQUESTS_ERR = 1;
    const USER_NOT_FOUND_ERR = 2;

    const { t } = useTranslation();

    let [creationStatus, setCreationStatus] = useState(RESET_LINK_NOT_CREATED);
    let [errorMsg, setErrorMsg] = useState(t("forgotPassword:errors.unknown"));

    function resendEmail(e) {
        e.preventDefault();
        setCreationStatus(RESET_LINK_NOT_CREATED);
    }

    async function handleFormSubmit (values) {

        console.debug(values);

        let response = await trackPromise(ResetPasswordApi.createResetPasswordRequest(values.email, values.portalName));

        if (response.status === 400) {
            let responseBody = await response.json();
            switch (responseBody.code) {
                case TOO_MANY_REQUESTS_ERR:
                    setErrorMsg(t("forgotPassword:errors.tooManyRequests"));
                    break;
                case USER_NOT_FOUND_ERR:
                    setErrorMsg(t("forgotPassword:errors.emailNotFound"));
                    break;
                default:
                    setErrorMsg(t("forgotPassword:errors.unknown"));
            }

            setCreationStatus(RESET_LINK_CREATION_FAILED);
            return;
        }

        if (response.status !== 200) {
            setErrorMsg(t("forgotPassword:errors.unknown"));
            setCreationStatus(RESET_LINK_CREATION_FAILED);
            return;
        }

        setCreationStatus(RESET_LINK_CREATION_SUCCESS);
    }

    if (creationStatus === RESET_LINK_CREATION_SUCCESS) {
        return (
            <div className="d-flex flex-column h-100">
                <ForgotPasswordInfo />
            </div>
        )
    }

    if (creationStatus === RESET_LINK_CREATION_FAILED) {
        return (
            <div className="d-flex flex-column h-100">
                <ForgotPasswordFailedInfo
                    clearPasswordReset={resendEmail}
                    hashErrorMessage={errorMsg}
                />
            </div>
        )
    }

    return (
        <div className="d-flex flex-column h-100">

            <ForgotPasswordForm onSubmit={handleFormSubmit} />

        </div>
    )

};

export default connect()(ForgotPassword);