//https://habr.com/en/post/485764/

import * as TokenProviderService from './TokenProviderService';
import ReduxStore from '../store/store';
import * as AuthActions from '../actions/authActions';

export const AuthHttpClient = async (input, init = {}) => {

    const accessToken = await TokenProviderService.getAccessToken();

    init.headers = {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
    };

    const response = await fetch(input, init);
    const logoutHttpCodes = [401];

    if (accessToken && logoutHttpCodes.includes(response.status)) {
        ReduxStore.dispatch(AuthActions.logout());
    }

    return response;
};