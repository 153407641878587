import React, {useEffect, useState} from 'react';
import * as ProfileApi from "../../http/UserProfileApi";
import {useHistory} from 'react-router-dom';
import {trackPromise} from "react-promise-tracker";
import OAuthBindFailed from "./OAuthBindFailed";

let BindLinkedinAccount = (props) => {
    const historyHook = useHistory();

    const USER_ALREADY_EXISTS_ERR = 2;

    const NOT_BINDED_STATUS = 1;
    const BIND_SUCCESSFUL_STATUS = 2;
    const BIND_FAILED_STATUS = 3;

    let [bindStatus, setBindStatus] = useState(NOT_BINDED_STATUS);
    let [errorMsg, setErrorMsg] = useState('');


    useEffect(()=> {
        (async () => {
            const accessToken = window.location.href.split('?')[1].replace('accessToken=', '');
            let response = await trackPromise(ProfileApi.bindLinkedInAccount(accessToken));

            if (response.status === 200) {
                setBindStatus(BIND_SUCCESSFUL_STATUS);
                historyHook.push('/profiloversigt');
            } else {
                let responseBody = await response.json();
                if (responseBody.code === USER_ALREADY_EXISTS_ERR) {
                    setErrorMsg('Denne LinkedIn konto er allerede knyttet til en anden Medielogin bruger');
                } else {
                    setErrorMsg('Unknown error');
                }
                setBindStatus(BIND_FAILED_STATUS);
            }

        })();
    }, []);

    if (bindStatus === BIND_FAILED_STATUS) {
        return <OAuthBindFailed errMsg={errorMsg} />
    }

    return (
        <div></div>
    )
};

export default BindLinkedinAccount;
