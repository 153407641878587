import React from "react";
import {useTranslation, Trans} from "react-i18next";

const Modal = (props) => {
    const { show, closeModal } = props;

    const { t } = useTranslation();

    return (
        <div className={show ? "overlay" : "hide"} >
            <div className={show ? "contactModal" : "hide"}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5 className="font-18">{t("subscription:changeSubUsersModal.wantChange")}</h5>
                            <p className="text-muted" dangerouslySetInnerHTML={{__html: t("subscription:changeSubUsersModal.contactPhone")}} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 text-right">
                            <button onClick={closeModal} className="btn btn-secondary">{t("subscription:changeSubUsersModal.undo")}</button>
                        </div>
                        <div className="col-6 text-left">
                            <a href="tel: 96 26 52 99" className="btn btn-primary">{t("subscription:changeSubUsersModal.callUp")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;