import React from "react";
import {Accordion, Card} from "react-bootstrap";

const MenuCard = (props) => {

    // function changeBackground(e) {
    //     let target = e;
    //     var changeImage = true;
    //     if (e.target.parentElement.className == 'card') {
    //         if (e.target.parentElement.children[0].className.includes('nav-active')) {
    //             changeImage = false;
    //         }
    //     } else if (e.target.parentElement.className.includes('nav-active')) {
    //         changeImage = false;
    //     }
    //
    //     if (changeImage == true) {
    //         e.target.parentElement.querySelector('img').src = e.target.parentElement.querySelector('img').src.replace("_black", "");
    //     }
    // }
    //
    // function changeBackgroundOut(e) {
    //     var insertText = '_black';
    //     var changeImage = true;
    //     var str = e.target.parentElement.querySelector('img').src;
    //     if (!str.includes('_black')) {
    //
    //         if (e.target.parentElement.className == 'card') {
    //             if (e.target.parentElement.children[0].className.includes('nav-active')) {
    //                 changeImage = false;
    //             }
    //         } else if (e.target.parentElement.className.includes('nav-active')) {
    //             changeImage = false;
    //         }
    //         if (changeImage == true){
    //         var n = str.lastIndexOf(".");
    //         var str2 = str.substring(0, n) + insertText + str.substring(n);
    //
    //         e.target.parentElement.querySelector('img').src = str2;
    //         }
    //     }
    // }

    const cardHeaderStyle = (props.isActive) ? 'nav-active' : '';
    const iconColor = (props.isActive) ? '' : '_black';
    const open = props.isActive;

    return (
        <Card>
            <Accordion.Toggle
                as={Card.Header}
                eventKey={props.eventKey}
                className={`has-children ${cardHeaderStyle}`}
                onClick={props.onClick}
            >

                {/*<img style={{width:"17px"}}*/}
                {/*    src={`../../../public/assets/images/design/icons/${props.iconName}${iconColor}.svg`}*/}
                {/*    alt={props.title} />*/}
                {/*<div className={`menu-image ${props.iconName} ${iconColor} ${open}`}></div>*/}

                <span className={`sidebar-icon fbg-icons fbg-icons-${props.iconName}`}></span>

                {props.title}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.eventKey} in={open} >
                <Card.Body>
                    {props.children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default MenuCard;