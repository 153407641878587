import {Form, Formik} from "formik";
import InputTextField from "../commonComponents/InputTextField";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import * as AuthApi from "../../http/AuthAPI";
import * as ProfileApi from "../../http/UserProfileApi";
import {trackPromise} from "react-promise-tracker";
import {useHistory} from "react-router-dom";
import FormErrors from "../commonComponents/FormErrors";

const UploadAvatar = (props) => {

    let history = useHistory();
    let [currentUser, setCurrentUser] = useState({
        profilePicture: null
    });

    useEffect(() => {
        (async() => {
            let response = await AuthApi.getCurrentUser();
            if (response.status === 200) {
                let responseBody = await trackPromise(response.json());
                setCurrentUser(responseBody);
            } else {
                history.push("/");
            }
        })();
    }, []);

    async function handleAvatarUpload(values, {setFieldError}) {
        let response = await ProfileApi.uploadAvatar(values.avatar);
        if (response.status === 200) {
            history.push("/profiloversigt");
        } else {
            setFieldError('avatar', 'Invalid file');
        }
    }

    async function handleRemoveAvatar(e) {
        e.preventDefault();
        await ProfileApi.removeAvatar();
        history.push("/profiloversigt");
    }

    return (
        <div id={"edit-profile"}>

            <div className={"row m-b-30 edit-profile-icon"}>
                <div className={"col text-center"}>

                    {
                        currentUser.profilePicture &&
                        <img className="rounded-circle" src={MEMBERS_API_URL + "/uploads/user/avatar/" + currentUser.profilePicture}/>
                    }

                    {
                        !currentUser.profilePicture &&
                        <img className="rounded-circle" src={"../../../public/assets/images/design/grayed_man.svg"}/>
                    }
                </div>
            </div>

            <div className={"row edit-profile-inputs"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <Formik
                        initialValues={{avatar: null}}
                        onSubmit={handleAvatarUpload}
                    >
                        {({errors, touched, values, setFieldValue}) => (

                            <Form noValidate={true}>

                                <div className={"form-group row mx-0"}>
                                    <FormErrors errors={errors} touched={touched} />
                                    <div className={`input-group `  + (errors.avatar ? 'has-error' : '') }>
                                        <label htmlFor="avatar" className="col-auto col-form-label">Profilbillede</label>
                                        <div className="col px-0">
                                            <input className={"form-control"}
                                               id="avatar"
                                               name="avatar"
                                               type="file"
                                               onChange={(event) => {setFieldValue("avatar", event.currentTarget.files[0]);}}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={"row mt-3"}>
                                    <div className={"col text-center"}>
                                        <a style={{cursor: 'pointer'}} onClick={handleRemoveAvatar} className={"text-muted"}>
                                            <i className={"mdi mdi-minus-circle font-18"}></i> Slet billede
                                        </a>
                                    </div>
                                </div>
                                <div className={"row edit-profile-button"}>
                                    <div className={"col text-center"}>
                                        <button type="submit" className={"btn btn-primary waves-effect btn-wide"}>
                                            Upload billede
                                        </button>

                                        <p className={"mt-3"}>
                                            <Link to={"/profiloversigt"} className={"text-body"}>Fortryd</Link>
                                        </p>
                                    </div>
                                </div>

                            </Form>

                        )}


                    </Formik>

                </div>
            </div>
        </div>
    )

};

export default UploadAvatar;