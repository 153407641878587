import React from 'react';
import {Route, Switch} from "react-router-dom";
import '../../../public/assets/scss/style.scss';
import Login from "../auth/Login";
import ForgotPassword from "../forgotPassword/resetLinkCreation/ForgotPassword";
import ResetPasswordPage from "../forgotPassword/resetForgottenPassword/ResetPasswordPage";

import PageNotFoundErrorComponent from "./PageNotFoundErrorComponent";
import Spinner from "../commonComponents/Spinner";
import RemoteLogin from "../auth/RemoteLogin";
import FacebookTokenLogin from "../auth/FacebookTokenLogin";
import LinkedInTokenLogin from "../auth/LinkedInTokenLogin";
import {UserIsNotLoggedIn} from "../../access/userAccessRules";

const LoginLayout = () => {

    let ht = document.getElementsByTagName('html')[0];
    ht.style.height = '100%';
    let bd = document.getElementsByTagName('body')[0];
    bd.style.height = '100%';
    bd.style.overflow = 'hidden';
    let sect = document.getElementsByTagName('section')[0];
    sect.style.height = '100%';


    return (


            <div className="wrapper-page-outer">
                <Switch>
                    <Route exact path="/auth/login" component={UserIsNotLoggedIn(Login)}/>
                    <Route exact path="/auth/facebook-token-login" component={UserIsNotLoggedIn(FacebookTokenLogin)}/>
                    <Route exact path="/auth/linkedin-token-login" component={UserIsNotLoggedIn(LinkedInTokenLogin)}/>
                    <Route exact path="/auth/remote-login/:credentials" component={UserIsNotLoggedIn(RemoteLogin)}/>
                    <Route exact path="/auth/glemtkodeord" component={UserIsNotLoggedIn(ForgotPassword)}/>
                    <Route exact path="/auth/glemtkodeord/kodeord/:token" component={ResetPasswordPage}/>
                    <Route component={PageNotFoundErrorComponent}/>
                </Switch>

                <Spinner />
            </div>




    )
};

export default LoginLayout;