import React, {useState}  from "react";
import {Link, Route, Switch} from 'react-router-dom';
import '../../../public/assets/scss/style.scss';
import Topbar from '../navigation/Topbar';
import Sidebar from '../navigation/Sidebar';

import Page from '../page/Page';
import Dashboard from '../dashboard/Dashboard';
import Profile from '../profile/Profile';
import Newsletter from '../newsletter/Newsletter';
import Faq from '../faq/Faq';
import Contact from '../contact/Contact';
import Cookies from '../page/Cookies';
import Terms from '../page/Terms';
import EditProfile from "../profile/EditProfile";

import ChangePassword from "../password/ChangePassword";

import InterestsPage from "../interests/InterestsPage";
import EditShippingAddress from "../shippingAddress/EditShippingAddress";
import ChangeEmail from "../changeEmail/ChangeEmail";
import ChangeEmailSuccess from "../changeEmail/ChangeEmailSuccess";
import PageNotFoundErrorComponent from "./PageNotFoundErrorComponent";
import Spinner from "../commonComponents/Spinner";
import Subscription from "../subscription/Subscription";

import BindFacebookAccount from "../../components/profile/BindFacebookAccount";
import BindLinkedinAccount from "../profile/BindLinkedinAccount";
import UnbindFailed from "../profile/UnbindFailed";
import DestroyProfileConfirmation from "../profile/DestroyProfileConfirmation";
import UploadAvatar from "../profile/UploadAvatar";
import Invoices from "../payment/Invoices";
import ChangePaymentMethod from "../payment/ChangePaymentMethod";
import DetachFromSubscriptionConfirmation from "../subscription/DetachFromSubscriptionConfirmation";
import PaymentInfo from "../payment/PaymentInfo";
import CardChanged from "../payment/CardChanged";
import ChangeMP from "../payment/ChangeMP";
import ChangeCard from "../payment/ChangeCard";
import ifloading from "../payment/ifloading";

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PasswordSubmitFailed from "../profile/PasswordSubmitFailed";

export default () => {

    let ht = document.getElementsByTagName('html')[0];
    ht.style.height = null;
    let bd = document.getElementsByTagName('body')[0];
    bd.style.height = null;
    bd.style.overflow = 'visible';
    let sect = document.getElementsByTagName('section')[0];
    sect.style.height = null;

    const { t } = useTranslation();
    const currentUser = useSelector(state => state.auth.currentUser);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const isInvoicePaid = useSelector(state => state.auth.unPaidInvoice);

    const handleClick = () => {
        setIsSidebarOpen(isSidebarOpen => !isSidebarOpen);
    };
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    return (
            <div id="wrapper">
                <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    currentUser={currentUser}
                    handleClick={handleClick}
                />

                <div className="content-page">

                    <div className="content">

                        {
                            currentUser && !currentUser.isActivated &&
                            <div className="row alert-wrapper">
                                <div className="col">
                                    <div className="alert alert-warning">
                                        <i className="mdi mdi-alert mr-2"/>
                                        {t('common:activateAccountMsg')}
										<a href={t('common:activateAccountHref')}>{t('common:activateAccountTlf')}</a>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            currentUser && currentUser.hasSuspendedSubscriptions &&
                            <div className="row alert-wrapper unpaid-invoice">
                                <div className="col">
                                    <Link to="/fakturaer">
                                        <div className="alert alert-warning">
                                            <i className="mdi mdi-alert mr-2"/>
                                            {t('common:unPaidAccountMsg')}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }

                        <div className="topbar">
                            <Topbar handleClick={handleClick}/>
                        </div>

                        <div className="page-content-wrapper ">

                            <div className="container">
                                <Switch>
                                    <Route exact path="/" component={Dashboard}/>
                                    <Route exact path="/profiloversigt" component={Profile}/>
                                    <Route exact path="/profile-unbind-failed" component={UnbindFailed}/>
                                    <Route exact path="/profile-password-failed" component={PasswordSubmitFailed}/>
                                    <Route exact path="/ret_profil" component={EditProfile}/>
                                    <Route exact path="/upload-avatar" component={UploadAvatar} />
                                    <Route exact path="/slet_profil" component={DestroyProfileConfirmation}/>
                                    <Route exact path="/ret_email" component={ChangeEmail}/>
                                    <Route exact path="/ret_email_success" component={ChangeEmailSuccess}/>
                                    <Route exact path="/leveringsadresse" component={EditShippingAddress}/>
                                    <Route exact path="/skift_kodeord" component={ChangePassword}/>
                                    <Route exact path="/ifloading" component={ifloading}/>


                                    <Route exact path="/interesser" component={InterestsPage}/>
                                    <Route exact path="/abonnementer" component={Subscription}/>
                                    <Route exact path="/fjern-tilknytning/:portalName" component={DetachFromSubscriptionConfirmation} />
                                    <Route exact path="/betaling" component={PaymentInfo}/>
                                    <Route exact path="/skift-mobilepay" component={ChangeMP}/>
                                    <Route exact path="/skift-kort" component={ChangeCard}/>
                                    <Route exact path="/kort-skiftet" component={CardChanged}/>
                                    <Route exact path="/skift-betalingsmetode" component={ChangePaymentMethod}/>
                                    <Route exact path="/fakturaer" component={Invoices}/>
                                    <Route exact path="/nyhedsbreve" component={Newsletter}/>
                                    <Route exact path="/faq" component={Faq}/>
                                    <Route exact path="/kontakt" component={Contact}/>
                                    <Route exact path="/abonnementsvilkar" component={Cookies}/>
                                    <Route exact path="/brugervilkar" component={Terms}/>
                                    <Route exact path="/app/page" component={Page}/>

                                    <Route exact path="/bind/facebook-account" component={BindFacebookAccount}/>
                                    <Route exact path="/bind/linkedin-account" component={BindLinkedinAccount} />

                                    <Route path="*" component={PageNotFoundErrorComponent}/>
                                </Switch>
                            </div>

                        </div>


                    </div>

                    {/*<Footer/>*/}
                    <Spinner />


                </div>


            </div>
    );
}