import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AsyncAutoSelectField = ({hasError, name, label, ...props}) => {

    let hasErrorClass = hasError ? 'has-error' : '';

    return (
        <div className={"form-group row mx-0"}>
            <div className={`input-group ${hasErrorClass}`}>
                <label htmlFor={name} className="col-auto col-form-label">{label}</label>
                <div className="col px-0">
                    <AsyncTypeahead {...props} />
                </div>
            </div>
        </div>
    );
}

export default AsyncAutoSelectField;