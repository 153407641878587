import React, {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import * as SubscriptionApi from "../../http/SubscriptionApi";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";
import * as UserApi from "../../http/UserApi";

export default () => {

    const REJECT_NONE = 1;
    const REJECT_SUCCESS = 2;
    const REJECT_FAILED = 3;

    const { t } = useTranslation();
    let { token } = useParams();

    let [rejectStatus, setRejectStatus] = useState(REJECT_NONE);

    const tryAgain = t("subscription:rejectInvitation.tryAgain");

    async function handleReject() {
        let response = await trackPromise(SubscriptionApi.rejectInvitation(token));
        if (response.status === 200) {
            setRejectStatus(REJECT_SUCCESS);
        } else {
            setRejectStatus(REJECT_FAILED);
        }
    }

    function createMarkup(htmlFragment) {
        return {__html: htmlFragment};
    }

    if (rejectStatus === REJECT_SUCCESS) {

        return (
            <div className="message-container">
                <div className="message-wrapper">
                    <div className="message-area">

                        <div className="text-center">
                            <img
                                src={"../../../public/assets/images/design/ok_green.svg"}
                                alt={"Ok"}/>
                            <p className={"msg m-t-20"}>
                                <strong>{t("subscription:rejectInvitation.success")}</strong>
                            </p>
                            <p className="msg-small">
                                <Trans i18nKey="subscription:rejectInvitation:canLogin"><Link to={"/auth/login"}>Click here</Link></Trans>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        )

    } else if (rejectStatus === REJECT_FAILED) {

        return (
            <div className="message-container">
                <div className="message-wrapper">
                    <div className="message-area">

                        <div className="text-center">
                            <img
                                src={"../../../public/assets/images/design/cancel_red.svg"}
                                alt={"Failed"}/>
                            <p className={"msg m-t-20"}>
                                <strong>{t("subscription:rejectInvitation.failed")}</strong>
                            </p>

                            <div dangerouslySetInnerHTML={createMarkup(tryAgain)} />

                        </div>

                    </div>
                </div>
            </div>
        )

    } else {
        return (
            <div className="message-container">
                <div className="message-wrapper">
                    <div className="message-area">

                        <div className="text-center">
                            <img className="rounded-circle"
                                 src={"../../../public/assets/images/design/grayed_man.svg"}/>
                            <p className={"msg m-t-20"}>
                                <strong>{t("subscription:rejectInvitation.pageHeading")}</strong>
                            </p>
                            <p className="m-t-30">
                                <button onClick={handleReject} className="btn btn-primary btn-darkblue waves-effect waves-light btn-wide">
                                    {t("subscription:rejectInvitation.confirm")}
                                </button>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        )

    }
}