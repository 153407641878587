import React from 'react';
import LoginForm from './LoginForm';

class Login extends React.Component {


    render() {

        return (
            <div className="d-flex flex-column h-100">

                <LoginForm />

                <div className="custom-loginfooter-text">
                    <div>
                        FBG Medier A/S<span> — </span>
                    </div>
                    <div>
                        Birk Centerpark 36, Birk, 7400 Herning<span> —&nbsp;</span>
                    </div>
                    <div>
                        <a href="tel:004596265299" data-wpel-link="internal">+45 96 26 52 99</a><span> —&nbsp;</span>
                    </div>
                    <div>
                        <a href="mailto:mail@fbg.dk">mail@fbg.dk</a><span> —&nbsp;</span>
                    </div>
                    <div>
                        <a href="https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&amp;id=26579880"
                           data-wpel-link="external" target="_blank" rel="external noopener noreferrer">CVR:
                            26579880</a><span> —&nbsp;</span>
                    </div>
                    <div>
                        <a href="https://www.fbg.dk/privatlivs-og-cookiepolitik/" target="_blank">Privatlivs- og
                            cookiepolitik</a>
                    </div>
                </div>

                <div className="custom-loginfooter-text2">
                    <div>
                        For henvendelser vedr. GDPR og sikkerhed: <a href="mailto:security@fbg.dk">security@fbg.dk</a>
                    </div>
                </div>

            </div>
        )
    }
}

export default Login;