import React, {useEffect, useState} from "react";
import Menu from "./sideMenu/Menu";
import {Link} from "react-router-dom";
import SideBottom from "./SideBottom";
import {NavLink} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import * as UserProfileApi from "../../http/UserProfileApi";

export default (props) => {
	// console.log(props)
	const isSidebarOpen = props.isSidebarOpen;
	// let currentUser = props.currentUser;
	// console.log(currentUser)x
	// <img className="rounded-circle" src={MEMBERS_API_URL + "/uploads/user/avatar/" + currentUser.profilePicture}/>
	let [currentUser, setProfile] = useState(null);

	useEffect(() => {
		(async () => {
			let response = await trackPromise(UserProfileApi.getProfile());
			if (response.status === 200) {
				let responseBody = await response.json();
				setProfile(responseBody);
				// currentUser = responseBody;
			}
		})();
	}, []);


	return (
		 <div className={`left side-menu ${isSidebarOpen ? 'show' : ''} `}>

			 <div className={"top-left"}>
				 {/*<div className="top-left-icon">*/}
				 {/*    <Link to="/" onClick={props.handleClick}>*/}
				 {/*        <i className="fa fa-home" />*/}
				 {/*    </Link>*/}
				 {/*</div>*/}
				 {/*FBG MEDIELOGIN*/}
				 <div className={`top-left-cross text-muted ${isSidebarOpen ? 'show' : ''}`} onClick={props.handleClick}>
					 <i className="dripicons-cross"/>
				 </div>
			 </div>

			 {
				 currentUser &&
				 <div className="sidebar-profile">
					 <Link to="/profiloversigt">
						 {
							 currentUser.profilePicture &&
							 <img className="rounded-circle" src={MEMBERS_API_URL + "/uploads/user/avatar/" + currentUser.profilePicture} title="Profil billede"/>

						 }
						 {
							 !currentUser.profilePicture &&
							 <img className="rounded-circle" src={"../../../public/assets/images/design/grayed_man.svg"} title="Profil billede"/>
						 }
					 </Link>
					 <p className={"name"}>{currentUser.name} {currentUser.surname}</p>
					 <p className={"email"}>{currentUser.email}</p>
				 </div>

			 }

			 <div className="sidebar-inner d-flex align-items-center">

				 <div style={{overflow: "auto", width: "100%", maxHeight: "100%"}}>
					 <Menu handleClick={props.handleClick}/>
				 </div>
			 </div>

			 <SideBottom handleClick={props.handleClick}/>

		 </div>
	);
};