import React, { useState } from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import InterestCard from "./InterestCard";

const InterestsPanel = (props) => {

    const [styles, setStyles] = useState({
        styleActive: (props.styleActive ? 'active' : ''),
        styleOpen: (props.styleActive ? true : false)
    });

    function handleHeaderClick(prevStyles) {
        let styles = {
            styleActive: ((prevStyles.styleActive == 'active') ? '' : 'active'),
            styleOpen: !prevStyles.styleOpen
        };
        return styles;
    }

    return (
        <Accordion>
            <Card className={`accordion-card ${styles.styleActive}`} >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={(prevStyles) => setStyles(handleHeaderClick)}>
                    <div className="row">
                        <div className="col-6 px-0">
                            <img src={props.portalHTML.imageSource} className="img-fluid fbg-height-control" />
                        </div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" in={styles.styleOpen}>
                    <Card.Body>
                        <p className="mt-2">{props.portalHTML.text}</p>

                        <div className="row px-1">
                            {
                                props.portalData.interests.map((interest) => {
                                    const isChecked = props.checkedSwitches.indexOf(interest.id) != -1;
                                    return (
                                        <InterestCard
                                            id={interest.id}
                                            label={interest.interestName}
                                            key={interest.id}
                                            handleChange={props.handleSwitchChange}
                                            checked={isChecked}
                                        />
                                    )
                                })
                            }
                        </div>

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )

}

export default InterestsPanel;
