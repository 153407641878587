import * as AuthAPI from '../http/AuthAPI';
import * as constants from './actionConstants';
import {getDefaultTokenService, getUserTokenService} from "../services/TokenProviderFactory";
import {trackPromise} from "react-promise-tracker";

export const login = (email, password, rememberMe = false, callback = null) => {
    return async (dispatch) => {
        let response = await trackPromise(AuthAPI.login(email, password));

        if(callback) {
            callback(response);
        }

        if (response.status === 200) {
            let credentials = await response.json();
            const tokenProviderService = getDefaultTokenService();
            await tokenProviderService.setCredentials(
                credentials.token,
                credentials.refresh_token,
                rememberMe
            );
            dispatch(loginSuccess());
            dispatch(loginAttemptSuccess());
        } else {
            dispatch(loginAttemptFailed());
            dispatch(logout());
        }
    }
};

export const autoLoginFromToken = () => {
    return async (dispatch) => {
        const tokenProviderService = getDefaultTokenService();
        let accessToken = await trackPromise(tokenProviderService.getAccessToken());
        if (null == accessToken) {
            dispatch(logout());
        } else {
            dispatch(loginSuccess());
        }
    };
};

export const logout = () => {
    return async (dispatch) => {
        const tokenProviderService = getDefaultTokenService();
        await tokenProviderService.removeCredentials();
        dispatch(logoutSuccess());
        window.location.href=MEMBER_DASHBOARD_URL;
    }
};

export const getCurrentUser = () => {
    return async (dispatch) => {
        let response =  await trackPromise(AuthAPI.getCurrentUser());

        if (response.status !== 200) {
            dispatch(logout());
            return;
        }

        let currentUser = await response.json();

        if (!currentUser.isAdmin) {
            dispatch(logout());
            return;
        }

        dispatch(getCurrentUserSuccess(currentUser));
    }
};

export const loginSuccess = () => ({
    type: constants.CURRENT_USER_LOGIN_SUCCESS,
    isLoggedIn: true
});

export const logoutSuccess = () => ({
    type: constants.CURRENT_USER_LOGOUT,
    isLoggedIn: false
});

export const loginAttemptSuccess = () => ({
    type: constants.CURRENT_USER_LOGIN_ATTEMPT_SUCCESS
});

export const loginAttemptFailed = () => ({
    type: constants.CURRENT_USER_LOGIN_ATTEMPT_FAILED
});

export const getCurrentUserSuccess = (user) => ({
    type: constants.GET_CURRENT_USER_SUCCESS,
    currentUser: user
});

export const loginAsUserSuccess = (user) => ({
    type: constants.LOGIN_AS_USER_SUCCESS,
    loginAsUser: user
});

export const logoutAsUserSuccess = (user) => ({
    type: constants.LOGOUT_AS_USER_SUCCESS
});