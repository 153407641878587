import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

export default () => {

    const { t } = useTranslation();

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/ok_green.svg"}
                            alt={"Ok"} />
                        <p className={"msg m-t-20"}>
                            <strong>{t('changeEmail:success.emailChanged')}</strong>
                        </p>
                        <p className={"msg-small"}>
                            <Trans i18nKey="changeEmail:success.canLogIn"><br/><Link to={"/auth/login"}>log in</Link></Trans>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}