import { AuthHttpClient } from "../services/AuthHttpClient";
import { HttpClient } from "../services/HttpClient";

export const getAllPortals = () => {
    return HttpClient( MEMBERS_API_URL + '/api/v1/public/portals/all');
};

export const getPortalsWithInterests = () => {
    return AuthHttpClient( MEMBERS_API_URL + '/api/v1/private/portals/all/interests', {
        method: 'GET'
    });
};

export const getAllMagazines = () => {
    return AuthHttpClient( MEMBERS_API_URL + '/api/v1/private/portals/all/magazines', {
        method: 'GET'
    });
};
