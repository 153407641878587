import {AuthHttpClient} from "../services/AuthHttpClient";

export const getUsers = (params) => {

    let queryParams = [];
    for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== '') {
            queryParams.push(key + '=' + params[key]);
        }
    }
    let queryString = '';
    if (queryParams.length > 0) {
        queryString = '?' + queryParams.join('&');
    }

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/users' + queryString);
};

export const getUser = (userId) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/user/' + userId);
}

export const loginAs = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/login-as/' + email);
}

export const markAsAdmin = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/mark-as-admin/' + email);
}

export const setPassword = (email, newPass) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/set-password/' + email + '?newpass=' + newPass);
}

export const setEmail = (email, newEmail) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/set-email/' + email + '?newEmail=' + newEmail);
}

export const markAsUser = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/mark-as-user/' + email);
}
export const softDeleteUser = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/soft-delete-user/' + email);
}
export const restoreUser = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/restore-user/' + email);
}
export const removeUser = (email) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/admin/remove-user/' + email);
}
