import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

export default (props) => {

    const { t } = useTranslation();

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/cancel_red.svg"}
                            alt={"Cancel"} />
                        <p className={"msg m-t-20"}>
                            <strong>{t("changeEmail:errors.invalidEmailLink")}</strong>
                        </p>
                        <p className={"msg-small"}>
                           <Link to="/ret_email">{t("changeEmail:invalid.sendRequestAgain")}</Link>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}