import {AuthHttpClient} from "../services/AuthHttpClient";

export const getUserPackages = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/subscription/packages', {
        method: 'GET'
    });
};

export const getCCPaymentUrl = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/subscription/changecard', {
        method: 'GET'
    });
};

export const callCCNotifySupport = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/subscription/notifysupport', {
        method: 'GET'
    });
};

export const getSubscribers = (portalName) => {
    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/private/subscription/package/${portalName}/subscribers`, {
        method: 'GET'
    });
};

export const addSubscriber = (portalName, userEmail, activationActivateUrl = null) => {
    const formData = new FormData();
    formData.append('iterasPortalName', portalName);

    if (activationActivateUrl) {
        formData.append('activationActivateUrl', activationActivateUrl)
    } else {
        formData.append('activationActivateUrl', ACTIVATION_ACTIVATE_URL);
    }

    formData.append('activationRejectUrl', ACTIVATION_REJECT_URL);
    formData.append('invitationRejectUrl', SUBSCRIPTION_REJECT_URL);
    formData.append('subUserEmail', userEmail);

    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/private/subscription/add-extra-subscriber`, {
        method: 'POST',
        body: formData
    })
};

export const rejectInvitation = (token) => {
    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/public/subscription/reject-invitation/${token}`, {
        method: 'DELETE'
    });
}

export const removeSubscriber = (portalName, userEmail) => {
    const formData = new FormData();
    formData.append('iterasPortalName', portalName);
    formData.append('subUserEmail', userEmail);

    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/private/subscription/remove-extra-subscriber`, {
        method: 'POST',
        body: formData
    })
};

export const updateSubscriber = (portalName, newEmail, oldEmail, activationActivateUrl = null) => {
    const formData = new FormData();
    formData.append('iterasPortalName', portalName);
    formData.append('newEmail', newEmail);
    formData.append('oldEmail', oldEmail);

    if (activationActivateUrl) {
        formData.append('activationActivateUrl', activationActivateUrl)
    } else {
        formData.append('activationActivateUrl', ACTIVATION_ACTIVATE_URL);
    }

    formData.append('activationRejectUrl', ACTIVATION_REJECT_URL);
    formData.append('invitationRejectUrl', SUBSCRIPTION_REJECT_URL);

    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/private/subscription/update-extra-subscriber`, {
        method: 'POST',
        body: formData
    })
}

export const detachSubscription = (portalName) => {
    return AuthHttpClient(
        `${MEMBERS_API_URL}/api/v1/private/subscription/detach-subscription/${portalName}`,
        {method: 'DELETE'}
    );
}

export const cancelSubscription = (packageToStop) => {
    const formData = new FormData();
    formData.append('packageId', packageToStop.packageId);
    formData.append('externalPID', packageToStop.externalPID);

    return AuthHttpClient(`${MEMBERS_API_URL}/api/v1/private/subscription/cancel`, {
        method: 'POST',
        body: formData
    })
}