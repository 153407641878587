import React from 'react';
import {Trans, useTranslation} from "react-i18next";

export default () => {

    const { t } = useTranslation();

    return (
        <div className={"flex-display-center"}>
            <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                <div className="px-30 text-center">
                    <h1>Abonnementsvilkår</h1>
                    <p className={"text-muted m-t-20 m-b-20"}>
                        <a href={t("cookies:cookiesUrl")} target={"_blank"}>{t("cookies:cookiesUrlLabel")}</a>
                    </p>
                </div>
            </div>
        </div>
    )
};