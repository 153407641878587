import React, { useState, useEffect } from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const SubscriptionPanel = (props) => {

    const [styles, setStyles] = useState({
        styleActive: (props.styleActive ? 'active' : ''),
        styleOpen: !!props.styleActive
    });

    function handleHeaderClick(prevStyles) {
        return {
            styleActive: ((prevStyles.styleActive === 'active') ? '' : 'active'),
            styleOpen: !prevStyles.styleOpen
        };
    }

    // console.log(props);

    return (
        <Accordion>
            <Card className={`accordion-card ${styles.styleActive}`} >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={(prevStyles) => setStyles(handleHeaderClick)}>
                    <img src={props.imageSource} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" in={styles.styleOpen}>
                    <Card.Body>
                        {
                            props.userSubscriptions.map((userSubscription, index) =>
                                <div key={index} className="row mt-3">
                                    <div className="col">
                                        <Card body className="white-card px-30 py-4">
                                            {userSubscription}
                                        </Card>
                                    </div>
                                </div>
                            )
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
};

export default SubscriptionPanel;