import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import * as AuthApi from "../../http/AuthAPI";
import * as ResetPasswordApi from "../../http/ResetPasswordApi";
import {useTranslation, Trans} from "react-i18next";

export default (props) => {

    const RESET_LINK_NOT_CREATED = 0;
    const RESET_LINK_CREATION_FAILED = 1;
    const RESET_LINK_CREATION_SUCCESS = 2;

    const TOO_MANY_REQUESTS_ERR = 1;
    const USER_NOT_FOUND_ERR = 2;

    const { t } = useTranslation();
    let [creationStatus, setCreationStatus] = useState(RESET_LINK_NOT_CREATED);
    let [errorMsg, setErrorMsg] = useState(t('changePassword:errors.unknown'));


    async function resetPasswordRequest(e) {
        e.preventDefault();
        let user = await trackPromise(AuthApi.getCurrentUser());
        user = await trackPromise(user.json());
        let response = await trackPromise(ResetPasswordApi.createResetPasswordRequest(user.email));

        if (response.status === 400) {
            let responseBody = await response.json();
            switch (responseBody.code) {
                case TOO_MANY_REQUESTS_ERR:
                    setErrorMsg(t('changePassword:errors.tooManyRequests'));
                    break;
                case USER_NOT_FOUND_ERR:
                    setErrorMsg(t('changePassword:errors.userNotFound'));
                    break;
                default:
                    setErrorMsg(t('changePassword:errors.unknown'));
            }

            setCreationStatus(RESET_LINK_CREATION_FAILED);
            return;
        }

        if (response.status !== 200) {
            setErrorMsg(t('changePassword:errors.unknown'));
            setCreationStatus(RESET_LINK_CREATION_FAILED);
            return;
        }

        setCreationStatus(RESET_LINK_CREATION_SUCCESS);
    }

    return <div id={"reset-password"}>
        <div className="reset-password-outer">
            <div className="reset-password-wrapper">
                <h1>{t("changePassword:resetPass")}</h1>
                <br/>
                {
                    creationStatus === RESET_LINK_CREATION_FAILED &&
                    <div style={{color: "red"}} className="mb-5"> {errorMsg} </div>
                }

                {
                    creationStatus === RESET_LINK_CREATION_SUCCESS &&
                    <div style={{color: "green"}} className="mb-5">
                        {t("changePassword:emailSent")}
                    </div>
                }

                <div className={"reset-password-buttons"}>
					<p>
						<button onClick={resetPasswordRequest} type="submit" className="btn btn-primary btn-wide">{t("changePassword:sendEmail")}</button>
					</p>
					<p>
						<Link to={"/auth/login"} className={"text-body"}>{t("changePassword:undo")}</Link>
					</p>
				</div>


			</div>
        </div>
    </div>;
}