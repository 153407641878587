import React from 'react';
import { useField } from 'formik';

const TextareaField = ({ label, ...props }) => {

    const [field, meta] = useField(props);
    const hasError = (meta.touched && meta.error) ? 'has-error' : '';

    return (
        <div className={"form-group row mx-0"}>
            <div className={`input-group ${hasError}`}>
                <label htmlFor={props.id || props.name} className="col-auto col-form-label">{label}</label>
                <div className="col px-0">
                    <textarea {...field} {...props}  className="form-control px-0" />
                </div>
            </div>
        </div>
    );
}

export default TextareaField;