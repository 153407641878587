import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

let topLogo = "/public/assets/images/design/medielogin.svg";
let portalName = "medielogin";
import queryString from 'query-string';
const parsed = queryString.parse(location.search);
let utmCampaign = parsed.utm_campaign;

if(typeof utmCampaign !== "undefined"){
    utmCampaign = utmCampaign.toLowerCase();
    if(utmCampaign.indexOf("maskinteknik")>=0) topLogo = "/public/assets/images/design/maskinteknik.svg";
    if(utmCampaign.indexOf("maskinbladet")>=0) topLogo = "/public/assets/images/design/maskinbladet.svg";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) topLogo = "/public/assets/images/design/fritidsmarkedet.svg";
    if(utmCampaign.indexOf("gronteknik")>=0) topLogo = "/public/assets/images/design/gronteknik.svg";

    if(utmCampaign.indexOf("maskinteknik")>=0) portalName = "maskinteknik";
    if(utmCampaign.indexOf("maskinbladet")>=0) portalName = "maskinbladet";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) portalName = "fritidsmarkedet";
    if(utmCampaign.indexOf("gronteknik")>=0) portalName = "gronteknik";
}

export default () => {

    const { t } = useTranslation();

    return (
        <div className="h-100 d-flex flex-column">

            <div className="text-center page-top">
                <p className="text-uppercase my-0"><img src={topLogo} style={{'width':'50%'}} /></p>
            </div>

            <div className="h-100 d-flex flex-column justify-content-center">
                <div className="text-center">
                    <img
                        src={"../../../public/assets/images/design/ok_green.svg"}
                        alt={"Ok"} />
                    <p className={"msg m-t-20"}>
                        <strong>{ t('forgotPassword:resetPassword.success') }</strong>
                    </p>
                    <p>
                        <Trans i18nKey="forgotPassword:resetPassword.login"><Link to={"/auth/login"}>log in</Link></Trans>
                    </p>
                </div>
            </div>

        </div>
    )
}