import React from 'react';
import {Link} from "react-router-dom";
import FaqPanel from "./FaqPanel";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

export default () => {

    const { t } = useTranslation();

    return (
        <div id="faq">
            <div className={"row mt-0 mt-md-5"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <div className="px-30">
                    <h1>{t("faq:pageHeader")}</h1>
                        <p className={"text-muted mt-3"}>
                            {t("faq:intro1")}
                        </p>
                        <p className={"text-muted"}>
                            <Trans i18nKey="faq:intro2"><Link to={"/kontakt"}>contact us</Link></Trans>
                        </p>
                    </div>
                </div>
            </div>

            <div className={"row m-t-10"} key={0}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <FaqPanel question={t("faq:questions.0.question")} answer={t("faq:questions.0.answer")} key={0} />
                </div>
            </div>
            <div className={"row m-t-10"} key={1}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.1.question")} answer={t("faq:questions.1.answer")} key={1} />
                </div>
            </div>
            <div className={"row m-t-10"} key={2}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.2.question")} answer={t("faq:questions.2.answer")} key={2} />
                </div>
            </div>
            <div className={"row m-t-10"} key={3}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.3.question")} answer={t("faq:questions.3.answer")} key={3} />
                </div>
            </div>
            <div className={"row m-t-10"} key={4}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.4.question")} answer={t("faq:questions.4.answer")} key={4} />
                </div>
            </div>
            <div className={"row m-t-10"} key={5}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.5.question")} answer={t("faq:questions.5.answer")} key={5} />
                </div>
            </div>
            <div className={"row m-t-10"} key={6}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.6.question")} answer={t("faq:questions.6.answer")} key={6} />
                </div>
            </div>
            <div className={"row m-t-10"} key={7}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.7.question")} answer={t("faq:questions.7.answer")} key={7} />
                </div>
            </div>
            <div className={"row m-t-10"} key={8}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.8.question")} answer={t("faq:questions.8.answer")} key={8} />
                </div>
            </div>
            <div className={"row m-t-10"} key={9}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.9.question")} answer={t("faq:questions.9.answer")} key={9} />
                </div>
            </div>
            <div className={"row m-t-10"} key={10}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.10.question")} answer={t("faq:questions.10.answer")} key={10} />
                </div>
            </div>
            <div className={"row m-t-10"} key={11}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.11.question")} answer={t("faq:questions.11.answer")} key={11} />
                </div>
            </div>
            <div className={"row m-t-10"} key={12}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.12.question")} answer={t("faq:questions.12.answer")} key={12} />
                </div>
            </div>
            <div className={"row m-t-10"} key={13}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.13.question")} answer={t("faq:questions.13.answer")} key={13} />
                </div>
            </div>
            <div className={"row m-t-10"} key={14}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.14.question")} answer={t("faq:questions.14.answer")} key={14} />
                </div>
            </div>
            <div className={"row m-t-10"} key={15}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.15.question")} answer={t("faq:questions.15.answer")} key={15} />
                </div>
            </div>
            <div className={"row m-t-10"} key={16}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.16.question")} answer={t("faq:questions.16.answer")} key={16} />
                </div>
            </div>
            <div className={"row m-t-10"} key={17}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.17.question")} answer={t("faq:questions.17.answer")} key={17} />
                </div>
            </div>
            <div className={"row m-t-10"} key={18}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.18.question")} answer={t("faq:questions.18.answer")} key={18} />
                </div>
            </div>
            <div className={"row m-t-10"} key={19}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.19.question")} answer={t("faq:questions.19.answer")} key={19} />
                </div>
            </div>
            <div className={"row m-t-10"} key={20}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <FaqPanel question={t("faq:questions.20.question")} answer={t("faq:questions.20.answer")} key={20} />
                </div>
            </div>

            <ContentBottom />

        </div>
    )
};