import React from "react";
import * as Utils from '../commonComponents/Utils';
import {useTranslation, Trans} from "react-i18next";

const StopSubscriptionSuccess = (props) => {

    const {t} = useTranslation();

    let fullPrint = props.endDates.filter((item) => {
        return item.campaignName === 'full_print';
    });

    let magazines = props.endDates.filter((item) => {
        return item.campaignName !== 'full_print'
            && item.campaignName !== 'Digital+'
            && item.campaignName !== 'Pro'
            && item.campaignName !== 'Pro Plus'
            && item.campaignName !== 'Business'
            && item.campaignName !== 'Enterprise'
            && item.campaignName !== 'Basis';
    });

    let genericPackage = props.endDates.filter((item) => {
        return item.campaignName === 'Digital+'
            || item.campaignName === 'Pro'
            || item.campaignName === 'Pro Plus'
            || item.campaignName === 'Business'
            || item.campaignName === 'Enterprise'
            || item.campaignName === 'Basis';
    });

    console.log(magazines);

    let magazineStrings = [];
    if (magazines && genericPackage.length === 0) {
        magazineStrings = magazines.map((magazine) => {
            return Utils.capitalizeFirstLetter(magazine.campaignName) + ' indtil ' + Utils.formatToDanishDate(magazine.endDate);
        })
    }

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/ok_green.svg"}
                            alt={"Ok"}/>
                        {
                            genericPackage.length > 0 &&
                            <p className={"msg m-t-20"}>
                                <strong>Dit abonnement {Utils.capitalizeFirstLetter(genericPackage[0].campaignName)} er nu opsagt <br/>Abonnementet udløber den {Utils.formatToDanishDate(genericPackage[0].endDate)}.</strong>
                            </p>
                        }
                        {
                            fullPrint.length > 0 &&
                            <p className={"msg m-t-20"}>
                                <strong>{t("subscription:stop.stoppedDate")} {Utils.formatToDanishDate(fullPrint.endDate)}.</strong>
                            </p>
                        }
                        {
                            magazineStrings.length > 0 &&
                            <p className={"msg m-t-20"}>
                                <strong>Dit abonnement er nu opsagt<br/><br/>
                                    {t("subscription:stop.magazineListStart")}<br/>
                                    {
                                        magazineStrings.map((magazineString, key) => {
                                            let and = (key > 0) ? 'og' : '';
                                            return (
                                                <span key={key}>{and} {magazineString}<br/></span>
                                            )
                                        })

                                    }
                                    {t("subscription:stop.magazineListEnd")}
                                </strong>
                            </p>
                        }
                    </div>

                    <div className="message-buttons text-center">
                        <a href="#" className={"btn btn-primary waves-effect text-nowrap btn-wide"}
                           onClick={props.backLinkSuccessHandler}>
                            {t("subscription:stop.backToSubscriptions")}
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StopSubscriptionSuccess;