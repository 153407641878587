import React, {useEffect, useState, useRef} from "react";
import {Accordion, Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import MenuCard from "./MenuCard";
import {connect} from "react-redux";
import * as AuthApi from '../../../http/AuthAPI';
import * as AdminTokenService from "../../../services/AdminTokenService";
import {useTranslation, Trans} from "react-i18next";
import {trackPromise} from "react-promise-tracker";
import * as SubscriptionAPI from "../../../http/SubscriptionApi";
import * as PaymentApi from "../../../http/PaymentApi";

const Menu = (props) => {

	const defaultStates = {
		home: false,
		profil: false,
		abonnement: false,
		nyhedsbreve: false,
		faq: false,
		kontakt: false,
	}



    const wrapperRef = useRef();

    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoggedInAs, setIsLoggedInAs] = useState(false);
    const [activeState, setActiveState] = useState(defaultStates);
    const [packages, setPackages] = useState(null);
    const [countPackages, setCountPackages] = useState(0);
    const [countInvoices, setCountInvoices] = useState(0);

    useEffect(() => {
        (async () => {
            let response = await AuthApi.getCurrentUser();
            if (response.status === 200) {
                setCurrentUser(await response.json());
            }

            if (AdminTokenService.hasCredentials()) {
                setIsLoggedInAs(true);
            }

        })();
        updateActiveState();
    }, []);

    useEffect(() => {
        (async () => {
            const packagesResponse = await trackPromise(SubscriptionAPI.getUserPackages());
            const packagesJson = await trackPromise(packagesResponse.json());
            setPackages(packagesJson);
            setCountPackages(packagesJson.length);

            const invoicesResponse = await trackPromise(PaymentApi.getInvoices());
            const invoicesJson = await trackPromise(invoicesResponse.json());
            setCountInvoices(invoicesJson.length);
        })();
    }, []);

    useEffect(() => {

        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                updateActiveState();
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    function matchCurrentPath(path) {
        return path === window.location.href.split('#').pop();
    }

    function matchChildrenPath(parentMenuId) {
        const children = {
            'profil': [
                '/profiloversigt',
                '/ret_profil',
                '/skift_kodeord',
                '/ret_email',
                '/interesser'
            ],
            'abonnement': [
                '/abonnementer',
                '/betaling',
                '/fakturaer'
            ]
        }
        let path = window.location.href.split('#').pop();
        return children[parentMenuId].includes(path);
    }

    function updateActiveState() {
        let newStates = Object.assign({}, defaultStates);;
        if (matchChildrenPath('profil')) {
            newStates['profil'] = true;
        } else if (matchChildrenPath('abonnement')) {
            newStates['abonnement'] = true;
        } else if (matchCurrentPath('/nyhedsbreve')) {
            newStates['nyhedsbreve'] = true;
        } else if (matchCurrentPath('/faq')) {
            newStates['faq'] = true;
        } else if (matchCurrentPath('/kontakt')) {
            newStates['kontakt'] = true;
        } else if (matchCurrentPath('/')) {
            newStates['home'] = true;
        }
        setActiveState(newStates);
    }

    function toggleCardState(cardId) {
        if (cardId in activeState) {
            let newStates = Object.assign({}, defaultStates);
            newStates[cardId] = !activeState[cardId];
            setActiveState(newStates);
        }
    }

    function boughtOwnSubscription() {
        if (currentUser === null || packages === null) {
            return false;
        }
        let countOwnPackages = 0;
        for (let i = 0; i < packages.length; i++) {
            if (packages[i].packageOwner.iterasId === currentUser.iterasId ) {
                countOwnPackages++;
            }
        }
        return countOwnPackages > 0;
    }

    // function changeBackground(e) {
    //     let target = e;
    //     var changeImage = true;
    //     if (e.target.parentElement.className == 'card') {
    //         if (e.target.parentElement.children[0].className.includes('nav-active')) {
    //             changeImage = false;
    //         }
    //     } else if (e.target.parentElement.className.includes('nav-active')) {
    //         changeImage = false;
    //     }
    //
    //     if (changeImage == true) {
    //         e.target.parentElement.querySelector('img').src = e.target.parentElement.querySelector('img').src.replace("_black", "");
    //     }
    // }
    //
    // function changeBackgroundOut(e) {
    //     var insertText = '_black';
    //     var changeImage = true;
    //     var str = e.target.parentElement.querySelector('img').src;
    //     if (!str.includes('_black')) {
    //
    //         if (e.target.parentElement.className == 'card') {
    //             if (e.target.parentElement.children[0].className.includes('nav-active')) {
    //                 changeImage = false;
    //             }
    //         } else if (e.target.parentElement.className.includes('nav-active')) {
    //             // console.log("here")
    //             changeImage = false;
    //         }
    //         if (changeImage == true){
    //         var n = str.lastIndexOf(".");
    //         var str2 = str.substring(0, n) + insertText + str.substring(n);
    //
    //         e.target.parentElement.querySelector('img').src = str2;
    //         }
    //     }
    // }

    return (
        <div id="sidebar-menu" ref={wrapperRef}>
            <Accordion>

                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={toggleCardState.bind(null, 'home')}
                        className={(matchCurrentPath('/')) ? 'nav-active' : ''} >

                        <span className={`sidebar-icon fbg-icons fbg-icons-home`}></span>

                        <NavLink to="/" onClick={props.handleClick}>{t("sideMenu:home")}</NavLink>
                    </Accordion.Toggle>
                </Card>

                <MenuCard
                    eventKey="1"
                    cardId="profil"
                    title={t("sideMenu:profile.menuItem")}
                    iconName="user"
                    onClick={toggleCardState.bind(null, 'profil')}
                    isActive={activeState['profil']}
                >
                    <ul className="list-unstyled">
                        <li>
                            <NavLink to={"/profiloversigt"} onClick={props.handleClick} >{t("sideMenu:profile.overview")}</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/ret_profil"} onClick={props.handleClick} >{t("sideMenu:profile.editProfile")}</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/skift_kodeord"} onClick={props.handleClick} >{t("sideMenu:profile.changePassword")}</NavLink>
                        </li>
                        {
                            currentUser !== null && currentUser.isActivated &&
                            <li>
                                <NavLink to={"/ret_email"} onClick={props.handleClick} >{t("sideMenu:profile.changeEmail")}</NavLink>
                            </li>
                        }
                        <li>
                            <NavLink to={"/interesser"} onClick={props.handleClick} >{t("sideMenu:profile.interests")}</NavLink>
                        </li>
                    </ul>
                </MenuCard>

                <MenuCard
                    eventKey="2"
                    cardId="abonnement"
                    title={t("sideMenu:abonnement.menuItem")}
                    iconName="shopping-bag"
                    onClick={toggleCardState.bind(null, 'abonnement')}
                    isActive={activeState['abonnement']}
                >
                    <ul className="list-unstyled">
                        <li>
                            <NavLink to={"/abonnementer"} onClick={props.handleClick} >{t("sideMenu:abonnement.packages")}</NavLink>
                        </li>
                        {
                            boughtOwnSubscription() &&
                            <li>
                                <NavLink to={"/betaling"}
                                         onClick={props.handleClick}>{t("sideMenu:abonnement.payment")}</NavLink>
                            </li>
                        }
                        {
                            countInvoices > 0 &&
                            <li>
                                <NavLink to={"/fakturaer"}
                                         onClick={props.handleClick}>{t("sideMenu:abonnement.invoices")}</NavLink>
                            </li>
                        }
                    </ul>
                </MenuCard>


                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="3"
                        onClick={toggleCardState.bind(null, 'nyhedsbreve')}
                        className={(matchCurrentPath('/nyhedsbreve')) ? 'nav-active' : ''}
                        // onMouseOver={changeBackground}
                        // onMouseOut={changeBackgroundOut}
                    >

                        {/*<img style={{width:"17px"}}*/}
                        {/*    src={`../../../public/assets/images/design/icons/envelope${(matchCurrentPath('/nyhedsbreve')) ? '' : '_black'}.svg`}*/}
                        {/*    alt={t("sideMenu:news")} />*/}

                        {/*<div className={`menu-image envelope ${(matchCurrentPath('/nyhedsbreve')) ? 'true' : '_black'}`}></div>*/}

                        <span className={`sidebar-icon fbg-icons fbg-icons-mail`}></span>

                        <NavLink to="/nyhedsbreve" onClick={props.handleClick} >{t("sideMenu:news")}</NavLink>
                    </Accordion.Toggle>
                </Card>

                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="4"
                        onClick={toggleCardState.bind(null, 'faq')}
                        className={(matchCurrentPath('/faq')) ? 'nav-active' : ''}
                    >
                        {/*<img style={{width:"17px"}}*/}
                        {/*    src={`../../../public/assets/images/design/icons/question${(matchCurrentPath('/faq')) ? '' : '_black'}.svg`}*/}
                        {/*    alt={t("sideMenu:faq")} />*/}

                        {/*<div className={`menu-image faq ${(matchCurrentPath('/faq')) ? 'true' : '_black'}`}></div>*/}

                        <span className={`sidebar-icon fbg-icons fbg-icons-help-circle`}></span>


                        <NavLink to="/faq" onClick={props.handleClick} >{t("sideMenu:faq")}</NavLink>
                    </Accordion.Toggle>
                </Card>

                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="5"
                        onClick={toggleCardState.bind(null, 'kontakt')}
                        className={(matchCurrentPath('/kontakt')) ? 'nav-active' : ''}
                    >
                        {/*<img style={{width:"17px", maxHeight:"17px"}}*/}
                        {/*    src={`../../../public/assets/images/design/icons/info${(matchCurrentPath('/kontakt')) ? '' : '_black'}.svg`}*/}
                        {/*    alt={t("sideMenu:contact")} />*/}

                        {/*<div className={`menu-image info ${(matchCurrentPath('/kontakt')) ? 'true' : '_black'}`}></div>*/}

                        <span className={`sidebar-icon fbg-icons fbg-icons-info`}></span>


                        <NavLink to="/kontakt" onClick={props.handleClick} >{t("sideMenu:contact")}</NavLink>
                    </Accordion.Toggle>
                </Card>

                {
                    (currentUser && currentUser.isAdmin || isLoggedInAs)  &&
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="6">
                            {/*<i className="fa fa-dashboard" />*/}
                            <span className={`sidebar-icon fa fa-dashboard`} style={{top:2}}></span>

                            <a href={ADMIN_DASHBOARD_URL + "#/users"} >Admin Dashboard {isLoggedInAs ? '[LoggedIn as]' : ''}</a>
                        </Accordion.Toggle>
                    </Card>
                }

            </Accordion>
        </div>
    )
}

export default connect()(Menu);