import React, {useEffect} from "react";
import * as UserApi from "../../http/UserApi";
import * as AuthActions from "../../actions/authActions";
import {connect} from "react-redux";
import {useTranslation, Trans} from "react-i18next";

const SelfDeleted = (props) => {

    const { t } = useTranslation();

    useEffect(() => {
        props.dispatch(AuthActions.logout());
    }, []);

    return (
		<div className="content-page" style={{margin: "0"}} >
			<div className="content" >
				<div className="message-container deleted-profile">
					<div className="message-wrapper">
						<div className="message-area">

							<div className="text-center">
								<img
									src={"../../../public/assets/images/design/ok_green.svg"}
									alt={"Ok"} />
								<p className={"msg m-t-20"}>
									<strong>
										<Trans i18nKey="profile:delete.profileDeletedSuccess"><br /></Trans>
									</strong>
								</p>
							</div>

							<div className="message-buttons text-center">
								<a href="https://www.maskinbladet.dk"
								   className={"btn btn-primary waves-effect text-nowrap btn-wide"}>
									{t("profile:delete.backToAddress")}
								</a>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default connect()(SelfDeleted);