import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import {Form, Formik} from 'formik';
import InputTextField from "../commonComponents/InputTextField";
import * as ChangeEmailApi from "../../http/ChangeEmailApi";
import * as AuthApi from "../../http/AuthAPI";
import FormErrors from "../commonComponents/FormErrors";
import {validate} from "./validate";
import {trackPromise} from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

const ChangeEmail = (props) => {

    const TOO_MANY_REQUESTS_ERR = 1;
    const USER_ALREADY_EXISTS_ERR = 2;
    const VALIDATION_ERR = 3;

    const FORM_NOT_SENT = 1;
    const FORM_WAS_SENT_SUCCESSFULLY= 2;

    const { t } = useTranslation();
    const historyHook = useHistory();
    let [changeEmailsStatus, setChangeEmailStatus] = useState(FORM_NOT_SENT);

    useEffect(() => {
        (async() => {
            const currentUser = await trackPromise(AuthApi.getCurrentUser());
            const currentUserJson = await trackPromise(currentUser.json());

            if (!currentUserJson.isActivated) {
                historyHook.push('/');
            }
        })();
    })

    async function handleFormSubmit(values, {setFieldError, setFieldTouched}) {
        let response = await trackPromise(ChangeEmailApi.changeEmailRequest(values.email));

        if (response.status === 200) {
            setChangeEmailStatus(FORM_WAS_SENT_SUCCESSFULLY);
            return;
        }

        if (response.status === 400) {
            let responseBody = await response.json();
            switch (responseBody.code) {
                case TOO_MANY_REQUESTS_ERR:
                    setFieldError('email', t('changeEmail:errors.tooManyRequests'));
                    break;
                case USER_ALREADY_EXISTS_ERR:
                    setFieldError('email', t('changeEmail:errors.userEmailExists'));
                    break;
                case VALIDATION_ERR:
                    setFieldError('email', t('changeEmail:errors.invalidEmail'));
                    break;
                default:
                    setFieldError('email', t('changeEmail:errors.unknown'));
                    break;
            }

            return;
        }

        setFieldError('email', t('changeEmail:errors.unknown'));
    }

    if (changeEmailsStatus === FORM_WAS_SENT_SUCCESSFULLY) {
        return (
            <div className="message-container">
                <div className="message-wrapper">
                    <div className="message-area">

                        <div className="text-center">
                            <img
                                src={"../../../public/assets/images/design/ok_gray.svg"}
                                alt={"Ok"} />
                            <p className={"msg m-t-20"}>
                                <strong>{t("changeEmail:change.sendMail")}</strong></p>
                            <p className={"msg-small"}>
                                {t("changeEmail:change.clickLink")}
                            </p>
                        </div>
                        <div className="message-buttons text-center screen-invisible">
                            <Link to={"/"} className="btn btn-primary waves-effect text-nowrap btn-wide">
                                {t("changeEmail:change.backToKonto")}
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
            <div className={"row change-email"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto change-email-text"}>
                    <div className="px-4 py-4">
                        <h1>{t("changeEmail:change.pageHeader")}</h1>

                        <Trans i18nKey="changeEmail:change.intro"><p></p></Trans>
                        {t("changeEmail:change.enterNew")}
                    </div>
                </div>

            <Formik
                initialValues={{
                    email: ''
                }}
                validate={validate(t)}
                onSubmit={handleFormSubmit}
            >
                {
                    ({errors, touched}) => <Form  noValidate={true}>

                        <div>
                            <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                                <FormErrors errors={errors} touched={touched} />
                                <InputTextField
                                    name={"email"}
                                    type="text"
                                    label={t("changeEmail:change.labelNew")}
                                />
                            </div>
                        </div>

                        <div className="button-container">
                            <div className="buttons-wrapper">
                                <div className="buttons-area">
                                    <div className="bottom-buttons">
                                <p>
                                    <button type="submit" className={"btn btn-primary waves-effect btn-wide"}>{t("changeEmail:change.editEmail")}</button>
                                </p>
                                <p className="text-center my-0">
                                    <Link to={"/profiloversigt"} className={"text-body"}>{t("changeEmail:change.undo")}</Link>
                                </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>
                }

            </Formik>

        </div>
    )
};

export default ChangeEmail;