import React, {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import * as UserApi from "../../http/UserApi";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";

export default () => {

    const REMOVE_NONE = 1;
    const REMOVE_SUCCESS = 2;
    const REMOVE_FAILED = 3;
    let CONFIRMED_REMOVAL = false;

	const { t } = useTranslation();
    let { token } = useParams();

    let [removeStatus, setRemoveStatus] = useState(REMOVE_NONE);

	if(window.location.href.indexOf("confirmed=true")>5) CONFIRMED_REMOVAL = true;

	let CONFIRM_URL = window.location.href.replace("?", "?confirmed=true&");

    if(!CONFIRMED_REMOVAL){
		return (
			<div className="content-page" style={{margin: "0"}} >
				<div className="content">
					<div className="message-container deleted-profile">
						<div className="message-wrapper">
							<div className="message-area">

								<div className="text-center">
									<img
										src={"../../../public/assets/images/design/cancel_red.svg"}
										alt={"Ok"}/>
									<p className={"msg m-t-20"}>
										<strong>{t("profile:delete.deleteConfirmationnow")}</strong>
									</p>
									<p>
										{t("profile:delete.noregrets")}
									</p>
								</div>

								<div className="message-buttons text-center">
									<p>
										<a href={CONFIRM_URL}
										   className={"btn btn-primary waves-effect text-nowrap btn-wide"}>
											{t("profile:delete.deleteProfile")}
										</a>
									</p>
									<p>
										<a className="text-body" href="https://www.maskinbladet.dk">
											{t("profile:delete.cancel")}
										</a>
									</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}


    useEffect(() => {
        (async () => {
            let response = await trackPromise(UserApi.removeProfile(token));
            if (response.status === 200) {
                setRemoveStatus(REMOVE_SUCCESS);
            } else {
                setRemoveStatus(REMOVE_FAILED);
            }
        })();
    }, []);

    if (removeStatus === REMOVE_SUCCESS) {

        return (
			<div className="content-page" style={{margin: "0"}} >
				<div className="content">
					<div className="message-container deleted-profile">
						<div className="message-wrapper">
							<div className="message-area">

								<div className="text-center">
									<img
										src={"../../../public/assets/images/design/ok_green.svg"}
										alt={"Ok"}/>
									<p className={"msg m-t-20"}>
										<strong>{t("profile:delete.profileDeletedSuccess")}</strong>
									</p>
								</div>

								<div className="message-buttons text-center">
									<a href="https://www.maskinbladet.dk"
									   className={"btn btn-primary waves-effect text-nowrap btn-wide"}>
										{t("profile:delete.backToAddress")}
									</a>
								</div>

							</div>
						</div>
					</div>
			</div>
		</div>
        )

    } else if (removeStatus === REMOVE_FAILED) {

        return (
			<div className="content-page" style={{margin: "0"}} >
				<div className="content">
					<div className="message-container">
						<div className="message-wrapper">
							<div className="message-area">

								<div className="text-center">
									<img
										src={"../../../public/assets/images/design/cancel_red.svg"}
										alt={"Failed"}/>
									<p className={"msg m-t-20"}>
										<strong>{t("profile:errors.couldNotDelete")}</strong>
									</p>
									<p className="msg-small">
										{t("profile:tryAgain")} <a href={t("profile:tryAgainTlfHref")}>{t("profile:tryAgainTlf")}</a> eller <a href={t("profile:tryAgainMailHref")}>{t("profile:tryAgainMail")}</a>
									</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
        )

    } else {
        return (
            <div> </div>
        )

    }
}