import React from 'react';
import { useField } from 'formik';

const InputTextFieldGroup = ({ handleUpdate, handleRemove, isSaved, handleFocus, errorMessage, ...props }) => {

    const [field, meta] = useField(props);
    const hasError = (meta.touched && meta.error || errorMessage) ? 'has-error' : '';
    const alertSuccess = (isSaved && !errorMessage) ? 'has-success' : '';
    const savedSuccess = (isSaved && !errorMessage) ? 'success' : '';

    return (
        <div className={"form-group row mx-0"}>
            <div className={`input-group ${hasError} ${alertSuccess} with-controls`}>
                <div className="col">
                    <input
                        {...field}
                        {...props}
                        placeholder="Indtast e-mailadresse"
                        className="form-control px-0"
                        onFocus={handleFocus}
                    />
                </div>
                <div className="col-auto px-0">
                    {( meta.value !== meta.initialValue && meta.value !== "" ) &&
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleUpdate}
                        >
                            Gem <i className="mdi mdi-content-save" />
                        </button>
                    }
                    {( meta.value === meta.initialValue && meta.initialValue !== "" ) &&
                    <button
                        type="button"
                        className="btn btn-link-red"
                        onClick={handleRemove}
                    >
                        Slet <i className="mdi mdi-minus-circle" />
                    </button>
                    }
                </div>
            </div>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            {errorMessage && <div className="error">{errorMessage}</div>}
            {meta.touched && isSaved && <div className={savedSuccess}>Gemt</div>}
        </div>
    );
}

export default InputTextFieldGroup;