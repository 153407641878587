import React from "react";
import {Link} from "react-router-dom";
import {Form, Formik} from "formik";
import InputTextField from "../../commonComponents/InputTextField";
import {validate} from "./validate";
import FormErrors from "../../commonComponents/FormErrors";
import {useTranslation, Trans} from "react-i18next";

let topLogo = "/public/assets/images/design/medielogin.svg";
let portalName = "medielogin";
import queryString from 'query-string';
const parsed = queryString.parse(location.search);
let utmCampaign = parsed.utm_campaign;

if(typeof utmCampaign !== "undefined"){
    utmCampaign = utmCampaign.toLowerCase();
    if(utmCampaign.indexOf("maskinteknik")>=0) topLogo = "/public/assets/images/design/maskinteknik.svg";
    if(utmCampaign.indexOf("maskinbladet")>=0) topLogo = "/public/assets/images/design/maskinbladet.svg";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) topLogo = "/public/assets/images/design/fritidsmarkedet.svg";
    if(utmCampaign.indexOf("gronteknik")>=0) topLogo = "/public/assets/images/design/gronteknik.svg";

    if(utmCampaign.indexOf("maskinteknik")>=0) portalName = "maskinteknik";
    if(utmCampaign.indexOf("maskinbladet")>=0) portalName = "maskinbladet";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) portalName = "fritidsmarkedet";
    if(utmCampaign.indexOf("gronteknik")>=0) portalName = "gronteknik";
}


let ForgotPasswordForm = (props) => {

    const { t } = useTranslation();

    return (

        <Formik
            initialValues={{ email: '', portalName: portalName}}
            validate={validate(t)}
            onSubmit={props.onSubmit}
        >
            {({ errors, touched }) => (
                <Form className="form-horizontal h-100 d-flex flex-column justify-content-between" noValidate={true}>

                    <div className="d-flex flex-column text-center page-top">
                        <p className="text-uppercase my-0"><img src={topLogo} style={{'width':'50%'}} /></p>
                    </div>

                    <div className="d-flex flex-column">
                        <div className={"row"}>
                            <div className={"col-12 text-center"}>
                                <h1>{t("forgotPassword:resetLink.pageHeader")}</h1>

                                <p>{t("forgotPassword:resetLink.intro")}</p>
                                <p>{t("forgotPassword:resetLink.enterEmail")}</p>
                            </div>
                        </div>

                        <FormErrors
                            errors={errors}
                            touched={touched}
                        />

                        <input
                            name={"portalName"}
                            type="hidden"
                            value={portalName}
                        />

                        <div className="row">
                            <div className="col-12">
                                <InputTextField
                                    name={"email"}
                                    type="text"
                                    label={"E-mail"}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="d-flex flex-column">
                        <div className="row">
                            <div className="col text-center">
                                <p>
                                    <button type="submit" className="btn btn-primary btn-wide">Send e-mail</button>
                                </p>
                                <p>
                                    <Link to={"/auth/login"} className={"text-body"}>Fortryd</Link>
                                </p>
                            </div>
                        </div>
                    </div>

                </Form>
            )}
        </Formik>
    )
};

export default ForgotPasswordForm;