import { HttpClient } from "../services/HttpClient";

export const dawaAddressTypes = {
    street: 'vejnavn',
    address: 'adgangsadresse',
    directAddress: 'adresse'
}

export const findAddress = (query, addressType) => {

    let searchParams = new URLSearchParams({
        q: query,
        type: 'adresse',
        caretpos: query.length,
        supplerendebynavn: true,
        stormodtagerpostnumre: true,
        multilinje: true,
        fuzzy: '',
        startfra: addressType
    });

    return HttpClient('https://dawa.aws.dk/autocomplete?' + searchParams.toString(), {
        method: 'GET'
    });
};