import React, {useEffect, useState} from 'react';
import {trackPromise} from "react-promise-tracker";
import * as SubscriptionAPI from "../../http/SubscriptionApi";
import * as AuthAPI from "../../http/AuthAPI";
import PaymentInfoPanel from "./PaymentInfoPanel";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";
import {getCCPaymentUrl} from "../../http/SubscriptionApi";



let ChangeCard = (props) => {

    const { t } = useTranslation();


    useEffect(() => {
        (async() => {
            const ccPaymentUrlResponse = await trackPromise(SubscriptionAPI.getCCPaymentUrl());
            const pUrlJson = await trackPromise(ccPaymentUrlResponse.json());
            paymentiframe.src = pUrlJson['paymentUrl'];
         })();
    }, []);

    return (
        <div id="payment-info">
            <center>
                <iframe style={{width:400, height:610}} id="paymentiframe" frameBorder="0" src="/#/ifloading"></iframe>
            </center>
            <ContentBottom />

        </div>
    )
}

export default ChangeCard;