import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';
import {logout } from '../../actions/authActions';
import * as TokenProviderService from '../../services/TokenProviderService';
import {useTranslation, Trans} from "react-i18next";
import {createHashHistory} from 'history';

const portalsInfo = {
    'MB.DK': {
        url: 'https://www.maskinbladet.dk',
        name: 'Maskinbladet',
        color: '#1787c6',
        textColor: '#ffffff',
        className: '',
    },
    'DB.DK': {
        url: 'http://www.dagensbyggeri.dk',
        name: 'Dagensbyggeri',
        color: '#1787c6',
        textColor: '#ffffff',
        className: '',
    },
    'MT.DK': {
        url: 'http://www.maskinteknik.dk',
        name: 'Maskinteknik',
        color: '#ffcb05',
        textColor: '#00000a',
        className: 'hover-text-black-to-white',
    },
    'FM.DK': {
        url: 'https://www.fritidsmarkedet.dk',
        name: 'Fritidsmarkedet',
        color: '#DA4626',
        textColor: '#ffffff',
        className: '',
    },
    'GT.DK': {
        url: 'https://www.gronteknik.dk',
        name: 'Grønteknik',
        color: '#2CB34A',
        textColor: '#ffffff',
        className: '',
    }
};

let useRereshEvent = true;

let ContentBottom = (props) => {

    const { t } = useTranslation();

    let [loggedFromPortal, setLoggedFromPortal] = useState(null);
    let [portalReturnUrl, setPortalReturnUrl] = useState(null);

    const refreshToken = TokenProviderService.getRefreshToken()

    let queryString = '';
    if (refreshToken) {
        queryString = '?mltoken=' + refreshToken;
    }

    useEffect(() => {
        setLoggedFromPortal(TokenProviderService.getPortal());
        setPortalReturnUrl(TokenProviderService.getPortalReturnUrl());

        handleContentBackSize(window.location.hash.slice(2));
    }, []);

    const handleContentBackSize = (pathname) => {
        const ignores = ['interesser', 'betaling', ];

        if (ignores.includes(pathname)) {
            document.getElementsByClassName("page-content-wrapper")[0].style.marginTop = "0px";
            document.getElementById("index").style.paddingBottom = "0px";
            return false;
        } else {
            if (document.getElementsByClassName("content-back").length >= 1 && window.screen.width <= 768 && !ignores.includes(pathname)) {
                document.getElementById("index").style.paddingBottom = "100px";
                document.getElementsByClassName("page-content-wrapper")[0].style.marginTop = "50px";
            } else {
                document.getElementById("index").style.paddingBottom = "0";
                if (window.screen.width <= 768) {
                    document.getElementsByClassName("page-content-wrapper")[0].style.marginTop = "0";
                }
            }
        }
    };

	function debounce(func){
		var timer;
		return function(event){
			if(timer) clearTimeout(timer);
			timer = setTimeout(func,100,event);
		};
	}

	if (useRereshEvent === true) {
		useRereshEvent = false;
		window.addEventListener("resize", debounce(function (e) {
			handleContentBackSize(window.location.hash.slice(2));
		}));
	}


    const history = createHashHistory();
    history.listen((location) => {
            handleContentBackSize(location.pathname.slice(1));
    });

    return (
        <div className={"content-back"}>

            {
                loggedFromPortal &&
                <a className={portalsInfo[loggedFromPortal].className} href={portalReturnUrl ? portalReturnUrl : portalsInfo[loggedFromPortal].url} style={{backgroundColor: portalsInfo[loggedFromPortal].color}}>
                    <span>{t("sideMenu:backTo")} <strong>{portalsInfo[loggedFromPortal].name}</strong></span>
                </a>
            }

            {
                !loggedFromPortal &&
                <a href={portalReturnUrl ? portalReturnUrl : portalsInfo['MB.DK'].url + queryString}>
                    <span>{t("sideMenu:backTo")} <strong>{portalsInfo['MB.DK'].name}</strong></span>
                </a>
            }

        </div>
    );
};

export default ContentBottom;