import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

export default (props) => {

    const { t } = useTranslation();

    return (
        <div className="h-100 d-flex flex-column justify-content-between">

            <div className="d-flex flex-column text-center page-top">
                <p className="text-uppercase my-0">FBG MEDIERLOGIN</p>
            </div>

            <div className="d-flex flex-column">
                <div className="text-center">
                    <img
                        src={"../../../public/assets/images/design/cancel_red.svg"}
                        alt={"Cancel"} />
                    <p className={"msg m-t-20"}>
                        <strong>{props.errMsg}</strong>
                    </p>
                </div>
            </div>

            <div className="d-flex flex-column">
                <div className="text-center">
                    <p><Link to="/">{ t("auth:loginFailed") }</Link></p>
                </div>
            </div>

        </div>
    )
}