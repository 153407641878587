import React, {useEffect, useState} from 'react';
import {trackPromise} from "react-promise-tracker";
import * as SubscriptionAPI from "../../http/SubscriptionApi";
import * as AuthAPI from "../../http/AuthAPI";
import PaymentInfoPanel from "./PaymentInfoPanel";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

const portalInfo = {
    maskinbladet: {
        portalName: 'maskinbladet',
        externalPID: 'MB.DK',
        imageSource:'../../../public/assets/images/design/maskinbladet.svg'
    },

    fritidsmarkedet: {
        portalName: 'fritidsmarkedet',
        externalPID: 'FM.DK',
        imageSource:'../../../public/assets/images/design/fritidsmarkedet.svg'
    },

    dagensbyggeri: {
        portalName: 'dagensbyggeri',
        externalPID: 'DB.DK',
        imageSource:'../../../public/assets/images/design/dagensbyggeri.svg'
    },

    maskinteknik: {
        portalName: 'maskinteknik',
        externalPID: 'MT.DK',
        imageSource:'../../../public/assets/images/design/maskinteknik.svg'
    }
};

let PaymentInfo = (props) => {

    const { t } = useTranslation();

    let [packages, setPackages] = useState([]);
    let [usedPortals, setUsedPortals] = useState([]);
    let [currentUserIterasId, setCurrentUserIterasId] = useState(0);



    useEffect(() => {
        (async() => {
            const packagesResponse = await trackPromise(SubscriptionAPI.getUserPackages());
            const packagesJson = await trackPromise(packagesResponse.json());
            setPackages(packagesJson);

            let tmpUsedPortals = [];
            for (let iterasPackage of packagesJson) {
                if (!tmpUsedPortals.includes(iterasPackage.portalName)) {
                    tmpUsedPortals.push(iterasPackage.portalName);
                }
            }
            setUsedPortals(tmpUsedPortals);

            const currentUserResponse = await trackPromise(AuthAPI.getCurrentUser());
            const currentUserJson = await trackPromise(currentUserResponse.json());
            setCurrentUserIterasId(currentUserJson.iterasId);

        })();
    }, []);

    return (
        <div id="payment-info">
            <div className={"row mt-0 mt-md-5"}>
                <div className={"col-sm-10 offset-sm-1"}>
                    <div className="px-30">
                        <h1>{t("payment:yourPayment.pageHeader")}</h1>
                    </div>
                </div>
            </div>

            {
                usedPortals.map((portalName, index) => {
                    const portal = portalInfo[portalName];

                    return (
                        <div key={index}>
                            <div className={"row"}>
                                <div className={"col-sm-10 offset-sm-1"}>
                                    <div className={"px-30 m-b-20 m-t-5"}>
                                        <img src={portal.imageSource} className="img-fluid" alt={portalName} />
                                    </div>
                                </div>
                            </div>
                            {
                                packages
                                    .filter(iterasPackage => {
                                        return +iterasPackage.packageOwner.iterasId === +currentUserIterasId;
                                    })
                                    .map((iterasPackage, index) => {
                                        if (iterasPackage.portalName === portalName) {
                                            return (
                                                <PaymentInfoPanel
                                                    key={index}
                                                    currentUserIterasId={currentUserIterasId}
                                                    iterasPackage={iterasPackage}
                                                />
                                            )
                                        }
                                    })
                            }

                        </div>
                    )
                })
            }
            <ContentBottom />

        </div>
    )
}

export default PaymentInfo;