export const formatToDanishDate = (dateInSeconds) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    let dt = new Date(dateInSeconds * 1000);
    return new Intl.DateTimeFormat('da-DK', options).format(dt);
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const noFraction = new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const withFraction = new Intl.NumberFormat('da-DK', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const formatCurrency = (price) => {
    if (price % 1 == 0) {
        return noFraction.format(price) + ',- kr.';
    } else {
        return withFraction.format(price) + ' kr.';
    }
}