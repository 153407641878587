import * as AuthAPI from '../http/AuthAPI';
import * as constants from './actionConstants';
import * as tokenProviderService from '../services/TokenProviderService';
import {trackPromise} from "react-promise-tracker";
import {updateLastLoginTime} from "../http/UserApi";
import * as PaymentApi from "../http/PaymentApi";

export const login = (email, password, rememberMe = false, callback = null) => {
    return async (dispatch) => {
        let response = await trackPromise(AuthAPI.login(email, password));

        if(callback) {
            callback(response);
        }

        if (response.status === 200) {
            let credentials = await response.json();
            await tokenProviderService.setCredentials(
                credentials.token,
                credentials.refresh_token,
                rememberMe
            );
            dispatch(loginSuccess());
            dispatch(loginAttemptSuccess());
            await updateLastLoginTime();
        }
    }
};

export const loginFacebook = (accessToken, rememberMe = false) => {
    return async (dispatch) => {
        let response = await trackPromise(AuthAPI.facebookLogin(accessToken));
        if (response.status === 200) {
            let credentials = await response.json();
            await tokenProviderService.setCredentials(
                credentials.token,
                credentials.refresh_token,
                rememberMe
            );
            dispatch(loginSuccess());
            dispatch(loginAttemptSuccess());
        }

        return response;
    }
};

export const loginLinkedIn = (accessToken, rememberMe = false) => {
    return async (dispatch) => {
        let response = await trackPromise(AuthAPI.linkedInLogin(accessToken));
        if (response.status === 200) {
            let credentials = await response.json();
            await tokenProviderService.setCredentials(
                credentials.token,
                credentials.refresh_token,
                rememberMe
            );
            dispatch(loginSuccess());
            dispatch(loginAttemptSuccess());
        }

        return response;
    }
};

export const autoLoginFromToken = () => {
    return async (dispatch) => {
        let accessToken = await trackPromise(tokenProviderService.getAccessToken());
        if (null != accessToken) {
            dispatch(loginSuccess());
        }
    };
};

export const logout = () => {
    tokenProviderService.removeCredentials();
    return logoutSuccess();
};

export const getCurrentUser = () => {
    return async (dispatch) => {
        let response =  await trackPromise(AuthAPI.getCurrentUser());

        if (response.status !== 200) {
            return;
        }

        let currentUser = await response.json();
        dispatch(getCurrentUserSuccess(currentUser));
    }
};


function transformDate(dateString) {
    let parts = dateString.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    let myDate = new Date(parts[0], parts[1] - 1, parts[2]);
    return Utils.formatToDanishDate(myDate.getTime() / 1000);
}

function parseDate(str) {
    var mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);

}

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export const checkForUnPaidInvoices = () => {
    return async (dispatch) => {
        let invoicesResponse = await trackPromise(PaymentApi.getInvoices());
        if (invoicesResponse.status !== 200) {
            // 	dispatch(await trackPromise(invoicesResponse.json()));
            return;
        }
        let hasUnPaid = false;
        let invoices = await invoicesResponse.json();
        invoices.map(function (invoice, index) {
            let today = new Date();
            let daysBetween = datediff(parseDate(invoice.dueDate), parseDate(today.toISOString().split('T')[0]));
            if (daysBetween >= 2 && invoice.toPay !== 0) {
                hasUnPaid = true;
                return;
            }
        });

        dispatch(getInvoice(hasUnPaid));
    }
};

export const loginSuccess = () => ({
    type: constants.CURRENT_USER_LOGIN_SUCCESS,
    isLoggedIn: true
});

export const logoutSuccess = () => ({
    type: constants.CURRENT_USER_LOGOUT,
    isLoggedIn: false
});

export const loginAttemptSuccess = () => ({
    type: constants.CURRENT_USER_LOGIN_ATTEMPT_SUCCESS
});

export const loginAttemptFailed = () => ({
    type: constants.CURRENT_USER_LOGIN_ATTEMPT_FAILED
});

export const getCurrentUserSuccess = (user) => ({
    type: constants.GET_CURRENT_USER_SUCCESS,
    currentUser: user
});

export const getInvoice = (invoice) => ({
    type: constants.CURRENT_USER_INVOICE,
    CURRENT_USER_INVOICE: invoice,
});

