import React, {useEffect, useState} from 'react';
import * as PaymentApi from '../../http/PaymentApi';
import * as Utils from '../commonComponents/Utils';
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

export default () => {

    let [invoices, setInvoices] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {

        (async () => {

            const invoicesResponse = await trackPromise(PaymentApi.getInvoices());

            if (invoicesResponse.status === 200) {
                setInvoices(await trackPromise(invoicesResponse.json()));
            }

        })();

    }, []);

    function transformDate(dateString)
    {
        let parts = dateString.split('-');
        // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
        // January - 0, February - 1, etc.
        let myDate = new Date(parts[0], parts[1] - 1, parts[2]);
        return Utils.formatToDanishDate(myDate.getTime()/1000);
    }

	function parseDate(str) {
		var mdy = str.split('-');
		return new Date(mdy[0], mdy[1] - 1, mdy[2]);

	}

	function datediff(first, second) {
		// Take the difference between the dates and divide by milliseconds per day.
		// Round to nearest whole number to deal with DST.
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}

    // downloadUrl: "https://app-test.iteras.dk/fbg/invoice/o/798800.pdf?timepageauth=WzI0Njk4MiwiL2ZiZy9pbnZvaWNlL28vNzk4ODAwLnBkZiIsMzYwMF0%3A1kLRS7%3AOy2TgJZrksVTN09JFcTNUCzjZG4"
    // dueDate: "2020-10-08"
    // invoiceDate: "2020-09-24"
    // invoiceNumber: 798800
    // toPay: 337.5

    return (
        <div id="invoices">

            <div className={"row mt-5"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <div className="px-30">
                        <h1>{t("payment:invoices.pageHeader")}</h1>
                    </div>
                </div>
            </div>
            {
                invoices.map((invoice, index) => {
						 let today = new Date();
						 let daysBetween = datediff(parseDate(invoice.dueDate), parseDate(today.toISOString().split('T')[0]));
						 let notPaid = '';
						 let payNow = '';
						 let notPaidClass = '';
						 if (daysBetween >= 2 && invoice.toPay !== 0) {
							 notPaid = <span className={"font-18 font-weight-bold payment-invoice__notpaid"}>Ikke betalt: </span>;
							 notPaidClass = 'not-paid';
						 }

                         if(invoice.paymentUrl!=""){
                             notPaid = <span className={"font-18 font-weight-bold payment-invoice__notpaid"}>Ikke betalt: </span>;
                             payNow = <span className={"font-18 font-weight-bold payment-invoice__notpaid"}>Betal nu</span>;
                             notPaidClass = 'not-paid';
                             invoice.downloadUrl = invoice.paymentUrl;
                         }

                    return (
                        <div className="row m-t-10" key={index}>
                            <div className="col-12 col-lg-10 col-xl-8 mx-auto ">
                                <a href={invoice.downloadUrl} target="_blank" alt={t("payment:invoices.download")} title={t("payment:invoices.download")}>
                                    <div className={"payment-container " + notPaidClass}>
                                        <div className="payment-invoice">

                                            <p className="font-18 font-weight-bold">{notPaid}{transformDate(invoice.invoiceDate)}</p>
                                            <small><strong>{t("payment:invoices.invoiceNo")}</strong> {invoice.invoiceNumber}</small>
                                            <p>{payNow}</p>
                                        </div>
                                        <div className={"payment-arrow " + notPaidClass}> </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    )
                })
            }

            <ContentBottom />
        </div>
    )
};