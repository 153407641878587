export const validate = (t) => {

    return values => {
        const errors = {};
        if (!values.email) {
            errors.email = t('auth:validate.emailRequired');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('auth:validate.invalidEmail');
        }

        if (!values.password) {
            errors.password = t('auth:validate.passRequired');
        }

        return errors;
    }
}