import {AuthHttpClient} from "../services/AuthHttpClient";
import {HttpClient} from "../services/HttpClient";

export const login = (username, password) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/auth/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password, directLogin: '1'})
    });
};

export const facebookLogin = (accessToken) => {

    let formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('directLogin', '1');

    return HttpClient(MEMBERS_API_URL + `/api/v1/auth/facebook/login` , {
        method: 'POST',
        body: formData
    });
};

export const linkedInLogin = (accessToken) => {

    let formData = new FormData();
    formData.append('accessToken', accessToken);

    return HttpClient(MEMBERS_API_URL + `/api/v1/auth/linkedin/login` , {
        method: 'POST',
        body: formData
    });
};

export const refreshAccessToken = (refreshToken) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("refresh_token", refreshToken);

    return HttpClient(MEMBERS_API_URL + '/api/v1/auth/token/refresh', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: urlencoded
    });
};

export const getCurrentUser = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/current-user?getsuspended=true', {
        method: 'GET',
    });
};