import React, {useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import * as AuthApi from "../../http/AuthAPI";
import * as UserProfileApi from "../../http/UserProfileApi";
import PasswordModalForm from "./PasswordModalForm";
import * as UserApi from "../../http/UserApi";
import {Trans, useTranslation} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

import * as ProfileApi from "../../http/UserProfileApi";
import {Form, Formik} from "formik";

export default () => {

	const {t} = useTranslation();
	let history = useHistory();
	let [profile, setProfile] = useState({
		"id": 0,
		"email": "",
		"name": "",
		"surname": "",
		"location": null,
		"shippingLocation": null,
		"facebookAccount": null,
		"linkedInAccount": null,
		"iterasUserType": "private",
		"socialAccount": false,
		"hasPassword": false
	});

	let [facebookAccountName, setFacebookAccountName] = useState(null);
	let [isModalOpened, changeIsModalOpened] = useState(false);

	useEffect(() => {
		(async () => {
			let response = await trackPromise(UserProfileApi.getProfile());
			if (response.status === 200) {
				let responseBody = await response.json();
				setProfile(responseBody);

				if (responseBody.facebookAccount) {
					let facebookName =
						 (responseBody.facebookAccount.firstName ? responseBody.facebookAccount.firstName : '') + " " +
						 (responseBody.facebookAccount.lastName ? responseBody.facebookAccount.lastName : '');
					facebookName = facebookName.trim();

					if (facebookName) {
						setFacebookAccountName(facebookName);
					} else {
						setFacebookAccountName(t("profile:view.yourFacebookLink"));
					}
				}

				var dropRegion = document.getElementById("drop-region");

				if (dropRegion != null) {
					dropRegion.addEventListener('dragenter', preventDefault, false);
					dropRegion.addEventListener('dragleave', dragLeave, false);
					dropRegion.addEventListener('dragover', dragOver, false);
					dropRegion.addEventListener('drop', handleDrop, false);
				}


			}
		})();
	}, []);

	async function bindFacebookAccount(e) {
		e.preventDefault();
		const redirectUrl = encodeURIComponent('/#/bind/facebook-account');
		window.location.href = MEMBERS_API_URL + '/api/v1/auth/facebook/access-token?redirectPath=' + redirectUrl;
	}

	async function unbindFacebookAccount() {
		let response = await trackPromise(UserProfileApi.unbindFacebookAccount());

		if (response.status !== 200) {
			history.push('/profile-unbind-failed');
		}

		response = await trackPromise(AuthApi.getCurrentUser());
		if (response.status === 200) {
			let responseBody = await response.json();
			setProfile(responseBody);
		}
	}

	function handleClickUploadAvatar(e) {
		e.preventDefault();
		history.push('/upload-avatar');
	}

	const openPasswordModalForm = (e) => {
		e.preventDefault();
		if (profile.hasPassword) {
			unbindFacebookAccount();
			console.log("FB Unbinded");
		}
		changeIsModalOpened(true);
	}

	const closePasswordModalForm = (e, resetForm) => {
		e.preventDefault();
		resetForm({});
		changeIsModalOpened(false);
	}

	async function handleSubmit(values, {resetForm}) {

		resetForm({});
		changeIsModalOpened(false);

		let response = await trackPromise(UserApi.changeUserPassword(
			 null,
			 values.newPassword,
			 values.confirmNewPassword
		));

		if (response.status !== 200) {
			history.push('/profile-password-failed');
		} else {
			await unbindFacebookAccount();
		}
	}

	async function handleRemoveAvatar(e) {
		e.preventDefault();
		await ProfileApi.removeAvatar();
		window.location.reload(false);
		history.push("/profiloversigt");
	}

	function inputChange(event) {
		handleAvatarUpload(event.currentTarget.files[0]);
	}

	let inputFile = '';

	function triggerClickInput(e) {
		if (e.target !== 'i') {
			inputFile.click();
		}

	}

	async function handleAvatarUpload(avatar) {
		let response = await ProfileApi.uploadAvatar(avatar);
		if (response.status === 200) {
			// history.push("/profiloversigt");
			window.location.reload();
		} else {
			alert("Der gik noget galt vent lidt og prøv igen.")
		}
	}

	function preventDefault(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	function handleDrop(e) {
		e.preventDefault();
		e.stopPropagation();
		var data = e.dataTransfer,
			 files = data.files;

		console.log(data)
		handleAvatarUpload(files[0]);
	}

	function dragOver(e) {
		e.preventDefault();
		e.stopPropagation();
		document.querySelector('.image-wrapper').classList.add('active');
	}

	function dragLeave(e) {
		e.preventDefault();
		e.stopPropagation();
		document.querySelector('.image-wrapper').classList.remove('active')
	}

	return (
		 <div id={"profile-view"}>
			 <div className={"row"}>
				 <div className={"col text-center intro"}>

					 {
						 profile.profilePicture &&
						 <div className={"image-upload"} id={'drop-region'}>

							 <Formik initialValues={{avatar: null}}>

								 {({errors, touched, values, setFieldValue}) => (

									  <Form noValidate={true}>
										  <i className={"fbg-icons fbg-icons-x"} onClick={handleRemoveAvatar}/>

										  <input className={"form-control"}
													id="avatar"
													name="avatar"
													type="file"
													ref={input => {
														// assigns a reference so we can trigger it later
														inputFile = input;
													}}
													onChange={(event) => {
														setFieldValue("avatar", event.currentTarget.files[0]);
														inputChange(event)
													}}
										  />

										  <div className="image-wrapper" onClick={triggerClickInput}>
											  <div className="rounded-circle image" style={{background: `url(${MEMBERS_API_URL + "/uploads/user/avatar/" + profile.profilePicture}) center center`}}>
												  <div className={"image-text"}>Klik eller træk et billede hertil</div>
											  </div>
										  </div>

									  </Form>

								 )}

							 </Formik>
						 </div>
					 }



					 {
						 !profile.profilePicture &&
							  						 <div className={"image-upload"} id={'drop-region'}>

							 <Formik initialValues={{avatar: null}}>

								 {({errors, touched, values, setFieldValue}) => (

									  <Form noValidate={true}>

										  <input className={"form-control"}
													id="avatar"
													name="avatar"
													type="file"
													ref={input => {
														// assigns a reference so we can trigger it later
														inputFile = input;
													}}
													onChange={(event) => {
														setFieldValue("avatar", event.currentTarget.files[0]);
														inputChange(event)
													}}
										  />

										  <div className="image-wrapper" onClick={triggerClickInput}>
											  <div className="rounded-circle image" style={{background: `url("../../../public/assets/images/design/grayed_man.svg") center center`}}>
												  <div className={"image-text"}>Klik eller træk et billede hertil</div>
											  </div>
										  </div>

									  </Form>

								 )}

							 </Formik>
						 </div>

						 // <img style={{cursor: 'pointer'}} onClick={handleClickUploadAvatar} className="rounded-circle" src={"../../../public/assets/images/design/grayed_man.svg"} title="Upload billede"/>
					 }

					 <h2 className={"font-weight-bold"}>{profile.name} {profile.surname}</h2>

					 {
						 <div className={"mb-5"}>
							 {'company' === profile.iterasUserType && profile.companyName}
							 {'company' === profile.iterasUserType && profile.cvrNumber &&
							 <small><br/>CVR: {profile.cvrNumber}</small>}
						 </div>
					 }

				 </div>
			 </div>

			 <div className={"row"}>
				 <div className={"col-sm-10 offset-sm-1"}>







					 <div className={"row"}>
						 <div className={"col-12 col-md-6 m-b-10 card-inline-left"}>
							 <div className="card">
								 <div style={{ height: '54px' }} className="card-body">

									 <div className="container">
										 <div className={"row align-items-center"}>
											 <div className={"col-auto px-0"}>
												 <img className="icon-img"
														src={"../../../public/assets/images/design/icons/places.svg"}
														alt={"Places"}/>
											 </div>
											 <div className={"col"}>
												 {
													 profile.location === null
													 &&
													 <Link to={"/ret_profil"} className={"edit text-uppercase text-nowrap"}>
														 {t('profile:view.addAddress')}
													 </Link>
												 }
												 {
													 profile.location !== null
													 &&
													 <div>
														 {profile.location.address}<br/>
														 <small>{profile.location.zip} {profile.location.city}</small>
													 </div>
												 }
											 </div>
											 <div className={"col-auto text-right px-0"}>
												 <Link to={"/ret_profil"} className={"edit text-uppercase text-nowrap"}>
													 {t('profile:view.editProfile')} &nbsp;
													 <img className="edit-img"
															src={"../../../public/assets/images/design/icons/pencil.svg"}
															alt={"Ret"}/>
												 </Link>
											 </div>
										 </div>
									 </div>

								 </div>
							 </div>
						 </div>
						 <div className={"col-12 col-md-6 m-b-10 card-inline-right"}>
							 <div className="card">
								 <div style={{ height: '54px' }} className="card-body">

									 <div style={{ paddingTop: '15px' }} className="container">
										 <div className={"row align-items-center"}>
											 <div className={"col-auto px-0"}>
												 <img className="icon-img"
													  src={"../../../public/assets/images/design/icons/phone.svg"}
													  alt={"Phone"}/>
											 </div>
											 <div className={"col"}>
												 {profile.phone}
											 </div>
											 <div className={"col-auto text-right px-0"}>
												 <Link to={"/ret_profil"} className={"edit text-uppercase text-nowrap"}>
													 {t('profile:view.editPhone')} &nbsp;
													 <img className="edit-img"
														  src={"../../../public/assets/images/design/icons/pencil.svg"}
														  alt={"Ret"}/>
												 </Link>
											 </div>
										 </div>
									 </div>

								 </div>
							 </div>
						 </div>
					 </div>







					 <div className={"row"}>
						 <div className={"col-12 col-md-6 m-b-10 card-inline-left"}>

							 <div className={"card"}>
								 <div className={"card-body"}>

									 <div className="container">
										 <div className={"row align-items-center"}>
											 <div className={"col-auto px-0"}>
												 <img className="icon-img"
														src={"../../../public/assets/images/design/icons/envelope.svg"}
														alt={profile.email} title={profile.email}/>
											 </div>
											 <div id="profileEmail" className={"col"}>
												 {profile.email}
											 </div>
											 {
												 profile.isActivated &&
												 <div className={"col-auto text-right text-nowrap px-0"}>
													 <Link to={"/ret_email"} className={"edit text-uppercase"}>
														 {t("profile:view.changeEmail")} &nbsp;
														 <img className="edit-img"
																src={"../../../public/assets/images/design/icons/pencil.svg"}
																alt={"Ret"}/>
													 </Link>
												 </div>
											 }
										 </div>
									 </div>

								 </div>
							 </div>
						 </div>

						 <div className={"col-12 col-md-6 m-b-10 card-inline-right"}>
							 <div className={"card"}>
								 <div className={"card-body"}>

									 <div className="container">
										 <div className={"row align-items-center"}>
											 <div className={"col-auto px-0"}>
												 <img className="icon-img"
														src={"../../../public/assets/images/design/icons/lock.svg"}
														alt={"Kodeord"}/>
											 </div>
											 <div id="profilePass" className={"col"}>
												 &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
											 </div>
											 <div className={"col-auto text-right text-nowrap px-0"} style={{overflow: "hidden"}}>
												 <Link to={"/skift_kodeord"} className={"edit text-uppercase"}>
													 {t("profile:view.changePassword")} &nbsp;
													 <img className="edit-img"
															src={"../../../public/assets/images/design/icons/pencil.svg"}
															alt={"Ret"}/>
												 </Link>
											 </div>
										 </div>
									 </div>

								 </div>
							 </div>

						 </div>
					 </div>

					 {/*
                        profile.isActivated &&
                        <div className={"row m-t-20"}>
                            <div className={"col"}> {t('profile:view.associatedAccounts')} </div>
                        </div>
                    */}

					 <div className={"row m-b-10"}>
						 <div className={"col"}>
							 <div className={"card"}>
								 <div className={"card-body"}>

									 <div className="container">
										 <div className={"row align-items-center"}>
											 <div className={"col-auto px-0"}>
												 <img className="icon-img"
														src={"../../../public/assets/images/design/icons/facebook.svg"}
														alt={"Facebook"}/>
											 </div>
											 {
												 profile.facebookAccount !== null &&
												 <div className={"col"}>
													 <a href={profile.facebookAccount.facebookLink}> {facebookAccountName} </a>
												 </div>
											 }

											 {
												 profile.facebookAccount !== null &&
												 <div className={"col-2 col-md-4 text-right px-0"}>
													 <i style={{cursor: "pointer", color: "#ff0000"}}
														 onClick={openPasswordModalForm}
														 className={"mdi mdi-minus-circle"}/>
													 <PasswordModalForm
														  show={isModalOpened}
														  closeModal={closePasswordModalForm}
														  onSubmit={handleSubmit}
													 />
												 </div>
											 }

											 {
												 profile.facebookAccount == null &&
												 <div className={"col"}>
													 {
														 profile.isActivated &&
														 <a href="#" onClick={bindFacebookAccount}> {t("profile:view.bindAccount")} </a>
													 }

													 {
														 !profile.isActivated &&
														 t("profile:view.bindAccount") + " [inactive]"
													 }
												 </div>
											 }

										 </div>
									 </div>

								 </div>
							 </div>
						 </div>
					 </div>

					 {
						 profile.subscribedToPrintedMagazine
						 &&
						 profile.shippingLocation === null
						 &&
						 <div className={"row m-t-20"}>
							 <div className={"col text-center"}>
								 <Link to={"/leveringsadresse"} className={"edit text-uppercase text-nowrap"}>
									 {t("profile:view.changeDeliverAddress")} &nbsp;
									 <img className="edit-img"
											src={"../../../public/assets/images/design/icons/pencil.svg"}
											alt={"Ret"}/>
								 </Link>
							 </div>
						 </div>

					 }

					 {
						 profile.subscribedToPrintedMagazine
						 &&
						 profile.shippingLocation !== null
						 &&
						 <div className={"row m-t-20"}>
							 <div className={"col"}> {t("profile:view.deliveryAddress")}</div>
						 </div>
					 }

					 {
						 profile.subscribedToPrintedMagazine
						 &&
						 profile.shippingLocation !== null
						 &&
						 <div className={"row m-t-10"}>
							 <div className={"col"}>

								 <div className={"card"}>
									 <div className={"card-body"}>

										 <div className="container">
											 <div className={"row align-items-center"}>
												 <div className={"col-auto px-0"}>
													 <img className="icon-img"
															src={"../../../public/assets/images/design/icons/places.svg"}
															alt={"Shipping location"}/>
												 </div>
												 <div className={"col"}>
													 <div>
														 {profile.shippingLocation.address}<br/>
														 <small>{profile.shippingLocation.zip} {profile.shippingLocation.city} ATT. {profile.shippingLocation.recipient}</small>
													 </div>
												 </div>
												 <div className={"col-auto text-right text-nowrap px-0"}>
													 <Link to={"/leveringsadresse"} className={"edit text-uppercase"}>
														 {t("profile:view.edit")} &nbsp;
														 <img className="edit-img"
																src={"../../../public/assets/images/design/icons/pencil.svg"}
																alt={"Ret"}/>
													 </Link>
												 </div>
											 </div>
										 </div>

									 </div>
								 </div>
							 </div>
						 </div>
					 }

				 </div>
			 </div>

			 <ContentBottom/>

		 </div>
	)
};