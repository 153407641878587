import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Form, Formik, FieldArray} from "formik";
import InputTextFieldGroup from "./InputTextFieldGroup";
import Modal from './Modal.js';
import * as Utils from '../commonComponents/Utils';
import {getUserTokenService} from "../../../../frontend-admin-dashboard/src/services/TokenProviderFactory";
import * as UserAPI from "../../../../frontend-admin-dashboard/src/http/UserApi";

const Package = (props) => {

    let t = props.t;

    let [userEmails, setUserEmails] = useState([]);
    let [emailSavedIndex, setEmailSavedIndex] = useState(null);
    let [errorMessage, setErrorMessage] = useState(null);
    let [searchUserQuery, setSearchUserQuery] = useState("");
    let [showSearchBar, setShowSearchBar] = useState(false);

    React.useEffect(() => {
        let tmpUserEmails = [];
        let countEmails = 0;

        if (props.subscribers) {

            for (let i = 0; i < props.extraSlots; i++) {
                if (props.subscribers[i]) {
                    tmpUserEmails.push(props.subscribers[i].subUser);
                    countEmails++;
                } else {
                    tmpUserEmails.push({id: '', email: ''});
                }
            }
        }

        if (countEmails > 5) {
            setShowSearchBar(true);
        } else {
            setSearchUserQuery("");
            setShowSearchBar(false);
        }

        if (searchUserQuery.length > 0 && countEmails > 5) {
            tmpUserEmails = tmpUserEmails.filter((user) => {
                return user.email.includes(searchUserQuery);
            })
        }

        setUserEmails(tmpUserEmails);
    }, [props.subscribers, searchUserQuery]);

    async function updateSubscriberHandler(portalName, email, id, index) {
        if (!portalName) {
            return;
        }

        if (email && id && (typeof validateEmail(email) !== 'string')) {
            // update subscriber
            let oldEmail = userEmails[index].email;

            if (email === oldEmail) {
                return;
            }

            setEmailSavedIndex(index);
            setErrorMessage(null);
            let response = await props.updateSubscriberHandler(portalName, email, oldEmail);

            if (response.status === 200) {
                setEmailSavedIndex(null);
            } else {
                setErrorMessage(t('package:validation.cannotUpdateThisEmail'));
            }

        } else if (email && !id && (typeof validateEmail(email) !== 'string')) {
            // add subscriber
            setEmailSavedIndex(index);
            setErrorMessage(null);
            let response = await props.addSubscriberHandler(portalName, email);

            if (response.status === 200) {
                setEmailSavedIndex(null);
            } else {
                setErrorMessage(t('package:validation.cannotAddThisEmail'));
            }

        } else if (!email && id) {
            // remove subscriber
            let oldEmail = userEmails[index].email;
            setEmailSavedIndex(null);
            setErrorMessage(null);
            let response = await props.removeSubscriberHandler(portalName, oldEmail);

            if (response.status === 200) {
                setEmailSavedIndex(null);
            } else {
                setErrorMessage(t('package:validation.cannotDeleteThisEmail'));
            }
        }
    }

    function modifyUsers() {

        let refreshToken = localStorage.getItem('jwt-refresh-token');

        let loginUrl = 'https://www.maskinbladet.dk/medielogin/rediger-antal-brugere?mltoken=' + refreshToken; //default to MB

        window.open(loginUrl, '_blank');
    }

    async function removeSubscriberHandler(portalName, index) {
        if (!portalName) {
            return;
        }

        // remove subscriber
        let oldEmail = userEmails[index].email;
        setEmailSavedIndex(null);
        setErrorMessage(null);
        let response = await props.removeSubscriberHandler(portalName, oldEmail);

        if (response.status === 200) {
            setEmailSavedIndex(null);
        } else {
            setErrorMessage(t('package:validation.cannotDeleteThisEmail'));
        }
    }

    function handleFocus() {
        setEmailSavedIndex(null);
        setErrorMessage(null);
    }

    const validateEmail = (value) => {
        let error;
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = t('package:validation.incorrectEmailAddress');
        }
        return error;
    };

    const [show, setShow] = useState(false);
    const openModal = (e) => {
        e.preventDefault();
        setShow(true);
    };
    const closeModal = () => setShow(false);
	function showCancelSubscription(e){
		props.stopSubscriptionHandler(e, props.packageId, props.title, props.portalName, props.externalPID);
		document.getElementById("index").style.paddingBottom = "0px";
	}
    function isPackageSuspended(p){
        // console.debug(p.portalName);
       var retVal = false;
       var thisPortal = p.portalName;
       p.suspendedCampaigns.forEach(function(item){
           console.debug(item.iterasPortalName+" == "+thisPortal);
           if(item.iterasPortalName == thisPortal) retVal = true;
       });
       return retVal;
    }
    return (
        <div>
            <h5>{props.title}{isPackageSuspended(props) && <span className={"red-text ml-1"}>( Suspenderet pga. manglende betaling. ) <Link to="/fakturaer">Betal her</Link></span>}</h5>
            <p>{props.description}</p>

            {
                props.isInheritedPackage &&
                <div className={"row"}>
                    <div className={"col"}>
                        <Link to={'/fjern-tilknytning/' + props.portalName} className="text-muted">
                            <i className="mdi mdi-minus-circle mr-2" />
                            <small> {t('package:package.detach')} </small>
                        </Link>
                        <span className={"text-blue-primary font-weight-bold pull-right"}> {t('package:package.associatedWithCompany')} </span>
                    </div>
                </div>
            }

            {
                props.extraSlots > 0 &&
                !props.isInheritedPackage &&
                <div className={"row mb-2"}>
                    <div className={"col"}> {t('package:package.boughtUsers')} </div>
                    <div className={"col text-right"}>{props.extraSlots}</div>
                </div>
            }

            {
                props.magazineNames.length > 0 &&
                !props.isInheritedPackage &&
                <div className={"row"}>
                    <div className={"col"}> {t('package:package.magazines')} </div>
                    <div className={"col text-right"}>{props.magazineNames}</div>
                </div>
            }

            {
                (
                    props.package.packagePrice != null
                    || props.package.magazinesPrice != null
                    || props.package.annualPrice != null
                    || props.package.subscribersPrice != null
                ) &&
                !props.isInheritedPackage &&
                <div>
                    <hr />
                    <div className={"row"}>
                        <div style={{"max-width":"20%"}} className={"col"}> {t('package:package.price')} </div>
                        <div className={"col text-right"}>

                            {
                                props.package.packagePrice
                                &&
                                <div>
                                    {props.package.packageName + ', '}
                                    <span className={"text-blue-primary font-weight-bold"}>
                                        {Utils.formatCurrency(props.package.packagePrice.price)}
                                            {props.package.packagePrice.duration === 'year' ? ' pr. år.' : ''}
                                            {props.package.packagePrice.duration === 'month' ? ' pr. md.-' : ''}
                                    </span>
                                    <p style={{"font-size":"10px"}}>
                                        {props.package.packagePrice.nextprice !== 0 && props.package.packagePrice.nextprice !== 0 ? 'Herefter ' : ''}
                                        {props.package.packagePrice.nextprice !== 0 ? Utils.formatCurrency(props.package.packagePrice.nextprice) : ''}
                                        {props.package.packagePrice.nextpriceduration === '1m' ? ' pr. md.' : ''}
                                        {props.package.packagePrice.nextpriceduration === '12m' ? ' pr. år.' : ''}
                                    </p>
                                </div>
                            }

                            {
                                props.package.annualPrice
                                &&
                                <div>
                                    {props.package.packageName + ', '}
                                    <span className={"text-blue-primary font-weight-bold"}>
                                        {Utils.formatCurrency(props.package.annualPrice.price)}
                                        {props.package.annualPrice.duration === 'year' ? ' pr. år.' : ''}
                                        {props.package.annualPrice.duration === 'month' ? ' pr. md.' : ''}
                                    </span>
                                    <p style={{"font-size":"10px"}}>
                                        {props.package.annualPrice.duration === 'three_month' && props.package.annualPrice.nextprice !== 0 ? 'Herefter ' : ''}
                                        {props.package.annualPrice.nextprice !== 0 ? Utils.formatCurrency(props.package.annualPrice.nextprice) : ''}
                                        {props.package.annualPrice.nextpriceduration === '1m' ? ' pr. md.' : ''}
                                        {props.package.annualPrice.nextpriceduration === '12m' ? ' pr. år.' : ''}
                                    </p>
                                </div>
                            }

                            {
                                props.package.magazinesPrice
                                &&
                                <div>
                                    {t('package:package.magazinesPriceLabel')}
                                    <span className={"text-blue-primary font-weight-bold"}>
                                        {Utils.formatCurrency(props.package.magazinesPrice.price)}
                                        {props.package.magazinesPrice.duration === 'year' ? ' kr. pr. år.' : ' pr. md.'}
                                    </span>
                                </div>
                            }

                            {
                                props.package.subscribersPrice
                                &&
                                <div>
                                    {t('package:package.boughtUsersPriceLabel')}
                                    <span className={"text-blue-primary font-weight-bold"}>
                                        {Utils.formatCurrency(props.package.subscribersPrice.price)}
                                        {props.package.subscribersPrice.duration === 'year' ? ' pr. år.' : ' pr. md.'}
                                    </span>
                                </div>
                            }

                            { (props.packageType === 'company') && <small>{t('package:package.excludingVat')}</small> }
                            { (props.packageType !== 'company') && <small>{t('package:package.includingVat')}</small> }
                        </div>
                    </div>
                    <hr />
                </div>

            }

            {
                props.package.packagePrice == null
                && props.package.magazinesPrice == null
                && props.package.annualPrice == null
                && props.package.subscribersPrice == null
                && !props.isInheritedPackage
                &&
                <div>
                    <hr/>
                    <div className={"row"}>
                        <div className={"col"}> {t('package:package.price')} </div>
                        <div className={"col text-right"}>
                            {
                            props.package.abonnements[0].campaignId != 'full'
                            &&
                                <span className={"text-blue-primary font-weight-bold"}>{t('package:package.individualPrice')}</span>
                            }
                            {
                            props.package.abonnements[0].campaignId == 'full'
                            &&
                                <span className={"text-blue-primary font-weight-bold"}>{t('package:package.giftCard')}</span>
                            }

                        </div>
                    </div>
                    <hr/>
                </div>

            }

            {
                props.changeShippingAddress &&
                <div className={"row"}>
                    <div className={"col text-right"}>
                        <Link to={"/leveringsadresse"} className={"font-13 text-uppercase"}>
                            {t('package:package.changeShippingAddress')} <i className={"mdi mdi-lead-pencil button"}></i>
                        </Link>
                    </div>
                </div>
            }

            {
                props.extraSlots > 0 &&
                !props.isInheritedPackage &&
                <div>

                    <Formik enableReinitialize={true} initialValues={{subUsers: userEmails, searchUsersQuery: ''}}>
                        {({ errors, touched, values }) => (
                            <Form noValidate={true}>

                                {
                                    showSearchBar &&
                                    <div>
                                        <p>Søg efter brugere via e-mail</p>
                                        <div className={"form-group row mx-0"}>
                                            <div className={`input-group`}>
                                                <label htmlFor={"searchUserQuery"} className="col-auto col-form-label">Søg</label>
                                                <div className="col px-0">
                                                    <input
                                                        autoComplete={"false"}
                                                        name={"searchUserQuery"}
                                                        className="form-control px-0"
                                                        onChange={(e) => {setSearchUserQuery(e.target.value)}}
                                                        value={searchUserQuery}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <p>Tilknyttede brugere</p>

                                <FieldArray
                                    name="subUsers"
                                    render={(arrayHelpers) => {
                                        return (values.subUsers.length > 0) &&
                                        values.subUsers.map((subUser, index) => {
                                            return (<div className={"row"} key={index}>
                                                <div className={"col"}>
                                                    <InputTextFieldGroup
                                                        name={`subUsers.${index}.email`}
                                                        type="text"
                                                        autoComplete="nope"
                                                        validate={ validateEmail }
                                                        isSaved={(emailSavedIndex === index) && !errorMessage}
                                                        errorMessage={((emailSavedIndex === index) && errorMessage) ? errorMessage : null}
                                                        handleUpdate={ () => updateSubscriberHandler(props.portalName, subUser.email, subUser.id, index) }
                                                        handleRemove={ () => removeSubscriberHandler(props.portalName, index) }
                                                        handleFocus={ () => handleFocus() }
                                                    />
                                                </div>
                                            </div>);
                                        });
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            }


            <div className={"row"}>
                <div className={"col"}>
                    {
                        props.isPackageCanceled && !props.isInheritedPackage &&
                        <span className="text-muted"><small>{t('package:package.canceledSubscription')}</small></span>
                    }

                    {
                        !props.isPackageCanceled &&
                        props.magazineNames.length <= 1 &&
                        !props.isInheritedPackage &&
                        props.package.packagePrice == null &&
                        props.package.annualPrice == null &&
                        <span>
                            <small>
                                Udløber den.
                                {
                                    props.package.nextPaymentDate &&
                                    " " + Utils.formatToDanishDate(props.package.nextPaymentDate)
                                }
                            </small>
                        </span>
                    }

                    {
                        !props.isPackageCanceled &&
                        !props.isPackageSuspended &&
                        (props.package.packagePrice != null ||
                        props.package.annualPrice != null) &&
                        <a href="javascript:void(0)"
                           onClick={(e) => showCancelSubscription(e)}
                           className="text-muted"
                        >
                            {
                                props.magazineNames.length <= 1 &&
                                !props.isInheritedPackage &&
                                <span>
                                    <i className="mdi mdi-minus-circle mr-2"/>
                                    <small>{t('package:package.cancelSubscription')}</small>
                                </span>
                            }
                            {
                                props.magazineNames.length > 1 &&
                                !props.isInheritedPackage &&
                                <span>
                                    <i className="mdi mdi-minus-circle mr-2"/>
                                    <small>{t('package:package.cancelSubscriptions')}</small>
                                </span>
                            }
                        </a>
                    }

                    {
                        props.isPackageSuspended &&
                        <a href="#/fakturaer"
                           className="red-text"
                        >
                            <span>
                                <i className="mdi mdi-alert mr-2"/>
                                <small>Suspenderet</small>
                            </span>
                        </a>
                    }

                </div>
                {
                    props.isPackageCanceled && !props.isInheritedPackage &&
                    <div className={"col text-right"}>
                        <Link to={'/betaling'}><small>{t('package:package.seeMore')}</small></Link>
                    </div>
                }

                {
                    !props.isPackageCanceled &&
                    props.package.canHaveExtraSlots &&
                    !props.isInheritedPackage &&
                    <div className={"col text-right"}>
                        <a href="/#/abonnementer" onClick={() => modifyUsers()} className={"font-13 text-uppercase"}>
                            {t('package:package.changeSubscribersNumber')} <i className={"mdi mdi-lead-pencil button"} />
                        </a>
                        <Modal closeModal={closeModal} show={show} />
                    </div>
                }

            </div>
        </div>
    )
};

export default Package;
