import React from 'react';
import {Link} from "react-router-dom";

export default () => {

    return <div>
        <div className={"row mt-5"}>
            <div className={"col-12 col-lg-10 col-xl-8 mx-auto intro text-center"}>
                <h1>Skift din betalingsmetode</h1>

                <p className={"text-muted"}>
                    Hvilken metode ønsker du at betale med?
                </p>

                <div className="row card-wrapper">
                    <div className="col px-0">
                        <a href="#/skift-kort">
                            <div className="card quicklinks h-100">
                                <div className="card-body" style={{width:'250px'}}>
                                    <i className="frontpage-icons fbg-icons fbg-icons-credit-card"></i>
                                    <p className="card-text">Betalingskort</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col px-0">
                        <a href="#/skift-mobilepay">
                            <div className="card quicklinks h-100">
                                <div className="card-body" style={{width:'250px'}}>
                                    <img style={{ width: '50px' }} src={"../../../public/assets/images/design/mobilepay.svg"}/>
                                    <p className="card-text">MobilePay</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>;

}