import React from 'react';
import {Link} from "react-router-dom";
import { useField } from 'formik';
import {useTranslation, Trans} from "react-i18next";

const InputPasswordFieldBlock = ({ label, ...props }) => {

    const [field, meta] = useField(props);
    const hasError = (meta.touched && meta.error) ? 'has-error' : '';

    const { t } = useTranslation();

    return (
        <div className={"form-group row mx-0"}>
            <div className={`input-group ${hasError}`}>
                <label htmlFor="password" className="col-auto col-form-label">{label}</label>
                <div className="col px-0">
                    <input
                        id="password"
                        {...field}
                        {...props}
                        type={"password"}
                        className="form-control px-0"
                    />
                </div>
                <label className="col-auto col-form-label px-0">
                    <Link to={"/auth/glemtkodeord"} className={"edit text-uppercase text-nowrap"}>{ t("auth:forgotPassword") }</Link>
                </label>
            </div>
        </div>
    );
}

export default InputPasswordFieldBlock;
