import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';
import {logout } from '../../actions/authActions';
import * as TokenProviderService from '../../services/TokenProviderService';
import {useTranslation, Trans} from "react-i18next";

const portalsInfo = {
    'MB.DK': {
        url: 'https://www.maskinbladet.dk',
        name: 'maskinbladet.dk',
        color: '#1787c6',
        textColor: '#ffffff',
        className: '',
    },
    'DB.DK': {
        url: 'http://www.dagensbyggeri.dk',
        name: 'dagensbyggeri.dk',
        color: '#1787c6',
        textColor: '#ffffff',
        className: '',
    },
    'MT.DK': {
        url: 'http://www.maskinteknik.dk',
        name: 'maskinteknik.dk',
        color: '#ffcb05',
        textColor: '#00000a',
        className: 'hover-text-black-to-white',
    },
    'FM.DK': {
        url: 'https://www.fritidsmarkedet.dk',
        name: 'fritidsmarkedet.dk',
        color: '#DA4626',
        textColor: '#ffffff',
        className: '',
    },
    'GT.DK': {
        url: 'https://www.gronteknik.dk',
        name: 'grønteknik.dk',
        color: '#2CB34A',
        textColor: '#ffffff',
        className: '',
    }
};

let SideBottom = (props) => {

    const { t } = useTranslation();
    let [loggedFromPortal, setLoggedFromPortal] = useState(null);
    let [portalReturnUrl, setPortalReturnUrl] = useState(null);

    const refreshToken = TokenProviderService.getRefreshToken()

    let queryString = '';
    if (refreshToken) {
        queryString = '?mltoken=' + refreshToken;
    }

    useEffect(() => {
        setLoggedFromPortal(TokenProviderService.getPortal());
        setPortalReturnUrl(TokenProviderService.getPortalReturnUrl());
    }, []);

    let logout = (e) => {
        e.preventDefault();
        props.logout();

        if (loggedFromPortal && (loggedFromPortal in portalsInfo)) {
            window.location.href = portalsInfo[loggedFromPortal].url + PORTAL_LOGOUT_REDIRECT_PATH;
        }
    };

    return (
        <div className={"bottom-left"}>

			  {
				  loggedFromPortal &&
				  <div className={"bottom-back"} style={{backgroundColor: portalsInfo[loggedFromPortal].color}}>
					  <a className={portalsInfo[loggedFromPortal].className} href={portalReturnUrl ? portalReturnUrl : portalsInfo[loggedFromPortal].url} style={{color: portalsInfo[loggedFromPortal].textColor}}>
						  <span>{t("sideMenu:backTo")} <strong>{portalsInfo[loggedFromPortal].name}</strong></span>
					  </a>
                  <div className="triangle" style={{backgroundColor: portalsInfo[loggedFromPortal].color}}/>
				  </div>
			  }

			  {
				  !loggedFromPortal &&
				  <div className={"bottom-back"} style={{backgroundColor: portalsInfo['MB.DK'].color}}>
					  <a href={portalReturnUrl ? portalReturnUrl : portalsInfo['MB.DK'].url + queryString} style={{color: portalsInfo['MB.DK'].textColor}}>
						  <span>{t("sideMenu:backTo")} <strong>{portalsInfo['MB.DK'].name}</strong></span>
					  </a>
					  <div className="triangle"/>
				  </div>
			  }

            <div className={"bottom-links"}>
                <ul className={"list-inline"}>
                    <li className={"list-inline-item"}>
                        <NavLink to="/abonnementsvilkar" onClick={props.handleClick}>{t("sideMenu:cookies")}</NavLink><span>·</span>
                    </li>
                    <li className={"list-inline-item"}>
                        <NavLink to="/brugervilkar" onClick={props.handleClick}>{t("sideMenu:terms")}</NavLink><span>·</span>
                    </li>
                    <li className={"list-inline-item"}>
                        <a href="#" onClick={logout}>{t("sideMenu:logout")}</a>
                    </li>
                </ul>
            </div>




        </div>
    );
};

export default connect(null, {logout})(SideBottom);
