export const validate = (t) => {

    return (values) => {
        let errors = {};
        if (!values.email) {
            errors.email = t('changeEmail:validate.emailRequired');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t('changeEmail:validate.invalidEmail');
        }

        return errors;
    }
}