import React from "react";
import * as TokenProviderService from "../../services/TokenProviderService";

const BuySubscriptionPackage = (props) => {

    function handleLinkClick(e) {
        e.preventDefault()

        let url = props.portalUrl;
        const refreshToken = TokenProviderService.getRefreshToken()

        if (refreshToken) {
            url = url + '?mltoken=' + refreshToken;
        }

        window.location.href = url;
    }

    return (
        <a onClick={handleLinkClick} href="#">
            <div className="card">
                <div className="card-body">
                    <img src={props.imageSource} />
                </div>
            </div>
        </a>
    )
};

export default BuySubscriptionPackage;
