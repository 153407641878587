import React, { useState } from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";


const FaqPanel = (props) => {

    const [styles, setStyles] = useState({
        styleActive: '',
        styleOpen: false
    });

    function handleHeaderClick(prevStyles) {
        let styles = {
            styleActive: ((prevStyles.styleActive == 'active') ? '' : 'active'),
            styleOpen: !prevStyles.styleOpen
        };
        return styles;
    }

    function createMarkup(htmlFragment) {
        return {__html: htmlFragment};
    }

    return (
        <Accordion>
            <Card className={`accordion-card ${styles.styleActive}`} >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={(prevStyles) => setStyles(handleHeaderClick)}>
                    <h6>{props.question}</h6>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" in={styles.styleOpen}>
                    <Card.Body>
                        <div className="row">
                            <div className="col">

                                <div dangerouslySetInnerHTML={createMarkup(props.answer)} />

                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default FaqPanel;