import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const NewsletterCard = (props) => {

    return (
        <div className="col-12 col-md-6 px-0">
            <Card body className={"white-card card-switch"}>
                <img src={props.magazineInfo.imageSource} alt={props.magazine.magazineName} style={{'maxHeight':'22px'}} />

                <Form.Check
                    inline
                    type="switch"
                    id={props.magazine.id}
                    label=""
                    onChange={props.handleSwitchChange}
                    checked={props.checked}
                />
                {
                    props.magazineInfo.infoText &&
                    <div className="text-muted m-t-5">{props.magazineInfo.infoText}</div>
                }
                {
                    !props.magazineInfo.infoText &&
                    <div className="text-muted m-t-5">{props.newsFrequencyHTML}</div>
                }

            </Card>
        </div>
    )
}

export default NewsletterCard;