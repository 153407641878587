import React from 'react';
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

export default () => {
    const {t} = useTranslation();
    return (
        <div className={"dashboard-content-wrapper"}>

            <div className={"row"}>
                <div className={"col-12 mx-auto text-center"}>
                    <h1> {t('dashboard:pageHeader')}</h1>
                </div>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto text-center intro"}>
                    <div>
                        <Trans i18nKey="dashboard:dashboardDescriptionNew">
                            <Link to={"/abonnementer"}>subscriptions</Link>
                            <Link to={"/profiloversigt"}>profile</Link>
                            <Link to={"/interesser"}>interests</Link>
                            <Link to={"/nyhedsbreve"}>newsletters</Link>
                        </Trans>
                    </div>
             </div>
            </div>

            <div className={"row mt-5 mb-2"}>
                {/*<div className={"col text-center"}>*/}
                {/*    <h3>{t('dashboard:quickLinksHeader')}</h3>*/}
                {/*</div>*/}
            </div>

            <div className={"row"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                    <div className={"row row-cols-3 card-wrapper"}>
                        <div className={"col px-0"}>
                            <Link to={"/ret_profil"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/places.svg"}*/}
                                        {/*    alt={"Adresse"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-map-pin"}></i>
                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.address"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className={"col px-0"}>
                            <Link to={"/skift_kodeord"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/lock.svg"}*/}
                                        {/*    alt={"Adgangskode"} />*/}
                                        <i className={"frontpage-icons fbg-icons fbg-icons-lock"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.password"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className={"col px-0"}>
                            <Link to={"/interesser"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">
                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/heart.svg"}*/}
                                        {/*    alt={"Interesseområder"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-heart"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.interests"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0"}>

                            <Link to={"/abonnementer"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/bag.svg"}*/}
                                        {/*    alt={"Abonnememter"} />*/}
                                        <i className={"frontpage-icons fbg-icons fbg-icons-shopping-bag"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.subscriptions"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0"}>

                            <Link to={"/betaling"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/card.svg"}*/}
                                        {/*    alt={"Betaling"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-credit-card"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.payments"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0"}>

                            <Link to={"/fakturaer"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">
                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/invoice.svg"}*/}
                                        {/*    alt={"Fakturaer"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-file-text"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.invoices"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0"}>

                            <Link to={"/nyhedsbreve"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/envelope.svg"}*/}
                                        {/*    alt={"Nyhedsbreve"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-mail"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.newsletters"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0"}>
                            <Link to={"/faq"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">
                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/question.svg"}*/}
                                        {/*    alt={"Spørgsmål"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-help-circle"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.faq"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                        <div className={"col px-0 mx-auto"}>

                            <Link to={"/kontakt"}>
                                <div className="card quicklinks h-100">
                                    <div className="card-body">

                                        {/*<img className="img-fluid"*/}
                                        {/*    src={"../../../public/assets/images/design/icons/info.svg"}*/}
                                        {/*    alt={"Kontakt"} />*/}

                                        <i className={"frontpage-icons fbg-icons fbg-icons-info"}></i>

                                        <p className={"card-text"}>
                                            <Trans i18nKey="dashboard:quickLinks.contacts"><br/></Trans>
                                        </p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    </div>

                </div>
            </div>

            <ContentBottom />

        </div>
    );
}