import React from 'react';
import Form from "react-bootstrap/Form";
import {trackPromise} from "react-promise-tracker";
import * as portalApi from "../../http/PortalAPI";
import NewsletterCard from "./NewslettrCard";
import * as userApi from "../../http/UserApi";
import { withTranslation } from 'react-i18next';
import ContentBottom from "../commonComponents/ContentBottom";

const frequencyHTML = [
    'blank pos 0',
    'Nyhedsoverblik én gang om ugen',
    'Nyhedsoverblik to gange om ugen',
    'Nyhedsoverblik tre gange om ugen',
    'blank pos 4',
    'blank pos 5',
    'blank pos 6',
    'blank pos 7',
    'blank pos 8',
    'blank pos 9',
    'blank pos 10',
    'blank pos 11',
    'blank pos 12',
    'blank pos 13',
    'Nyhedsoverblik to gange om dagen',
];

const magazineInfo = {
    'Maskinbladet': {
        'imageSource': '../../../public/assets/images/design/news_maskinbladet.svg',
        'infoText': ''
    },
    'Agro': {
        'imageSource': '../../../public/assets/images/design/news_agro.svg',
        'infoText': ''
    },
    'Bovi': {
        'imageSource': '../../../public/assets/images/design/news_bovi.svg',
        'infoText': ''
    },
    'Hyo': {
        'imageSource': '../../../public/assets/images/design/news_hyo.svg',
        'infoText': ''
    },
    // 'Dagens byggeri': {
    //     'imageSource': '../../../public/assets/images/design/news_dagensbyggeri.svg',
    //     'infoText': ''
    // },
    'Grønteknik': {
        'imageSource': '../../../public/assets/images/design/news_gronteknik.svg',
        'infoText': ''
    },
    'Fritidsmarkedet': {
        'imageSource': '../../../public/assets/images/design/news_fritidsmarkedet.svg',
        'infoText': ''
    },
    'Maskinteknik': {
        'imageSource': '../../../public/assets/images/design/news_maskinteknik.svg',
        'infoText': ''
    },
    // 'BrugtPro Premium': {
    //     'imageSource': '../../../public/assets/images/design/newsletters-bpp.svg',
    //     'infoText': 'Få nye udgaver af magasinet tilsendt gratis'
    // }
}

const calcOtherMagazines = (magazines, selectedMagazines) => {

    let selectedIds = selectedMagazines.map((magazine) => {
        return magazine.id;
    });

    let otherMagazines = [];
    for (const magazine of magazines) {
        if (selectedIds.indexOf(magazine.id) === -1) {
            otherMagazines.push(magazine);
        }
    }
    return otherMagazines;
};

class Newsletter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            magazines: [],
            userNewsletters: [],
            checkedSwitches: [],
            selectedMagazines: [],
            otherMagazines: []
        }
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    componentDidMount() {
        // wrapper to show and hide loading spinner
        trackPromise(
            // fetch all magazines from the server
            portalApi
                .getAllMagazines()
                .then(response => response.json())
                .then((magazines) => {

			magazines = magazines.filter(magazine => magazine.magazineName !== "Dagens byggeri");

                    this.setState({
                        magazines
                    })
                })
        )
        trackPromise(
            // fetch the newsletters selected by the user
            userApi
                .getUserNewsletters()
                .then(response => response.json())
                .then((userNewsletters) => {

                    let checkedSwitches = userNewsletters.map((magazine) => {
                        return magazine.id;
                    });

                    this.setState({
                        userNewsletters,
                        checkedSwitches,
                        selectedMagazines: userNewsletters
                    })
                })
        )
    }

    handleSwitchChange(event) {
        // switchId equals user interest ID
        const switchId = parseInt(event.target.id);
        const isChecked = event.target.checked;
        let checkedSwitches = this.state.checkedSwitches;

        if (isChecked) {
            // add switchId to array of checked switches and send API request
            checkedSwitches.push(switchId);

            userApi.addUserNewsletter(switchId)
                .then(response => response.json())
                .then(() => {

                    this.setState({
                        checkedSwitches
                    })
                })

        } else {
            let index = checkedSwitches.indexOf(switchId);

            if (index !== -1) {
                // remove switchId from array of checked switches and send API request
                checkedSwitches.splice(index, 1);

                userApi.removeUserNewsletter(switchId)
                    .then(response => response.json())
                    .then(() => {

                        this.setState({
                            checkedSwitches
                        })
                    })
            }
        }
    }

    sortMagazines(magazine){
     return magazine.sort(function (a, b) {

            if (a.magazineName < b.magazineName) {
                return -1;
            }
            if (a.magazineName > b.magazineName) {
                return 1;
            }
            return 0;
        })
    }


    render() {

        const { t } = this.props;

        let selectedMagazines = this.state.selectedMagazines;
        let otherMagazines = calcOtherMagazines(this.state.magazines, this.state.selectedMagazines);
        otherMagazines = this.sortMagazines(otherMagazines);
        selectedMagazines = this.sortMagazines(selectedMagazines);

        return (
            <div id="newsletters">
            <Form>
                <div className={"row mt-0 mt-md-5"}>
                    <div className={"col-12 col-lg-10 col-xl-9 mx-auto"}>
                        <div className="px-30">
                            <h1>{t("newsletters:yourNewsletters")}</h1>
                            {
                                selectedMagazines.length === 0 &&
                                <p className={"text-muted m-t-10 m-b-20"}>
                                    {t("newsletters:notSubscribed")}
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-lg-10 col-xl-9 mx-auto"}>

                        <div className="row px-2">

                        {
                            selectedMagazines.map((magazineData, index) => {
                                const isChecked = this.state.checkedSwitches.indexOf(magazineData.id) !== -1;
                                return (
                                    <NewsletterCard
                                        key={index}
                                        magazine={magazineData}
                                        magazineInfo={magazineInfo[magazineData.magazineName]}
                                        newsFrequencyHTML={frequencyHTML[magazineData.newsFrequency]}
                                        handleSwitchChange={this.handleSwitchChange}
                                        checked={isChecked}
                                    />
                                )
                            })
                        }

                        </div>
                    </div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col-12 col-lg-10 col-xl-9 mx-auto"}>
                        <div className="px-30">
                            <h1>{t("newsletters:otherNewsletters")}</h1>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-lg-10 col-xl-9 mx-auto"}>

                        <div className="row px-2">

                        {
                            otherMagazines.map((magazineData, index) => {
                                const isChecked = this.state.checkedSwitches.indexOf(magazineData.id) !== -1;
                                return (
                                    <NewsletterCard
                                        key={index}
                                        magazine={magazineData}
                                        magazineInfo={magazineInfo[magazineData.magazineName]}
                                        newsFrequencyHTML={frequencyHTML[magazineData.newsFrequency]}
                                        handleSwitchChange={this.handleSwitchChange}
                                        checked={isChecked}
                                    />
                                )
                            })
                        }

                        </div>
                    </div>
                </div>

            </Form>

                <ContentBottom />
            </div>
        )
    }
}

export default withTranslation()(Newsletter);
