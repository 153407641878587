import React, {useEffect, useState} from "react";
import {Link, useParams, useHistory} from 'react-router-dom';
import * as ChangeEmailApi from "../../http/ChangeEmailApi";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";

export default () => {

    const { t } = useTranslation();
    let historyHook = useHistory();
    let { token } = useParams();

    useEffect(() => {
        (async () => {
            let changeEmailResponse = await trackPromise(ChangeEmailApi.removeRequest(token));
            if (changeEmailResponse.status !== 200) {
                historyHook.push('/');
            }
        })();
    }, []);

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/ok_green.svg"}
                            alt={"Ok"} />
                        <p className={"msg m-t-20"}>
                            <strong>{t("changeEmail:cancel.canceled")}</strong>
                        </p>
                        <p className="msg-small">
                            <Trans i18nKey="changeEmail:cancel.login"><Link to={"/auth/login"}>log in</Link></Trans>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}