import {Link, useHistory, useParams} from "react-router-dom";
import React, {useState} from "react";
import * as SubscriptionApi from "../../http/SubscriptionApi";
import {useTranslation, Trans} from "react-i18next";

const DetachFromSubscriptionConfirmation = (props) => {

    const { t } = useTranslation();

    let [isDetached, setIsDetachedStatus] = useState(false);
    let history = useHistory();
    let { portalName } = useParams();

    async function detachHandler(e) {
        e.preventDefault();
        let response = await SubscriptionApi.detachSubscription(portalName);
        if (response.status !== 200) {
            history.push("/abonnementer");
        }
        setIsDetachedStatus(true);
    }

    function cancelHandler(e) {
        e.preventDefault();
        history.push("/abonnementer");
    }

    if (isDetached) {
        return (
            <div className="message-container">
                <div className="message-wrapper">
                    <div className="message-area">

                        <div className="text-center">
                            <img
                                src={"../../../public/assets/images/design/ok_green.svg"}
                                alt={"Ok"} />
                            <p className={"msg m-t-20"}>
                                <strong>{ t("subscription:detach.detached") }</strong>
                            </p>
                        </div>
                        <div className="message-buttons text-center">
                            <button className={"btn btn-primary waves-effect btn-wide"}
                                    onClick={cancelHandler}>{ t("subscription:detach.undo") }</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/cancel_red.svg"}
                            alt={"Remove"}/>
                        <p className={"msg m-t-20"}>
                            <strong>{ t("subscription:detach.areYouSure") }</strong></p>
                    </div>

                    <div className="message-buttons text-center">
                        <div>
							<button className={"btn btn-primary waves-effect btn-wide"}
									onClick={detachHandler}>{ t("subscription:detach.yes") }
							</button>
							<p className="mt-3" style={{"color": "red"}}>
								<a href="#" onClick={cancelHandler} style={{"color": "red"}}> { t("subscription:detach.regret") } </a>
							</p>
                        </div>
					</div>
                </div>
            </div>
        </div>
    );
};

export default DetachFromSubscriptionConfirmation;