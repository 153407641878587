import React from "react";
import {Form, Formik} from "formik";
import InputTextField from "../commonComponents/InputTextField";
import {validatePassword} from "./validatePassword";
import FormErrors from "../commonComponents/FormErrors";
import {useTranslation, Trans} from "react-i18next";

const PasswordModalForm = ({ show, closeModal, onSubmit }) => {

    const { t } = useTranslation();

    return (
        <div className={show ? "overlay" : "hide"} >
            <div className={show ? "passwordModal" : "hide"}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5 className="font-20">{t('profile:passwordModal.modalTitle')}</h5>
                            <p>{t("profile:passwordModal.introText")}</p>
                        </div>
                    </div>
                    <div className="row m-t-10">
                        <div className="col text-center">

                            <Formik
                                initialValues={{ newPassword: '', confirmNewPassword: '' }}
                                validate={validatePassword(t)}
                                onSubmit={onSubmit}
                            >
                                {({ errors, touched, resetForm }) => (
                                    <Form className="form-horizontal" noValidate={true}>

                                        <FormErrors
                                            errors={errors}
                                            touched={touched}
                                        />

                                        <div className="row">
                                            <div className="col-12">
                                                <InputTextField
                                                    name={"newPassword"}
                                                    type="password"
                                                    label={t("profile:passwordModal.newPassword")}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <InputTextField
                                                    name={"confirmNewPassword"}
                                                    type="password"
                                                    label={t("profile:passwordModal.confirmNewPassword")}
                                                />
                                            </div>
                                        </div>

                                        <div className="row m-t-20">
                                            <div className="col-12 text-center">
                            <p>
                                <button type="submit" className={"btn btn-wide"}>{t("profile:passwordModal:save")}</button>
                            </p>
                            <p className={"mb-3"}>
                                <a href={"#"} onClick={(e) => closeModal(e, resetForm)} className="text-body">{t('profile:passwordModal:undo')}</a>
                            </p>

                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordModalForm;