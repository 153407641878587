import * as AuthApi from '../http/AuthAPI';

export default class TokenProviderService
{
    constructor(accessTokenName, refreshTokenName, credentialsExpirationName) {
        this.accessTokenName = accessTokenName;
        this.refreshTokenName = refreshTokenName;
        this.credentialsExpirationName = credentialsExpirationName;
    }

    hasCredentials() {
        const localAccessToken = window.localStorage.getItem(this.accessTokenName);
        const localRefreshToken = window.localStorage.getItem(this.refreshTokenName);
        const expirationOfCredentials = window.localStorage.getItem(this.credentialsExpirationName);
        return !(!localAccessToken || !localRefreshToken || !expirationOfCredentials);
    }

    getLocalCredentials() {
        return {
            accessToken: window.localStorage.getItem(this.accessTokenName),
            refreshToken: window.localStorage.getItem(this.refreshTokenName),
            expirationOfCredentials: window.localStorage.getItem(this.credentialsExpirationName)
        }
    }

    setCredentials(accessToken, refreshToken, rememberMe) {
        window.localStorage.setItem(this.accessTokenName, accessToken);
        window.localStorage.setItem(this.refreshTokenName, refreshToken);

        let tokensExpiredAfter = new Date();

        if (rememberMe) {
            tokensExpiredAfter.setDate(tokensExpiredAfter.getDate() + 5);
        } else {
            tokensExpiredAfter.setDate(tokensExpiredAfter.getDate() + 1)
        }

        window.localStorage.setItem(this.credentialsExpirationName, tokensExpiredAfter.getTime().toString());
    }

    removeCredentials() {
        window.localStorage.removeItem(this.accessTokenName);
        window.localStorage.removeItem(this.refreshTokenName);
        window.localStorage.removeItem(this.credentialsExpirationName);
    }

    /**
     * Get locally stored access token or refresh new one
     * @returns {Promise<*>}
     */
    async getAccessToken() {
        const localAccessToken = window.localStorage.getItem(this.accessTokenName);
        const localRefreshToken = window.localStorage.getItem(this.refreshTokenName);
        const expirationOfCredentials = window.localStorage.getItem(this.credentialsExpirationName);

        if (!localAccessToken || !localRefreshToken || !expirationOfCredentials) {
            this.removeCredentials();
            return null;
        }

        const currentTimestamp = (new Date()).getTime();

        if (currentTimestamp >= expirationOfCredentials) {
            this.removeCredentials();
            return null;
        }

        const expirationTokenTimestamp = this.getAccessTokenExpirationDate(localAccessToken) || null;

        if ( currentTimestamp >= expirationTokenTimestamp  ) {
            const response = await AuthApi.refreshAccessToken(localRefreshToken);
            if (response.status !== 200) {
                this.removeCredentials();
                return null;
            }

            let credentials = await response.json();
            window.localStorage.setItem(this.accessTokenName, credentials.token);
            window.localStorage.setItem(this.refreshTokenName, credentials.refresh_token);

            return credentials.token;
        }

        return localAccessToken;
    }

    getAccessTokenExpirationDate(jwtToken) {
        if (!jwtToken) {
            return null;
        }
        const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
        return jwt && jwt.exp && jwt.exp * 1000 || null;
    }
}










