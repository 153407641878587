import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import configI18n from './i18n';
import App from './components/App';

configI18n();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('index')
);