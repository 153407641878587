import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Form, Formik} from 'formik';
import InputTextField from "../commonComponents/InputTextField";
import * as ProfileApi from "../../http/UserProfileApi";
import {trackPromise} from "react-promise-tracker";
import {useHistory} from "react-router-dom";
import validate from "./validate"
import FormErrors from "../commonComponents/FormErrors";
import {useTranslation, Trans} from "react-i18next";
import AsyncAutoSelectFiled from "../commonComponents/AsyncAutoSelectFiled";
import {transformAddressObjectToDawaString, transformDawaObjectToAddressObject} from "../../services/DawaTransformer";
import * as DawaApi from "../../http/DawaAPI";

const EditShippingAddress = (props) => {

    const { t } = useTranslation();
    let history = useHistory();

    let [shippingAddress, setShippingAddress] = useState(null);

    let [addressOptions, setAddressOptions] = useState([]);
    let [isSearchingAddress, setIsSearchingAddress] = useState(false);
    let [selectedAddress, setSelectedAddress] = useState([]);

    useEffect(() => {
        (async() => {
            let response = await trackPromise(ProfileApi.getProfile());

            if (response.status === 200) {
                let currentUser = await response.json();
                setShippingAddress(currentUser.shippingLocation);

                if (currentUser.shippingLocation) {
                    let address = transformAddressObjectToDawaString(currentUser.shippingLocation)
                    let dawaAddressesResponse = await trackPromise(
                        DawaApi.findAddress(address, DawaApi.dawaAddressTypes.directAddress)
                    );

                    if (dawaAddressesResponse.status === 200) {
                        let dawaAddresses = await dawaAddressesResponse.json();

                        if (dawaAddresses.length > 0) {
                            setSelectedAddress([dawaAddresses[0]]);
                        }
                    }
                }
            }
        })();
    }, []);

    async function handleSearchAddress(query) {
        setIsSearchingAddress(true);

        let dawaAddresses = await DawaApi.findAddress(query, DawaApi.dawaAddressTypes.directAddress);

        if (dawaAddresses.status === 200) {
            setAddressOptions(await dawaAddresses.json());
        }

        setIsSearchingAddress(false);
    }

    async function handleAddressSelect(selected) {
        setSelectedAddress(selected);
    }

    async function handleSubmit(values, {setFieldError}) {
        let dawaObject = selectedAddress.length > 0 ? selectedAddress[0] : null;

        if (dawaObject === null || (dawaObject && dawaObject.type !== DawaApi.dawaAddressTypes.directAddress)) {
            setFieldError('address', t('shippingAddress:validate.addressRequired'))
            return;
        }

        let address = transformDawaObjectToAddressObject(dawaObject)

        await trackPromise(ProfileApi.updateShippingLocation(
            values.recipient,
            address.city,
            address.address,
            address.zip
        ));

        history.push('/profiloversigt');
    }

    return (

        <Formik
            initialValues={{
                recipient: shippingAddress ? shippingAddress.recipient : '',
                address: ''
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            validate={validate(t)}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="row mt-8">
                        <div className={"col-sm-10 offset-sm-1"}>
                            <FormErrors errors={errors} touched={touched} />
                            <InputTextField name={"recipient"} type="text" label={t('shippingAddress:edit.recipient')} />

                            <AsyncAutoSelectFiled
                                hasError={errors.address}
                                id={"address"}
                                name={"address"}
                                label={t("profile:edit.streetAddress")}
                                options={addressOptions}
                                labelKey={option => option.tekst}
                                minLength={2}
                                isLoading={isSearchingAddress}
                                onSearch={handleSearchAddress}
                                onChange={handleAddressSelect}
                                selected={selectedAddress}
                                useCache={false}
                                delay={50}
                            />

                        </div>
                    </div>


                    <div className="row mt-5">
                        <div className={"col text-center"}>
                            <p>
                                <button className={"btn btn-primary waves-effect btn-wide"}>
                                    {t('shippingAddress:edit.editAddress')}
                                </button>
                            </p>
                            <p className={"mb-5"}>
                                <Link to={"/"} className={"text-body"}>
                                    {t('shippingAddress:edit.cancel')}
                                </Link>
                            </p>
                        </div>
                    </div>

                </Form>
            )}
        </Formik>
    )
};

export default EditShippingAddress;