import React from "react";
import {useTranslation, Trans} from "react-i18next";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const StopSubscriptionConfirm = (props) => {

    const { t } = useTranslation();

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/cancel_red.svg"}
                            alt={"Ok"} />
                        <p className={"msg m-t-20"} style={{margin:"20px 0 0 0"}}>
                            <strong>{t("subscription:stop.areYouSure")}</strong>
                        </p>
                        <p className={"msg text-blue-primary"}>
                            <strong>{capitalizeFirstLetter(props.packageToStop.portalName)}:&nbsp;
                                {capitalizeFirstLetter(props.packageToStop.packageTitle)}</strong>
                        </p>
                    </div>

                    <div className="message-buttons text-center">
                        <div className="mb-3">
                            <a href="#" className={"btn btn-primary waves-effect btn-wide"} onClick={props.stopSubscriptionRequestHandler}>
								{t("subscription:stop.confirm")}
							</a>
                        </div>
                        <a href="#" style={{"color": "red"}} onClick={props.backLinkHandler}>
							{t("subscription:stop.undo")}

						</a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StopSubscriptionConfirm;