import React from 'react';
import {Link} from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export default (props) => {

    const { t } = useTranslation();

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/cancel_red.svg"}
                            alt={"Cancel"} />
                        <p className={"msg m-t-20"}>
                            <strong>{props.errMsg}</strong>
                        </p>
                    </div>

                    <div className="message-buttons text-center">
                        <Link to={"/"} className={"btn btn-primary waves-effect text-nowrap btn-wide"}>
                            {t("profile:backToKonto")}
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}