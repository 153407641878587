import {createStore, applyMiddleware} from "redux";
import rootReducer from "../reducers/rootReducer";
import thunkMiddleware from "redux-thunk";
import InitialState from './initialState';

const store = createStore(
    rootReducer,
    InitialState,
    applyMiddleware(thunkMiddleware)
);

export default store;