import React from "react";
import {connect} from 'react-redux';
import {Field, Form, Formik} from 'formik';
import InputTextField from "../commonComponents/InputTextField";
import FormErrors from "../commonComponents/FormErrors";
import InputPasswordFieldBlock from './InputPasswordFieldBlock';
import * as authActions from "../../actions/authActions";
import {validate} from './validate';
import {useTranslation, Trans} from "react-i18next";

let LoginForm = (props) => {

    const { t } = useTranslation();

    const handleFormSubmit = (values, {setFieldError, setFieldTouched}) => {
        props.dispatch(authActions.login(
            values.email,
            values.password,
            values.rememberMe,
            (response) => {
                if (response.status !== 200) {
                    setFieldError('email', t('auth:errors.wrongCredentials'));
                    setFieldError('password', ' ');
                }
            }
        ));
    };

    function handleFacebookLoginClick(e) {
        e.preventDefault();
        window.location.href = '/api/v1/auth/facebook/access-token?redirectPath=' + encodeURIComponent('/#/auth/facebook-token-login');
    }

    function handleLinkedInClick(e) {
        e.preventDefault();
        window.location.href = '/api/v1/auth/linkedin/access-token?redirectPath=' + encodeURIComponent('/#/auth/linkedin-token-login');
    }

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                rememberMe: false
            }}
            validate={validate(t)}
            onSubmit={handleFormSubmit}
        >
            {({ errors, touched }) => (

                <Form className="form-horizontal h-100 d-flex flex-column justify-content-between" noValidate={true}>

                    <div className="d-flex flex-column text-center page-top">
                        <p className="text-uppercase my-0">FBG MEDIELOGIN</p>
                    </div>

                    <div className="d-flex flex-column">
                        <div className={"row m-b-10"}>
                            <div className={"col text-center"}>
                                <img className="rounded-circle" src={"../../../public/assets/images/design/grayed_man.svg"}/>
                            </div>
                        </div>
                        <div className={"row m-b-10"}>
                            <div className={"col text-center"}>
                                <h1 className={"font-weight-bold"}>{ t("auth:logIn") }</h1>
                            </div>
                        </div>

                        <FormErrors errors={errors} touched={touched} />

                        <InputTextField
                            name={"email"}
                            type="text"
                            label={ t("auth:labels.email") }
                        />

                        <InputPasswordFieldBlock
                            name={"password"}
                            label={ t("auth:labels.password") }
                        />

                        <div className="form-group text-center row">
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <Field
                                        type={"checkbox"}
                                        id={"rememberMe"}
                                        name={"rememberMe"}
                                        className="custom-control-input"
                                    />
                                    <label className="custom-control-label" htmlFor="rememberMe">{t("auth:labels.rememberMe")}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <div className="row">
                            <div className="col text-center">
                                <button style={{marginBottom:'40px'}} type="submit" className="btn btn-primary waves-effect waves-light btn-wide">{t("auth:logIn")}</button>
                            </div>
                        </div>

                        {/*<div className="row m-t-20">*/}
                        {/*    <div className="col text-center">*/}
                        {/*        <button onClick={handleFacebookLoginClick} className="btn btn-primary btn-darkblue waves-effect waves-light btn-wide">*/}
                        {/*            <i className="mdi mdi-facebook button-icon-left"> </i>*/}
                        {/*            {t("auth:fbLogin")}*/}
                        {/*            <i className="mdi mdi-arrow-right button-icon-right"> </i>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    {/*<div className="row m-t-20">*/}
                    {/*    <div className="col-10 offset-1">*/}
                    {/*        <button onClick={handleLinkedInClick} className="btn btn-primary btn-darkblue waves-effect waves-light">*/}
                    {/*            <i className="mdi mdi-linkedin button-icon-left"></i>*/}
                    {/*            Log ind med LinkedIn*/}
                    {/*            <i className="mdi mdi-arrow-right button-icon-right"></i>*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </Form>
            )}
        </Formik>
    )
};

export default connect()(LoginForm);
