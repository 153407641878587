import Package from "../Package";
import React from "react";

export default (
    iterasPackage,
    subscribers,
    currentUserEmail,
    currentUserIterasId,
    stopSubscriptionHandler,
    changeExtraSlotsHandler,
    addSubscriberHandler,
    updateSubscriberHandler,
    removeSubscriberHandler,
    externalPID,
    t
) => {

    const getCampaignIds = (iterasPackage) => {
        let ids = [];

        if (iterasPackage) {
            ids = iterasPackage.abonnements.map(abonement => abonement.campaignId);
        }

        return ids;
    }

    let changeShippingAddress = false;
    let changeExtraSlots = false;
    let isInheritedPackage = +iterasPackage.packageOwner.iterasId !== +currentUserIterasId;
    let campaignIds = getCampaignIds(iterasPackage);

    return <Package
        package={iterasPackage}
        packageId={iterasPackage.packageId}
        packageName={iterasPackage.packageName}
        portalName={iterasPackage.portalName}
        title={iterasPackage.packageName}
        description={iterasPackage.packageDescription}
        packageType={iterasPackage.packageType}
        extraSlots={iterasPackage.extraSlots}
        magazineNames={iterasPackage.packageMagazineNamesFormatted}
        campaignIds={campaignIds}

        changeShippingAddress={changeShippingAddress}
        changeExtraSlots={changeExtraSlots}
        subscribers={subscribers}

        stopSubscriptionHandler={stopSubscriptionHandler}
        changeExtraSlotsHandler={changeExtraSlotsHandler}
        addSubscriberHandler={addSubscriberHandler}
        updateSubscriberHandler={updateSubscriberHandler}
        removeSubscriberHandler={removeSubscriberHandler}
        isInheritedPackage={isInheritedPackage}
        isPackageCanceled={iterasPackage.isPackageCanceled}
        isPackageSuspended={iterasPackage.isPackageSuspended}
        suspendedCampaigns={iterasPackage.suspendedCampaigns}
        currentUserEmail={currentUserEmail}
        externalPID={externalPID}
        t={t}
    />
};