export default (profile, t) => {

    return (values) => {
        let errors = {};

        if (profile.iterasUserType === 'company' && values.cvrNumber) {
            let cvrPattern = /^(|\d{8})$/;

            if (!cvrPattern.test(values.cvrNumber)) {
                errors.cvrNumber = t('profile:validate.invalidCvrNumber');
            }

        }

        return errors;
    }
}



