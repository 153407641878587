import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import daRoot from "../translations/da/root";

export default () => {
    i18n
        .use(initReactI18next)
        .init({
            resources: {
                da: daRoot
            },
            lng: "da",
            fallbackLng: "da",
            interpolation: { escapeValue: false }
        });
}
