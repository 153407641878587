// https://www.basefactor.com/react-how-to-display-a-loading-indicator-on-fetch-calls
// https://github.com/Lemoncode/react-promise-tracker
import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

const Spinner = props => {

    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress && (
        <div className="loader-container">
            <div className="loader-wrapper">
                <div className="loader-spinner">
                    <Loader type="ThreeDots" color="#1100FF" height="100" width="100" />
                </div>
            </div>
        </div>
        )
    );
};

export default Spinner;