import { AuthHttpClient } from "../services/AuthHttpClient";

export const getProfile = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/user-profile?getean=true', {
        method: 'GET'
    });
};

export const updateProfile = (
    name,
    surname,
    companyName,
    city,
    streetAddress,
    zipCode,
    cvrNumber,
    ean,
    acceptMarketing,
    billing_email,
    phone
) => {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('companyName', companyName);
    formData.append('city', city);
    formData.append('address', streetAddress);
    formData.append('zip', zipCode);
    formData.append('cvrNumber', cvrNumber);
    formData.append('ean', ean);
    formData.append('billing_email', billing_email);
    formData.append('acceptMarketing', (acceptMarketing ? '1' : '0'));
    formData.append('phone', phone);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/update', {
        method: 'POST',
        body: formData
    })
};

export const updateShippingLocation = (recipient, city, address, zip) => {

    let formData = new FormData();
    formData.append('recipient', recipient);
    formData.append('city', city);
    formData.append('address', address);
    formData.append('zip', zip);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/shipping-address/update', {
        method: 'POST',
        body: formData
    })
};

export const bindFacebookAccount = (accessToken, redirectPath) => {
    let formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('redirectPath', redirectPath);
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/bind-facebook', {
        method: 'POST',
        body: formData
    });
};

export const unbindFacebookAccount = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/unbind-facebook', {
        method: 'DELETE'
    })
};

export const bindLinkedInAccount = (accessToken, redirectPath) => {
    let formData = new FormData();
    formData.append('accessToken', accessToken);
    formData.append('redirectPath', redirectPath);
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/bind-linkedin', {
        method: 'POST',
        body: formData
    });
};

export const unbindLinkedInAccount = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/unbind-linkedin', {
        method: 'DELETE'
    });
};

export const uploadAvatar = (avatarImage) => {
    let formData = new FormData();
    formData.append('avatar', avatarImage);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/update-avatar', {
        method: 'POST',
        body: formData
    });
};

export const removeAvatar = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/profile/remove-avatar', {
        method: 'DELETE'
    });
};

