import React, {useEffect, useState} from 'react';
import {trackPromise} from "react-promise-tracker";
import * as SubscriptionAPI from "../../http/SubscriptionApi";
import * as AuthAPI from "../../http/AuthAPI";
import PaymentInfoPanel from "./PaymentInfoPanel";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";
import {getCCPaymentUrl} from "../../http/SubscriptionApi";



let CardChanged = (props) => {

    const { t } = useTranslation();


    useEffect(() => {
        (async() => {
            const ccNotifySupportResponse = await trackPromise(SubscriptionAPI.callCCNotifySupport());
         })();
    }, []);

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">
                    <div className="text-center">
                        <img src="../../../public/assets/images/design/ok_green.svg" alt="Ok"/>
                        <p className="msg m-t-20">
                            Din betalingsmetode er nu ændret.
                        </p>
                        <strong>Bemærk:</strong> Der kan gå op til 2 hverdage inden du kan se din nye betalingsmetode på siden <strong>"Dine betalingsoplysninger"</strong>
                    </div>
                    <div className="message-buttons text-center">
                        <a className="btn btn-primary waves-effect text-nowrap btn-wide" href="#/profiloversigt">Tilbage til profil</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardChanged;