import React from "react";
import {useTranslation, Trans} from "react-i18next";

function Modal(props) {
    const { show, closeModal } = props;
    const { t } = useTranslation();
    return (
        <div className={show ? "overlay" : "hide"} >
            <div className={show ? "acceptNewslettersModal" : "hide"}>
                <div className="row">
                    <div className="col">
                        <h5 className="font-20">{t('profile:view.news.title')}</h5>
                        <p>
                            <Trans i18nKey="profile:view.news.description"><br /><a href='https://fbg.dk'>Read more</a></Trans>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-center">
                        <button onClick={closeModal} className="btn btn-wide">{t('profile:view.news.ok')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;