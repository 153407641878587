import React from "react";
import {useTranslation, Trans} from "react-i18next";

const CustomPrompt = ({title, message, saveBtnLabel, dontSaveBtnLabel, confirmDataStorage}) => {

    const cancel = () => confirmDataStorage(false);
    const ok = () => confirmDataStorage(true);

    return (
        <div className={"overlay"} >
            <div className={"customPrompt"}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h5 className="font-20"> {title} </h5>
                            <p> {message} </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right">
                            <button type="button" onClick={cancel} className="btn" autoFocus={true}>{dontSaveBtnLabel}</button>
                            <button type="button" onClick={ok} className="btn">{saveBtnLabel}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomPrompt;