import React, {useEffect, useState} from 'react';
import {Form, Formik} from "formik";
import {connect} from "react-redux";
import InputTextField from "../commonComponents/InputTextField";
import TextareaField from "./TextareaField";
import {Link} from "react-router-dom";
import FormErrors from "../commonComponents/FormErrors";
import {validate} from "./validate";
import * as AuthAPI from "../../http/AuthAPI";
import * as ContactAPI from "../../http/ContactAPI";
import ContactSuccess from "./ContactSuccess";
import ContactFailed from "./ContactFailed";
import {useTranslation, Trans} from "react-i18next";

const Contact = (props) => {

    const EMAIL_NOT_SENT = 0;
    const EMAIL_SENT_SUCCESSFULLY = 1;
    const SENDING_EMAIL_FAILED = 2;

    const { t } = useTranslation();
    const [userEmail, setUserEmail] = useState('');
    const [sendingState, setSendingState] = useState(EMAIL_NOT_SENT);

    useEffect(() => {
        (
            async () => {
                await AuthAPI.getCurrentUser()
                    .then(response => response.json())
                    .then((currentUser) => {
                        setUserEmail(currentUser.email);
                    })
            }
        )();
    })

    async function handleFormSubmit(values) {
        let response = await ContactAPI.sendContactEmail(
            userEmail,
            values.subject,
            values.emailBody
        );

        if (response.status === 200) {
            setSendingState(EMAIL_SENT_SUCCESSFULLY);
        } else {
            setSendingState(SENDING_EMAIL_FAILED);
        }

    }

    if (sendingState === EMAIL_SENT_SUCCESSFULLY) {
        return <ContactSuccess />
    }

    if (sendingState === SENDING_EMAIL_FAILED) {
        return <ContactFailed />
    }

    const phoneCallHTML = t("contact:phoneCallTo");

    function createMarkup(htmlFragment) {
        return {__html: htmlFragment};
    }

    return (
        <div id={'contact-wrapper'}>
            <div className={"row mt-0 mt-md-5 edit-profile-icon"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <div className="px-30">
                        <h1>Har du spørgsmål?</h1>
                        <p className={"text-muted"}>
                            <Trans i18nKey="contact:contactUs"><Link to={"/faq"}>See FAQ</Link></Trans>
                        </p>
                        <div dangerouslySetInnerHTML={createMarkup(phoneCallHTML)} className={"text-muted m-b-20"} />
                    </div>
                </div>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                    subject: '',
                    emailBody: ''
                }}
                validate={validate(t)}
                onSubmit={handleFormSubmit}
            >
                {
                    ({errors, touched}) => (
                        <Form noValidate={true}>

                            <div className={"row"}>
                                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                                    <FormErrors errors={errors} touched={touched} />

                                    <div className="form-group row mx-0 d-none">
                                        <div className="input-group ">
                                            <label className="col-auto col-form-label">{t("contact:labels.yourEmail")}</label>
                                            <div className="col px-0">
                                                <span className="form-control" >{userEmail}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <InputTextField
                                        name={"subject"}
                                        type="text"
                                        label={t("contact:labels.subject")}
                                    />

                                    <TextareaField
                                        name={"emailBody"}
                                        label={t("contact:labels.body")}
                                        rows="10"
                                    />

                                </div>
                            </div>

                            <div className={"row contact-button"}>
                                <div className={"col text-center"}>
                                    <button type="submit" className={"btn btn-primary waves-effect btn-wide"}>
                                        {t("contact:sendEmail")}
                                    </button>
                                </div>
                            </div>

                        </Form>
                    )
                }
            </Formik>

        </div>
    )
};

export default connect()(Contact);