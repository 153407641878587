import React, { useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import 'babel-polyfill';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from '../store/store';
import DashboardLayout from './layout/DashboardLayout';
import LoginLayout from './layout/LoginLayout';
import { UserIsLoggedIn, UserIsNotLoggedIn } from '../access/userAccessRules';
import * as authActions from '../actions/authActions';
import ChangeEmailConfirmation from '../components/changeEmail/ChangeEmailConfirmation';
import CancelChangeEmail from "./changeEmail/CancelChangeEmail";
import SelfDeleted from "./profile/SelfDeleted";
import RestoreDeletedProfile from "./profile/RestoreDeletedProfile";
import RemoveDeletedProfile from "./profile/RemoveDeletedProfile";
import RejectActivation from "./profile/RejectActivation";
import RejectInvitation from "./subscription/RejectInvitation";
import {track } from "../services/GaService";


{APP_MODE === 'dev' ? console.log("We are developing") : track()}

const App = (props) => {

    useEffect(() => {
        props.dispatch(authActions.autoLoginFromToken());
        props.dispatch(authActions.getCurrentUser());
        props.dispatch(authActions.checkForUnPaidInvoices());
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route path="/cancel_change_email/:token" component={CancelChangeEmail} />
                    <Route path="/ret_email/:token" component={ChangeEmailConfirmation} />
                    <Route path="/restore_profile/:token" component={RestoreDeletedProfile} />
                    <Route path="/remove_deleted/:token" component={RemoveDeletedProfile} />
                    <Route path="/user_activation/reject/:token" component={RejectActivation}/>
                    <Route path="/subscription/reject/:token" component={RejectInvitation}/>
                    <Route path="/deleted" component={SelfDeleted}/>
                    <Route path="/auth" component={LoginLayout}/>
                    <Route path="/" component={UserIsLoggedIn(DashboardLayout)}/>
                </Switch>
            </Router>
        </Provider>
    );
};

export default connect()(App);
