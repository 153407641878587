import * as constants from '../actions/actionConstants';

let InitialState = {
    hashCreated: null,
    hashError: null,
    hashErrorMessage: null
};

export default (state = InitialState, action) => {
    switch (action.type) {
        case constants.PASSWORD_RESET_HASH_CREATED:
            return {
                hashCreated: true
            }
        case constants.PASSWORD_RESET_HASH_FAILURE:
            return {
                hashCreated: false,
                hashError: true,
                hashErrorMessage: action.errorMsg
            }
        case constants.PASSWORD_RESET_CLEAR:
            return InitialState;
        default:
            return state;
    }
}