import React from "react";
import {Link} from "react-router-dom";


export default (props) => {

    let iconClass = "fa fa-home";
    let mobileIconClass = "";
    let title = 'KONTOADMINISTRATION';
    let pathTo = '';

    if (location.hash === '#/profiloversigt') {
        iconClass = "mdi mdi-account";
        mobileIconClass = "fa fa-home";
        title = "PROFIL";
    } else if (
        location.hash === '#/ret_profil' ||
        location.hash === '#/slet_profil' ||
        location.hash === '#/upload-avatar' ||
        location.hash === '#/unbind-failed'
    ) {
        iconClass = "mdi mdi-lead-pencil";
        mobileIconClass = "ti-arrow-left text-muted";
        title = "RET PROFIL";
        pathTo = "/profiloversigt";
    } else if (location.hash === '#/ret_email') {
        iconClass = "mdi mdi-lead-pencil";
        mobileIconClass = "ti-arrow-left text-muted";
        title = "RET E-MAILADRESSE";
        pathTo = "/profiloversigt";
    } else if (location.hash === '#/leveringsadresse') {
        iconClass = "mdi mdi-lead-pencil";
        mobileIconClass = "ti-arrow-left text-muted";
        title = "RET LEVERINGSADRESSE";
        pathTo = "/profiloversigt";
    } else if (location.hash === '#/skift_kodeord') {
        iconClass = "mdi mdi-lead-pencil";
        mobileIconClass = "ti-arrow-left text-muted";
        title = "RET ADGANGSKODE";
        pathTo = "/profiloversigt";
    } else if (location.hash === '#/interesser') {
        iconClass = "mdi mdi-account";
        mobileIconClass = "fa fa-home";
        title = "INTERESSEOMRÅDER";
    } else if (location.hash === '#/abonnementer') {
        iconClass = "fa fa-shopping-bag";
        mobileIconClass = "fa fa-home";
        title = "ABONNEMENTER";
    } else if (location.hash === '#/betaling') {
        iconClass = "fa fa-shopping-bag";
        mobileIconClass = "fa fa-home";
        title = "BETALING";
    } else if (location.hash === '#/fakturaer') {
        iconClass = "fa fa-shopping-bag";
        mobileIconClass = "fa fa-home";
        title="FAKTURAER";
    } else if (location.hash === '#/nyhedsbreve') {
        iconClass = "mdi mdi-email";
        mobileIconClass = "fa fa-home";
        title = "Nyhedsbreve";
    } else if (location.hash === '#/faq') {
        iconClass = "fa fa-question-circle";
        mobileIconClass = "fa fa-home";
        title = "Ofte stillede spørgsmål";
    } else if (location.hash === '#/kontakt') {
        iconClass = "fa fa-info";
        mobileIconClass = "fa fa-home";
        title = "Kontakt";
    } else if (location.hash === '#/cookies') {
        iconClass = "fa fa-info";
        mobileIconClass = "fa fa-home";
        title = "Cookie- og privativpolitik";
    } else if (location.hash === '#/brugervilkar') {
        iconClass = "fa fa-info";
        mobileIconClass = "fa fa-home";
        title = "Brugervilkår";
    }

    return (

        <nav className="navbar-custom">

            <div className="nav-header text-uppercase">
                <div className="nav-icon">
                {
                    mobileIconClass.length > 0 &&
                    <Link to={pathTo}><i className={mobileIconClass} /></Link>
                }
                </div>
                {/*<i className={iconClass} /> {title}*/}
                <div className="nav-burger" onClick={props.handleClick}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="half-line"></div>
                </div>
            </div>
            <div className="clearfix"></div>

        </nav>

    );
}