import React, {useEffect, useState} from 'react';
import * as SubscriptionAPI from '../../http/SubscriptionApi';
import {trackPromise} from "react-promise-tracker";
import SubscriptionPanel from "./SubscriptionPanel";
import BuySubscriptionPackage from "./BuySubscriptionPackage";
import MaskinteknikFactory from "./factory/MaskinteknikFactory";
import MaskinbladetFactory from "./factory/MaskinbladetFactory";
import * as AuthAPI from "../../http/AuthAPI";
import StopSubscriptionConfirm from "./StopSubscriptionConfirm";
import StopSubscriptionSuccess from "./StopSubscriptionSuccess";
import StopSubscriptionFailed from "./StopSubscriptionFailed";
import {useTranslation, Trans} from "react-i18next";
import ContentBottom from "../commonComponents/ContentBottom";

const portalInfo = {
    maskinbladet: {
        portalName: 'maskinbladet',
        externalPID: 'MB.DK',
        imageSource:'../../../public/assets/images/design/maskinbladet.svg',
        portalUrl: MASKINBLADET_BUY_SUBSCRIPTION_URL,
        userActivationActivateUrl: MASKINBLADET_ACTIVATION_ACTIVATE_URL,
        createPackage: MaskinbladetFactory
    },
    /*
 maskinteknik: {
     portalName: 'maskinteknik',
     externalPID: 'MT.DK',
     portalUrl: 'http://www.maskinteknik.dk/',
     imageSource:'../../../public/assets/images/design/maskinteknik.svg',
     createPackage: MaskinteknikFactory
 },

 fritidsmarkedet: {
     portalName: 'fritidsmarkedet',
     externalPID: 'FM.DK',
     portalUrl: 'https://www.fritidsmarkedet.dk/',
     imageSource:'../../../public/assets/images/design/fritidsmarkedet.svg',
     createPackage: function (iterasPackage) {
         return <BuySubscriptionPackage portalUrl={this.portalUrl}/>;
     }
 },

 dagensbyggeri: {
     portalName: 'dagensbyggeri',
     externalPID: 'DB.DK',
     portalUrl: 'http://www.dagensbyggeri.dk/',
     imageSource:'../../../public/assets/images/design/dagensbyggeri.svg',
     createPackage: function(iterasPackage) {
         return <BuySubscriptionPackage portalUrl={this.portalUrl}/>;
     }
 }


 maskinteknik: {
     portalName: 'maskinteknik',
     externalPID: 'MT.DK',
     portalUrl: 'http://www.maskinteknik.dk/',
     imageSource:'../../../public/assets/images/design/maskinteknik.svg',
     createPackage: MaskinteknikFactory
 }
*/



};

let Subscription = (props) => {

    const STOP_SUBSCRIPTION_NONE = 0;
    const STOP_SUBSCRIPTION_CONFIRM = 1;
    const STOP_SUBSCRIPTION_SUCCESS = 2;
    const STOP_SUBSCRIPTION_FAILED = 3;

    const { t } = useTranslation();

    let [packages, setPackages] = useState([]);
    let [subscribers, setSubscribers] = useState({});
    let [usedPortals, setUsedPortals] = useState([]);
    let [currentUserEmail, setCurrentUserEmail] = useState('');
    let [currentUserIterasId, setCurrentUserIterasId] = useState(0);

    let [stopState, setStopState] = useState(STOP_SUBSCRIPTION_NONE);
    let [packageToStop, setPackageToStop] = useState({});
    let [endDates, setEndDates] = useState({});


    let loadPackages = async() => {
        const packagesResponse = await trackPromise(SubscriptionAPI.getUserPackages());
        const packagesJson = await trackPromise(packagesResponse.json());
        setPackages(packagesJson);

        let tmpSubscribers = {};
        let tmpUsedPortals = [];

        for (let iterasPackage of packagesJson) {
            if (!tmpUsedPortals.includes(iterasPackage.portalName)) {
                tmpUsedPortals.push(iterasPackage.portalName);
                let subscribers = await trackPromise(SubscriptionAPI.getSubscribers(iterasPackage.portalName));
                tmpSubscribers[iterasPackage.portalName] =  await trackPromise(subscribers.json());
            }
        }

        setUsedPortals(tmpUsedPortals);
        setSubscribers(tmpSubscribers);

        const currentUserResponse = await trackPromise(AuthAPI.getCurrentUser());
        const currentUserJson = await trackPromise(currentUserResponse.json());
        setCurrentUserEmail(currentUserJson.email);
        setCurrentUserIterasId(currentUserJson.iterasId)

    };


    useEffect(() => {
        loadPackages();
    }, []);

    async function addSubscriberHandler(portalName, email) {
        let activationUrl = null;

        for (let portal in portalInfo) {
            if (portalName === portalInfo[portal].portalName) {
                activationUrl = portalInfo[portal].userActivationActivateUrl;
            }
        }

        let response = await SubscriptionAPI.addSubscriber(portalName, email, activationUrl);
        if (response.status === 200) {
            await refreshSubscribers(portalName);
        }
        return response;
    }

    async function updateSubscriberHandler(portalName, newEmail, oldEmail) {
        // alert(`update user email from ${oldEmail} to ${newEmail}`);
        let activationUrl = null;

        for (let portal in portalInfo) {
            if (portalName === portalInfo[portal].portalName) {
                activationUrl = portalInfo[portal].userActivationActivateUrl;
            }
        }

        let response = await SubscriptionAPI.updateSubscriber(portalName, newEmail, oldEmail, activationUrl);
        if (response.status === 200) {
            await refreshSubscribers(portalName);
        }
        return response;
    }

    async function removeSubscriberHandler(portalName, email) {
        // alert(`remove user: ${email} from ${packageId}`);
        let response = await SubscriptionAPI.removeSubscriber(portalName, email);
        if (response.status === 200) {
            await refreshSubscribers(portalName);
        }
        return response;
    }

    async function refreshSubscribers(portalName) {

        let tmpSubscribers = [...subscribers];
        let newPackageSubscribers;

        await Promise.all(usedPortals.map(async (portalName) => {
            newPackageSubscribers = await SubscriptionAPI.getSubscribers(portalName);
            tmpSubscribers[portalName] =  await newPackageSubscribers.json();
        }))

        setSubscribers(tmpSubscribers);
    }


    function stopSubscriptionHandler(e, packageId, packageTitle, portalName, externalPID) {
        e.preventDefault();
        // console.log(packageId, packageTitle, portalName, externalPID);
        let packageToStop = {
            packageId,
            packageTitle,
            portalName,
            externalPID
        }
        setPackageToStop(packageToStop);
        setStopState(STOP_SUBSCRIPTION_CONFIRM);
    }

    async function stopSubscriptionRequestHandler(e) {
        e.preventDefault();
        // send request
        let response = await trackPromise(SubscriptionAPI.cancelSubscription(packageToStop));
        if (response.status === 200) {
        let responseBody = await response.json();
            // console.log(responseBody);
            setEndDates(responseBody);
            setStopState(STOP_SUBSCRIPTION_SUCCESS);
        } else {
            setStopState(STOP_SUBSCRIPTION_FAILED);
        }

    }

    function backLinkHandler(e) {
        e.preventDefault();
        setStopState(STOP_SUBSCRIPTION_NONE);
        setPackageToStop({});
        setEndDates({});
    }

    function backLinkSuccessHandler(e) {
        e.preventDefault();
        setStopState(STOP_SUBSCRIPTION_NONE);
        setPackageToStop({});
        setEndDates({});
        loadPackages();
    }

    function changeExtraSlotsHandler(e) {
        e.preventDefault();
        alert('changeExtraSlotsHandler');
    }

    function showSubscriptionsCanBeBought() {
        let portalNames = Object.keys(portalInfo);
        for (let portalName of portalNames) {
            if (!usedPortals.includes(portalName)) {
                return true;
            }
        }
        return false;
    }

    // console.log(packages);

    if (stopState === STOP_SUBSCRIPTION_CONFIRM) {
        return (
            <StopSubscriptionConfirm
                packageToStop={packageToStop}
                backLinkHandler={backLinkHandler}
                stopSubscriptionRequestHandler={stopSubscriptionRequestHandler}
            />
        )
    }

    if (stopState === STOP_SUBSCRIPTION_SUCCESS) {
        return (
            <StopSubscriptionSuccess
                endDates={endDates}
                backLinkSuccessHandler={backLinkSuccessHandler}
            />
        )
    }

    if (stopState === STOP_SUBSCRIPTION_FAILED) {
        return (
            <StopSubscriptionFailed
                backLinkHandler={backLinkHandler}
            />
        )
    }

    return (
        <div id={"subscriptions"} className={"flexbox-align-center"}>
            <div className={"row mt-0 mt-md-5"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <div className="px-30">
                        <h1>{t("subscription:yourPackages")}</h1>
                        {
                            packages.length === 0 &&
                            <p className={"text-muted m-t-10 m-b-20"}>
                                {t("subscription:noActiveSubscriptions")}
                            </p>
                        }
                    </div>
                </div>
            </div>

            {
                usedPortals.map((portalName) => {
                    const portal = portalInfo[portalName];
                    const portalPackages = [];
                    for (const iterasPackage of packages) {
                        if (iterasPackage.portalName === portalName) {
                            portalPackages.push(
                                portal.createPackage(
                                    iterasPackage,
                                    subscribers[iterasPackage.portalName],
                                    currentUserEmail,
                                    currentUserIterasId,
                                    stopSubscriptionHandler,
                                    changeExtraSlotsHandler,
                                    addSubscriberHandler,
                                    updateSubscriberHandler,
                                    removeSubscriberHandler,
                                    portal.externalPID,
                                    t
                                )
                            )
                        }
                    }

                    return (
                        <div key={portalName} className={"row m-t-10"}>
                            <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                                <SubscriptionPanel
                                    imageSource={portal.imageSource}
                                    styleActive={true}
                                    userSubscriptions={portalPackages}
                                />
                            </div>
                        </div>
                    );
                })
            }

            <div className={"row mt-5"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                    <div className="px-30">
                        <h1>{t("subscription:youCanBuy")}</h1>
                    </div>
                </div>
            </div>

            <div id="buy-package">
            {
                Object.keys(portalInfo).map((portalName) => {
                    const portal = portalInfo[portalName];
                    return (
                        <div key={portalName} className={"row m-t-10"}>
                            <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                                <BuySubscriptionPackage
                                    imageSource={portal.imageSource}
                                    portalUrl={portal.portalUrl}
                                />
                            </div>
                        </div>
                    );
                })
            }
            </div>

            <ContentBottom />

        </div>
    );
};

export default Subscription;