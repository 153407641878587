import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const InterestCard = (props) => {

    return (
        <div className="col-6 col-md-4 px-0">
            <Card body className={"white-card card-switch"}>
                <span>{props.label}</span>

                <Form.Check
                    inline
                    type="switch"
                    id={props.id}
                    label=""
                    onChange={props.handleChange}
                    checked={props.checked}
                />

            </Card>
        </div>
    )
}

export default InterestCard;
