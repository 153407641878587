import ReactGA from 'react-ga';
import { createHashHistory } from 'history';

export function track() {
     ReactGA.initialize(GOOGLE_ANALYTICS_CODE);
     ReactGA.set({ page: "medielogin.fbg.dk" });
     ReactGA.pageview("medielogin.fbg.dk");

    const history = createHashHistory();
    history.listen((location) => {
        ReactGA.pageview(location.pathname);
    });
}
