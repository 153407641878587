import { AuthHttpClient } from "../services/AuthHttpClient";
import {HttpClient} from "../services/HttpClient";

export const changeEmailRequest = (email) => {
    const formData = new FormData();

    formData.append('newEmail', email);
    formData.append('resetUrl', RESET_EMAIL_LINK_URL);
    formData.append('cancelResetUrl', CANCEL_RESET_EMAIL_LINK_URL);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/change-email/request ', {
        method: 'POST',
        body: formData
    });
};

export const verifyChangeEmailToken = (token) => {
    const formData = new FormData();
    formData.append('token', token);
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/change-email/validate-token', {
        method: 'POST',
        body: formData
    });
};

export const changeEmail = (token, password) => {
    const formData = new FormData();
    formData.append('token', token);

    if (password) {
        formData.append('password', password);
    }

    return HttpClient(MEMBERS_API_URL + '/api/v1/public/change-email/set', {
        method: 'POST',
        body: formData
    });
};

export const removeRequest = (token) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/change-email/remove/' + token, {
        method: 'DELETE'
    });
};