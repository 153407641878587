import React, {useEffect, useState} from 'react';

let ifloading = (props) => {
    return (
        <div id="payment-info">
            <center>
                Forbereder betalingsvindue
            </center>
        </div>
    )
}

export default ifloading;