import * as constants from '../actions/actionConstants';
import initialState from '../store/initialState';

export default (state = initialState.auth, action) => {
    switch (action.type) {

        case constants.CURRENT_USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn
            };

        case constants.CURRENT_USER_LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };

        case constants.GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.currentUser
            };

        case constants.CURRENT_USER_LOGIN_ATTEMPT_FAILED:
            return {
                ...state,
                loginAttemptFailed: true
            };

        case constants.CURRENT_USER_LOGIN_ATTEMPT_SUCCESS:
            return {
                ...state,
                loginAttemptFailed: false
            };

        case constants.LOGIN_AS_USER_SUCCESS:
            return {
                ...state,
                loginAsUser: action.loginAsUser
            };

        case constants.LOGOUT_AS_USER_SUCCESS:
            return {
                ...state,
                loginAsUser: null
            };

        default:
            return state;
    }
}