export function transformDawaObjectToAddressObject(selected) {
    if (!selected ||
        !selected.data.adresseringsvejnavn ||
        !selected.data.postnr ||
        !selected.data.postnrnavn ||
        !selected.data.husnr
    ) {
        return { address: '', city: '', zip: '' };
    }

    let address = selected.data.adresseringsvejnavn + ' ' + selected.data.husnr;
    let zip = selected.data.postnr;
    let city = selected.data.postnrnavn;

    if (selected.data.etage) {
        address += ', ' + selected.data.etage;

        if (selected.data.dør) {
            address += '.' + selected.data.dør;
        }
    }

    if (selected.data.supplerendebynavn) {
        address += ', ' + selected.data.supplerendebynavn;
    }

    return { address, city, zip }
}

export function transformAddressObjectToDawaString(addressObject) {
    if (!addressObject.address || !addressObject.zip || !addressObject.city) {
        return '';
    }

    return (`${addressObject.address}, ${addressObject.zip} ${addressObject.city}`).trim();
}