import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import {useDispatch} from "react-redux";
import ChangePasswordForm from "./ChangePasswordForm";
import * as ResetPasswordApi from "../../../http/ResetPasswordApi";
import ResetPasswordFailed from "./ResetPassfordFailed";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import {trackPromise} from "react-promise-tracker";
import * as AuthActions from "../../../actions/authActions";
import {useTranslation, Trans} from "react-i18next";

const ResetPasswordPage = () => {

    const PASSWORD_NOT_RESETED = 0;
    const PASSWORD_RESETED_SUCCESSFULLY = 1;
    const PASSWORD_RESET_FAILED = 2;

    let [resetPasswordState, setResetPasswordState] = useState(PASSWORD_NOT_RESETED);
    let [errorMsg, setErrorMsg] = useState('');
    let [successMsg, setSuccessMsg] = useState('');

    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { token } = useParams();

    useEffect(() => {
        (async ()=>{
            let response = await trackPromise(ResetPasswordApi.validateResetToken(token));
            if (response.status !== 200) {
                setResetPasswordState(PASSWORD_RESET_FAILED);
                setErrorMsg( t("forgotPassword:errors.invalidToken") );
            }
        })();
    }, []);

    if (resetPasswordState === PASSWORD_RESETED_SUCCESSFULLY) {
        dispatch(AuthActions.getCurrentUser());
        return <ResetPasswordSuccess successMsg={successMsg}/>
    }

    if (resetPasswordState === PASSWORD_RESET_FAILED) {
        return <ResetPasswordFailed errorMsg={errorMsg}/>
    }

    async function handleSubmit(values) {
        let response = await ResetPasswordApi.resetPassword(token, values.newPassword, values.portalName);

        if (response.status === 200) {
            setSuccessMsg( t("forgotPassword:resetPassword.success"));
            setResetPasswordState(PASSWORD_RESETED_SUCCESSFULLY);
        } else {
            setErrorMsg( t("forgotPassword:errors.failed"));
            setResetPasswordState(PASSWORD_RESET_FAILED);
        }
    }

    return (

        <div className="d-flex flex-column h-100">

            <ChangePasswordForm onSubmit={handleSubmit}/>

        </div>
    );
};

export default ResetPasswordPage;