import React from "react";

const FormErrors = (props) => {

    const errorNodes = [];
    const {errors, touched} = props;

    for( let key in errors) {
        if (touched.hasOwnProperty(key)) {
            errorNodes.push(<div className='error font-16' key={key}>{errors[key]}</div>)
        }
    }

    if (errorNodes.length > 0) {
        return (
            <div className="row m-b-20">
                <div className="col-12">
                    {errorNodes}
                </div>
            </div>
        )
    }
    return errorNodes;
};

export default FormErrors;