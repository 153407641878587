import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

let topLogo = "/public/assets/images/design/medielogin.svg";
let portalName = "medielogin";
import queryString from 'query-string';
const parsed = queryString.parse(location.search);
let utmCampaign = parsed.utm_campaign;

if(typeof utmCampaign !== "undefined"){
    utmCampaign = utmCampaign.toLowerCase();
    if(utmCampaign.indexOf("maskinteknik")>=0) topLogo = "/public/assets/images/design/maskinteknik.svg";
    if(utmCampaign.indexOf("maskinbladet")>=0) topLogo = "/public/assets/images/design/maskinbladet.svg";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) topLogo = "/public/assets/images/design/fritidsmarkedet.svg";
    if(utmCampaign.indexOf("gronteknik")>=0) topLogo = "/public/assets/images/design/gronteknik.svg";

    if(utmCampaign.indexOf("maskinteknik")>=0) portalName = "maskinteknik";
    if(utmCampaign.indexOf("maskinbladet")>=0) portalName = "maskinbladet";
    if(utmCampaign.indexOf("fritidsmarkedet")>=0) portalName = "fritidsmarkedet";
    if(utmCampaign.indexOf("gronteknik")>=0) portalName = "gronteknik";
}

export default (props) => {

    const { t } = useTranslation();

    return (
        <div className="h-100 d-flex flex-column justify-content-between">

            <div className="d-flex flex-column text-center page-top">
                <p className="text-uppercase my-0"><img src={topLogo} style={{'width':'50%'}} /></p>
            </div>

            <div className="d-flex flex-column">
                <div className="text-center">
                    <img
                        src={"../../../public/assets/images/design/cancel_red.svg"}
                        alt={"Cancel"} />
                    <p className={"msg m-t-20"}>
                        <strong>{props.hashErrorMessage}</strong>
                    </p>
                    <p className="msg-small">
                        <a href="#" onClick={props.clearPasswordReset}>{ t("forgotPassword:resetLink.tryAgain") }</a>
                    </p>
                </div>
            </div>

            <div className="d-flex flex-column">
                <div className="text-center">
                    <Link to={"/"} className={"btn btn-primary waves-effect text-nowrap btn-wide"}>
                        {t("forgotPassword:backToKonto")}
                    </Link>
                </div>
            </div>

        </div>
    )
}