import React, {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Form, Formik} from "formik";
import FormErrors from "../commonComponents/FormErrors";
import InputTextField from "../commonComponents/InputTextField";
import InvalidChangeEmailLink from "./InvalidChangeEmailLink";
import * as ChangeEmailApi from "../../http/ChangeEmailApi";
import * as tokenProviderService from "../../services/TokenProviderService";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";

export default (props) => {

    const EMAIL_NOT_CHANGED = 1;
    const EMAIL_WAS_CHANGED = 2;
    const INVALID_TOKEN = 3;

    const INVALID_PASSWORD_ERR = 5;
    const INVALID_TOKEN_ERR = 4;
    const USER_ALREADY_EXISTS = 2;

    const { t } = useTranslation();
    let historyHook = useHistory();
    let { token } = useParams();
    let [changeEmailStatus, setChangeEmailStatus] = useState(EMAIL_NOT_CHANGED);

    useEffect(() => {
        (async () => {
            let changeEmailRequest = await trackPromise(ChangeEmailApi.verifyChangeEmailToken(token));

            if (changeEmailRequest.status !== 200) {
                setChangeEmailStatus(INVALID_TOKEN);
            } else {
                let changeEmailRequestBody = await trackPromise(changeEmailRequest.json());
                if (changeEmailRequestBody.isSocialAccount) {
                    let changeEmailResponse = await trackPromise(ChangeEmailApi.changeEmail(token));
                    if (changeEmailResponse.status === 200) {
                        // tmp hack
                        let changeEmailResponseBody = await changeEmailResponse.json();
                        await tokenProviderService.setCredentials(
                            changeEmailResponseBody.token,
                            changeEmailResponseBody.refresh_token,
                            false
                        );
                        historyHook.push('/ret_email_success');
                    } else {
                        historyHook.push('/');
                    }
                }
            }

        })();
    }, []);

    async function handleFormSubmit(values, { setFieldError }) {
        let response = await trackPromise(ChangeEmailApi.changeEmail(token, values.password));

        if (response.status === 200) {
            setChangeEmailStatus(EMAIL_WAS_CHANGED);
            let responseBody = await response.json();
            // tmp hack
            await tokenProviderService.setCredentials(
                responseBody.token,
                responseBody.refresh_token,
                false
            );
            historyHook.push('/ret_email_success');
            return;
        }

        if (response.status === 400) {
            let responseBody = await response.json();

            switch (responseBody.code) {
                case INVALID_PASSWORD_ERR:
                    setFieldError('password', t('changeEmail:errors.invalidPassword'));
                    return;
                case INVALID_TOKEN_ERR:
                    setFieldError('password', t('changeEmail:errors.invalidToken'));
                    return;
                case USER_ALREADY_EXISTS:
                    setFieldError('password', t('changeEmail:errors.userExists'));
                    return;
            }
        }

        setFieldError('password', t('changeEmail:errors.unknown'));
    }

    if (changeEmailStatus === INVALID_TOKEN) {
        return <InvalidChangeEmailLink/>
    }

    return (
        <div className="wrapper-page">
            <div className="container">

                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h1>{t('changeEmail:confirm.changeEmail')}</h1>
                        <p>{t('changeEmail:confirm.enterPassword')}</p>
                    </div>
                </div>
                <Formik
                    initialValues={{ password: '' }}
                    onSubmit={handleFormSubmit}
                >
                    {({ errors, touched }) => (
                        <Form className="form-horizontal" noValidate={true}>
                            <FormErrors errors={errors} touched={touched} />
                            <div className="row">
                                <div className="col-12">
                                    <InputTextField name={"password"} type="password" label={t('changeEmail:confirm.passwordLabel')} />
                                </div>
                            </div>

                            <div className="row m-t-40">
                                <div className="col text-center">
                                    <p>
                                        <button type="submit" className="btn btn-primary btn-wide">{t('changeEmail:confirm.editEmail')}</button>
                                    </p>
                                    <p>
                                        <Link to={"/auth/login"} className={"text-body"}>{t('changeEmail:confirm.undo')}</Link>
                                    </p>
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );

}