import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Form, Formik} from 'formik';
import InputTextField from "../commonComponents/InputTextField";
import * as UserApi from "../../http/UserApi";
import * as AuthAPI from "../../http/AuthAPI";
import {validate} from "./validate";
import FormErrors from "../commonComponents/FormErrors";
import { trackPromise } from 'react-promise-tracker'
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import ResetPassword from "./ResetPassword";
import {useTranslation, Trans} from "react-i18next";

const ChangePassword = (props) => {

    const PASSWORD_WAS_CHANGED_SUCCESSFULLY = 1;
    const PASSWORD_WAS_NOT_CHANGED = 2;

    const INVALID_OLD_PASSWORD_ERR = 3;

    const { t } = useTranslation();
    let [changePasswordState, setChangePasswordState] = useState(PASSWORD_WAS_NOT_CHANGED);
    let [currentUser, setCurrentUser] = useState({
        socialAccount: false
    });

    useEffect(() => {
        (async () => {
            let response = await trackPromise(AuthAPI.getCurrentUser());
            if (response.status === 200) {
                setCurrentUser(await response.json());
            }
        })();
    }, []);

    async function handleFormSubmit(values, {setFieldError, setFieldTouched}) {

        let response = await trackPromise(UserApi.changeUserPassword(
            (!currentUser.socialAccount ? values.currentPassword : null),
            values.newPassword,
            values.passwordRepeat
        ));

        if (response.status === 200) {
            setChangePasswordState(PASSWORD_WAS_CHANGED_SUCCESSFULLY);
            return;
        }

        if(response.status === 400) {
            let responseBody = await response.json();
            if(responseBody.code === INVALID_OLD_PASSWORD_ERR) {
                setFieldError('currentPassword', t("changePassword:errors.invalidOldPassword"));
                return;
            }
        }

        setFieldError('newPassword', t("changePassword:errors.unknown"));
        setFieldError('currentPassword', ' ');
        setFieldError('passwordRepeat', ' ');
    }

    if (changePasswordState === PASSWORD_WAS_CHANGED_SUCCESSFULLY) {
        return <ChangePasswordSuccess />;
    }

    if (!currentUser.isActivated) {
        return <ResetPassword />;
    }

    return (
        <Formik
            initialValues={{
                currentPassword: '',
                newPassword: '',
                passwordRepeat: ''
            }}
            validate={validate(currentUser, t)}
            onSubmit={handleFormSubmit}
        >
            {({ errors, touched }) => (
                <Form noValidate={true}>

                    <div className={"row change-password-inputs"}>
                        <div className={"col-sm-8 offset-sm-2"}>
                            <FormErrors errors={errors} touched={touched} />
                            {
                                !currentUser.socialAccount &&
                                <InputTextField
                                    name={"currentPassword"}
                                    type="password"
                                    label={t("changePassword:currentPassword")}
                                />
                            }
                            <InputTextField
                                name={"newPassword"}
                                type="password"
                                label={t("changePassword:newPassword")}
                            />
                            <InputTextField
                                name={"passwordRepeat"}
                                type="password"
                                label={t("changePassword:passwordRepeat")}
                            />
                        </div>
                    </div>

                    <div className="button-container">
                        <div className="buttons-wrapper">
                            <div className="buttons-area">
                                <div className="bottom-buttons">
                        <p>
                            <button className={"btn btn-primary waves-effect btn-wide"}>{t("changePassword:save")}</button>
                        </p>
                        <p className="text-center my-0">
                            <Link to={"/profiloversigt"} className={"text-body"}>{t("changePassword:undo")}</Link>
                        </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Form>
            )}

        </Formik>

    )
};

export default ChangePassword;