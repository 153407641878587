import React from 'react';
import {useTranslation, Trans} from "react-i18next";
import {Link} from "react-router-dom";

export default () => {

    const { t } = useTranslation();

    const txtHTML = t("terms:termsText");

    function createMarkup(htmlFragment) {
        return {__html: htmlFragment};
    }

    return (
        <div className={"flex-display-center"}>
            <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                <div className="px-30">
                    <h1>{t("terms:pageHeader")}</h1>

                    <div dangerouslySetInnerHTML={createMarkup(txtHTML)} className={"text-muted m-t-20 m-b-20"} />
                </div>
            </div>
        </div>
    )
};