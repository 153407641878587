import { AuthHttpClient } from "../services/AuthHttpClient";

export const sendContactEmail = (email, subject, emailBody) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('emailBody', emailBody);
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/contact/send ', {
        method: 'POST',
        body: formData
    });
};