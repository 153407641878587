import React, { useState, useEffect } from 'react';
import * as userApi from '../../http/UserApi';
import * as authApi from '../../http/AuthAPI';

export default () => {

    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        userApi
            .getAllUsers()
            .then(response => response.json())
            .then((users) => setUsers(users));

        authApi
            .getCurrentUser()
            .then(response => response.json())
            .then(currentUser => setCurrentUser(currentUser));
    }, []);



    return (
        <div>
            <h3>Users</h3>

            {
                currentUser &&
                <b>logged in: {currentUser.email}</b>
            }

            <table className="table table-bordered">
                <tbody>
                {
                    users.map((user) => {
                        return (
                            <tr key={user.id}>
                                <td>{user.email}</td>
                                <td>{user.name}</td>
                                <td>{user.surname}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    );
}