import {getDefaultTokenService} from './TokenProviderFactory';
import ReduxStore from '../store/store';
import * as AuthActions from '../actions/authActions';

export const AuthHttpClient = async (input, init, bearer) => {
    let accessToken = bearer;

    if (!bearer) {
        accessToken = await getDefaultTokenService().getAccessToken();
    }

    if (!accessToken) {
       ReduxStore.dispatch(AuthActions.logout());
    }

    init = init || {};

    init.headers = {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(input, init);

    if (response.status in [401]) {
        ReduxStore.dispatch(AuthActions.logout());
    }

    return response;
};