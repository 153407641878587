import TokenProviderService from "./TokenProviderService";

export function getUserTokenService() {
    return new TokenProviderService(
        'jwt-access-token',
        'jwt-refresh-token',
        'jwt-credentials-expiration-date'
    );
}

export function getAdminTokenService() {
    return new TokenProviderService(
        'tmp-admin-jwt-access-token',
        'tmp-admin-jwt-refresh-token',
        'tmp-admin-jwt-credentials-expiration-date'
    );
}

export function getDefaultTokenService() {
    if (getAdminTokenService().hasCredentials()) {
        return getAdminTokenService();
    }

    return getUserTokenService();
}