export const validate = (currentUser, t) => {

    return (values) => {
        const errors = {};
        if (!currentUser.socialAccount && !values.currentPassword) {
            errors.currentPassword = t('changePassword:validate.currentPassRequired');
        }

        if (!values.newPassword) {
            errors.newPassword = t('changePassword:validate.newPassRequired');
        }

        if (!values.passwordRepeat) {
            errors.passwordRepeat = t('changePassword:validate.passRepeatRequired');
        }

        if (values.newPassword && values.newPassword.length < 8) {
            errors.newPassword = t('changePassword:validate.passLimit');
        }

        if (values.newPassword && (values.newPassword !== values.passwordRepeat)) {
            errors.passwordRepeat = t('changePassword:validate.dontMatch');
        }

        return errors;
    };

};