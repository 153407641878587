export const CURRENT_USER_LOGIN_FAILURE = 'CURRENT_USER_LOGIN_FAILURE';
export const CURRENT_USER_LOGIN_SUCCESS = 'CURRENT_USER_LOGIN_SUCCESS';
export const CURRENT_USER_LOGOUT = 'CURRENT_USER_LOGOUT';
export const CURRENT_USER_LOGIN_ATTEMPT_FAILED = 'CURRENT_USER_LOGIN_ATTEMPT_FAILED';
export const CURRENT_USER_LOGIN_ATTEMPT_SUCCESS = 'CURRENT_USER_LOGIN_ATTEMPT_SUCCESS';

export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';

export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';

export const PASSWORD_RESET_HASH_CREATED = 'PASSWORD_RESET_HASH_CREATED';
export const PASSWORD_RESET_HASH_FAILURE = 'PASSWORD_RESET_HASH_FAILURE';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';

export const CURRENT_USER_INVOICE = 'CURRENT_USER_INVOICE';