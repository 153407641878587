export const validatePassword = (t) => {

    return values => {
        const errors = {};

        if (!values.newPassword) {
            errors.newPassword = t('profile:validate.passRequired');
        }

        if (values.newPassword && values.newPassword.length < 8) {
            errors.newPassword = t('profile:validate.passLimit');
        }

        if (values.newPassword && (values.newPassword !== values.confirmNewPassword)) {
            errors.confirmNewPassword = t('profile:validate.dontMatch');
        }

        return errors;
    }
};