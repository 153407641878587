import {HttpClient} from "../services/HttpClient";

export const createResetPasswordRequest = (email,portalName) => {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('resetUrl', RESET_PASSWORD_LINK_URL);
    formData.append('portalName', portalName);
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/forgot-password', {
        method: 'POST',
        body: formData
    });
};

export const validateResetToken = (restToken) => {
    let formData = new FormData();
    formData.append('resetToken', restToken);

    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/validate-reset-token', {
        method: 'POST',
        body: formData
    });
};

export const resetPassword = (resetToken, newPassword, portalName) => {
    let formData = new FormData();
    formData.append('newPassword', newPassword);
    formData.append('resetToken', resetToken);
    formData.append('portalName', portalName);

    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/reset-password', {
        method: 'POST',
        body: formData
    });
};