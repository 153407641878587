import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Form, Formik, Field} from 'formik';
import InputTextField from "../commonComponents/InputTextField";
import * as ProfileApi from "../../http/UserProfileApi";
import * as DawaApi from "../../http/DawaAPI";
import {trackPromise} from "react-promise-tracker";
import validateEditProfile from "./validateEditProfile";
import FormErrors from "../commonComponents/FormErrors";
import Modal from "./Modal";
import {Trans, useTranslation} from "react-i18next";
import CustomPrompt from "../commonComponents/CustomPrompt";
import AsyncAutoSelectFiled from "../commonComponents/AsyncAutoSelectFiled";
import {transformAddressObjectToDawaString, transformDawaObjectToAddressObject} from "../../services/DawaTransformer"


let historyUnblock = null;
let transitionPath = null;
const excludeFromBlockingUrls = ['/slet_profil'];

const EditProfile = (props) => {
    const { t } = useTranslation()
    const history = useHistory();

    let [profile, setProfile] = useState({
        "id": 0,
        "email": "",
        "name": "",
        "surname": "",
        "companyName": "",
        "profilePicture": null,
        "location": null,
        "shippingLocation": null,
        "facebookAccount": null,
        "linkedInAccount": null,
        "acceptMarketing": false,
        "cvrNumber": "",
        "ean": "",
        "subscribedToPrintedMagazine": false,
        "iterasUserType": "private",
        "billing_email": "",
        "phone": ""
    });

    let [addressOptions, setAddressOptions] = useState([]);
    let [isSearchingAddress, setIsSearchingAddress] = useState(false);
    let [selectedAddress, setSelectedAddress] = useState([]);

    let [isModalOpened, changeIsModalOpened] = useState(false);
    let [isLeaveModalOpened, changeIsLeaveModalOpened] = useState(false);
    let formikFormReference = useRef();

    useEffect(() => {
        (async () => {
            let response = await trackPromise(ProfileApi.getProfile());
            if (response.status === 200) {
                let responseBody = await response.json();
                setProfile(responseBody);

                if (responseBody.location) {
                    let address = transformAddressObjectToDawaString(responseBody.location)
                    let dawaAddressesResponse = await trackPromise(
                        DawaApi.findAddress(address, DawaApi.dawaAddressTypes.directAddress)
                    );

                    if (dawaAddressesResponse.status === 200) {
                        let dawaAddresses = await dawaAddressesResponse.json();

                        if (dawaAddresses.length > 0) {
                            setSelectedAddress([dawaAddresses[0]]);
                        }
                    }
                }
            }
        })();
    }, []);


    useEffect(() => {
        transitionPath = null;
        historyUnblock = history.block(path => {

            if (excludeFromBlockingUrls.includes(path.pathname)) {
                historyUnblock();
                return true;
            }

            if (isFormDirty()) {
                changeIsLeaveModalOpened(true);
                transitionPath = path.pathname;
                return false;
            } else {
                return true
            }

        });
        return () => { historyUnblock(); }
    }, [])

    async function handleSearchAddress(query) {
        setIsSearchingAddress(true);

        let dawaAddresses = await DawaApi.findAddress(query, DawaApi.dawaAddressTypes.directAddress);

        if (dawaAddresses.status === 200) {
            setAddressOptions(await dawaAddresses.json());
        }

        setIsSearchingAddress(false);
    }

    async function handleAddressSelect(selected) {
        setSelectedAddress(selected);
    }

    async function handleFormSubmit(values, {setFieldError}) {

        let dawaObject = selectedAddress.length > 0 ? selectedAddress[0] : null;

        if (
            profile.subscribedToPrintedMagazine &&
            (dawaObject === null || (dawaObject && dawaObject.type !== DawaApi.dawaAddressTypes.directAddress))
        ) {
            setFieldError('address', t('profile:validate.addressRequired'))
            return;
        }

        let address = transformDawaObjectToAddressObject(dawaObject)

        let updateResponse = await trackPromise(ProfileApi.updateProfile(
            values.firstName,
            values.lastName,
            values.companyName,
            address.city,
            address.address,
            address.zip,
            values.cvrNumber,
            values.ean,
            values.acceptMarketing,
            values.billing_email,
            values.phone
        ));

        if (!transitionPath) {
            historyUnblock();
            let responseBody = await updateResponse.json();
            if(responseBody.code === 44){
                setFieldError('ean', responseBody.msg)
            }else{
                history.push("/profiloversigt");
            }
        }
    }

    function confirmDataStorage(store) {
        changeIsLeaveModalOpened(false);
        historyUnblock();

        if (store) {
            historyUnblock();
            submitForm();
        }

        if (transitionPath) {
            history.push(transitionPath);
        } else {
            history.push('/');
        }
    }

    function submitForm() {
        formikFormReference.current.handleSubmit();
    }

    function isFormDirty() {
        return formikFormReference.current.dirty && formikFormReference.current.submitCount === 0;
    }

    function handleClickUploadAvatar(e) {
        e.preventDefault();
        history.push('/upload-avatar');
    }



    return (
        <div id={"edit-profile"}>
            <Modal show={isModalOpened} closeModal={() => changeIsModalOpened(false)} />
            <div className={"row m-b-30 edit-profile-icon"}>
                <div className={"col text-center"}>
                    {
                        profile.profilePicture &&
                        <img onClick={handleClickUploadAvatar}
                             className="rounded-circle"
                             src={MEMBERS_API_URL + "/uploads/user/avatar/" + profile.profilePicture}/>
                    }

                    {
                        !profile.profilePicture &&
                        <img onClick={handleClickUploadAvatar}
                             className="rounded-circle"
                             src={"../../../public/assets/images/design/grayed_man.svg"}/>
                    }
                </div>
            </div>

            <div className={"row edit-profile-inputs"}>
                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                <Formik
                    innerRef={formikFormReference}
                    initialValues={{
                        firstName: profile.name,
                        lastName: profile.surname,
                        companyName: profile.companyName,
                        cvrNumber: profile.cvrNumber,
                        ean: profile.ean,
                        acceptMarketing: profile.acceptMarketing,
                        streetAddress: profile.location ? profile.location.address : '',
                        zipCode: profile.location ? profile.location.zip : '',
                        city: profile.location ? profile.location.city : '',
                        address: '',
                        billing_email: profile.billing_email,
                        phone: profile.phone
                    }}
                    enableReinitialize={true}
                    validate={validateEditProfile(profile, t)}
                    onSubmit={handleFormSubmit}
                >
                    {({ errors, touched, setFieldValue }) => (
                        <Form noValidate={true}>
                            {
                                isLeaveModalOpened
                                && <CustomPrompt
                                    message={t('profile:edit.confirmLeave.message')}
                                    title={t('profile:edit.confirmLeave.title')}
                                    saveBtnLabel={t('profile:edit.confirmLeave.saveBtnLabel')}
                                    dontSaveBtnLabel={t('profile:edit.confirmLeave.dontSaveBtnLabel')}
                                    confirmDataStorage={confirmDataStorage} />
                            }
                            <FormErrors errors={errors} touched={touched} />
                            <InputTextField name={"firstName"} type="text" label={t("profile:edit.firstName")} />
                            <InputTextField name={"lastName"} type="text" label={t("profile:edit.lastName")} />

                            {
                                profile.iterasUserType === 'company' &&
                                <InputTextField name={"companyName"} type="text" label={t("profile:edit.companyName")} />
                            }

                            {
                                <InputTextField name={"phone"} label={t("profile:edit.phone")} />
                            }

                            {
                                profile.iterasUserType === 'company' &&
                                <InputTextField name={"cvrNumber"} min="0" type="number" label={t("profile:edit.cvrNumber")} />
                            }

                            {
                                profile.iterasUserType === 'company' &&
                                <InputTextField hasError={errors.ean} name={"ean"} min="0" type="text" label="EAN-Nummer" />
                            }

                            <AsyncAutoSelectFiled
                                hasError={errors.address}
                                id={"address"}
                                name={"address"}
                                label={t("profile:edit.streetAddress")}
                                options={addressOptions}
                                labelKey={option => option.tekst}
                                minLength={2}
                                isLoading={isSearchingAddress}
                                onSearch={handleSearchAddress}
                                onChange={handleAddressSelect}
                                selected={selectedAddress}
                                useCache={false}
                                delay={50}
                            />
                            {
                                profile.iterasUserType === 'company' &&
                                <InputTextField name={"billing_email"} type="text" label={t("profile:edit.billing_email")} />
                            }
                            <div className={"row mt-4"}>
                                <div className={"col text-center"}>
                                    { profile.subscribedToPrintedMagazine &&
                                        <p>
                                            <Link to={"/leveringsadresse"}
                                                  className={"edit text-uppercase color-primary waves-effect"}>
                                                {t("profile:edit.otherDeliveryAddress")} <i className={"mdi mdi-lead-pencil"} />
                                            </Link>
                                        </p>
                                    }

                                    <div className="form-group form-check">

                                        <label className="form-check-label" htmlFor="exampleCheck1">
                                            <Field type="checkbox" name="acceptMarketing" className="form-check-input" id="exampleCheck1" />
                                            <Trans i18nKey="profile:edit.sendNews"><br /></Trans>
                                        </label>
                                        <a className={"ml-1"}
                                           onClick={(e) => {e.preventDefault(); changeIsModalOpened(true)}}
                                           href={"#"}
                                        >{t('profile:edit.readMore')}</a>
                                    </div>

                                </div>
                            </div>
                            <div className={"row mt-3 mb-4 mb-sm-0"}>
                                <div className={"col text-center"}>
                                    <Link to={"/slet_profil"} className={"text-muted"}>
                                        <i className={"mdi mdi-minus-circle font-18"}></i> &nbsp;
                                        {t("profile:edit.deleteProfile")}
                                    </Link>
                                </div>
                            </div>
                            <div className={"row edit-profile-button"}>

                                <div className={"col text-center"}>
                                    <button type="submit" className={"btn btn-primary waves-effect btn-wide"}>
                                        {t("profile:edit.editProfile")}
                                    </button>

                                    <p className={"mt-3"}>
                                        <Link to={"/profiloversigt"} className={"text-body"}>
                                            {t("profile:edit.cancel")}
                                        </Link>
                                    </p>
                                </div>
                            </div>

                        </Form>
                    )}

                </Formik>

                </div>
            </div>



        </div>
    )
};

export default EditProfile;