import {AuthHttpClient} from "../services/AuthHttpClient";
import {HttpClient} from "../services/HttpClient";

export const login = (username, password) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/auth/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    });
};


export const refreshAccessToken = (refreshToken) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("refresh_token", refreshToken);

    return HttpClient(MEMBERS_API_URL + '/api/v1/auth/token/refresh', {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body: urlencoded
    });
};

export const getCurrentUser = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/current-user');
};