import React from "react";
import {Link} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";

export default () => {

    const { t } = useTranslation();

    return (
        <div className="message-container">
            <div className="message-wrapper">
                <div className="message-area">

                    <div className="text-center">
                        <img
                            src={"../../../public/assets/images/design/ok_green.svg"}
                            alt={"Ok"} />
                        <p className={"msg m-t-20"}>
                            <strong>{t("changePassword:passwordUpdated")}</strong>
                        </p>
                    </div>
                    <div className="message-buttons text-center">
                        <Link to={"/profiloversigt"} className="btn btn-primary waves-effect text-nowrap btn-wide">
                            {t("changePassword:backToProfile")}
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}