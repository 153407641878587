import React, {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import * as UserApi from "../../http/UserApi";
import {trackPromise} from "react-promise-tracker";
import {useTranslation, Trans} from "react-i18next";

export default () => {

    const RESTORE_NONE = 1;
    const RESTORE_SUCCESS = 2;
    const RESTORE_FAILED = 3;

    let { token } = useParams();

    const { t } = useTranslation();

    let [restoreStatus, setRestoreStatus] = useState(RESTORE_NONE);

    useEffect(() => {
        (async () => {
            let response = await trackPromise(UserApi.restoreProfile(token));
            if (response.status === 200) {
                setRestoreStatus(RESTORE_SUCCESS);
            } else {
                setRestoreStatus(RESTORE_FAILED);
            }
        })();
    }, []);

    if (restoreStatus === RESTORE_SUCCESS) {

        return (
			<div className="content-page" style={{margin: "0"}} >
				<div className="content">
					<div className="message-container">
						<div className="message-wrapper">
							<div className="message-area">

								<div className="text-center">
									<img
										src={"../../../public/assets/images/design/ok_green.svg"}
										alt={"Ok"}/>
									<p className={"msg m-t-20"}>
										<strong>{t("profile:delete.restoreSuccess")}</strong>
									</p>
									<p className="msg-small">
										<Trans i18nKey="profile:canLogin"><Link to={"/auth/login"}>Click here</Link></Trans>
									</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
        )

    } else if (restoreStatus === RESTORE_FAILED) {

        return (
			<div className="content-page" style={{margin: "0"}} >
				<div className="content">
					<div className="message-container">
						<div className="message-wrapper">
							<div className="message-area">

								<div className="text-center">
									<img
										src={"../../../public/assets/images/design/cancel_red.svg"}
										alt={"Failed"}/>
									<p className={"msg m-t-20"}>
										<strong>{t("profile:errors.couldNotRestore")}</strong>
									</p>
									<p className="msg-small">
										{t("profile:tryAgain")}
									</p>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
        )

    } else {
        return (
            <div> </div>
        )

    }
}