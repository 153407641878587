// https://github.com/gfazioli/react-toggle
import React from 'react';
import Form from "react-bootstrap/Form";
import InterestsPanel from "./InterestsPanel";
import {trackPromise} from "react-promise-tracker";
import * as portalApi from "../../http/PortalAPI";
import * as userApi from "../../http/UserApi";
import { withTranslation } from 'react-i18next';
import ContentBottom from "../commonComponents/ContentBottom";




const extractUniquePortalIds = (userInterests) => {
    // extract portalIds
    let filteredIds = userInterests.map((interest) => {
        return interest.portalId;
    });
    // remove duplicates
    let uniqueIds = [...new Set(filteredIds)];
    return uniqueIds;
};

const filterPortalsByIds = (portals, selectedPortalIds, contains = true) => {
    let filteredPortals = portals.filter((portal) => {
        if (portal.interests.length > 0) {
            return (contains)
                ? (selectedPortalIds.indexOf(portal.id) !== -1)
                : (selectedPortalIds.indexOf(portal.id) === -1);
        } else {
            return false;
        }
    })
    return filteredPortals;
};

class InterestsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            interestsByPortals: [],
            userInterests: [],
            checkedSwitches: []
        };
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    componentDidMount() {
        // wrapper to show and hide loading spinner
        trackPromise(
            // fetch all portals and interests from the server
            portalApi
                .getPortalsWithInterests()
                .then(response => response.json())
                .then((interestsByPortals) => {
                    this.setState({
                        interestsByPortals
                    })
                })
        )
        trackPromise(
            // fetch the interests selected by the user
            userApi
                .getUserInterests()
                .then(response => response.json())
                .then((userInterests) => {

                    let checkedSwitches = userInterests.map((interest) =>{
                        return interest.id;
                    });

                    this.setState({
                        userInterests,
                        checkedSwitches
                    })
                })
        )
    }

    handleSwitchChange(event) {
        // switchId equals user interest ID
        const switchId = parseInt(event.target.id);
        const isChecked = event.target.checked;
        let checkedSwitches = this.state.checkedSwitches;

        if (isChecked) {
            // add switchId to array of checked switches and send API request
            checkedSwitches.push(switchId);

            userApi.addUserInterest(switchId)
                .then(response => response.json())
                .then((userInterests) => {

                    this.setState({
                        checkedSwitches
                    })
                })

        } else {
            let index = checkedSwitches.indexOf(switchId);

            if (index !== -1) {
                // remove switchId from array of checked switches and send API request
                checkedSwitches.splice(index, 1);

                userApi.removeUserInterest(switchId)
                    .then(response => response.json())
                    .then((userInterests) => {

                        this.setState({
                            checkedSwitches
                        })
                    })
            }
        }
    }


    render() {

        const { t } = this.props;

        const portalHTML = {
            'MB.DK': {
                'imageSource': '../../../public/assets/images/design/maskinbladet.svg',
                'text': 'Maskinbladet - Landbrugsnyheder om politik, erhverv, bedrifter, mennesker og maskiner, PowerGear-tests samt brugte traktorer og andre landbrugsmaskiner.'
            },
            'FM.DK': {
                'imageSource': '../../../public/assets/images/design/fritidsmarkedet.svg',
                'text': 'Fritidsmarkedet er mediet, der inspirerer mennesker på landet med informative artikler og videoer, som giver læserne nyttig og brugbar viden.'
            },
            'MT.DK': {
                'imageSource': '../../../public/assets/images/design/maskinteknik.svg',
                'text': 'Maskinteknik - den bredeste dækning af entreprenør- og anlægsbranchen med fokus på maskiner, udførende, leverandører og projekter.'
            },
            'GT.DK': {
                'imageSource': '../../../public/assets/images/design/gronteknik.svg',
                'text': 'Grøn Teknik - den bredeste dækning af den grønne sektor med fokus på maskiner, mennesker og projekter indenfor skov, anlæg, have/park m.m.'
            }
        };

        let uniquePortalIds = extractUniquePortalIds(this.state.userInterests);
        let selectedPortals = filterPortalsByIds(this.state.interestsByPortals, uniquePortalIds, true);
        let otherPortals = filterPortalsByIds(this.state.interestsByPortals, uniquePortalIds, false);

        return (
            <Form className={"form-interests"}>
            <div id={"interests"}>
                <div className={"row mt-5"}>
                    <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                        <div className="px-30">
                            <h1>{t("interests:heading1")}</h1>
                            <p className={"text-muted m-t-10 m-b-20"}>
                                {t("interests:introText")}
                            </p>
                        </div>
                    </div>
                </div>

                {
                    selectedPortals.map((portalData, index) => {
                        return (
                            <div className={"row m-b-10"} key={index}>
                                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                                    <InterestsPanel
                                        portalHTML={portalHTML[portalData.externalPID]}
                                        styleActive={true}
                                        portalData={portalData}
                                        checkedSwitches={this.state.checkedSwitches}
                                        handleSwitchChange={this.handleSwitchChange}
                                    />

                                </div>
                            </div>
                        )
                    })
                }

                {
                    (otherPortals.length > 0) &&
                    <div className={"row mt-5"}>
                        <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>
                            <div className="px-30">
                                <h1>{t("interests:heading2")}</h1>
                            </div>
                        </div>
                    </div>
                }

                {
                    otherPortals.map((portalData, index) => {
                        return (
                            <div className={"row m-t-10"} key={index}>
                                <div className={"col-12 col-lg-10 col-xl-8 mx-auto"}>

                                    <InterestsPanel
                                        portalHTML={portalHTML[portalData.externalPID]}
                                        styleActive={false}
                                        portalData={portalData}
                                        checkedSwitches={this.state.checkedSwitches}
                                        handleSwitchChange={this.handleSwitchChange}
                                    />

                                </div>
                            </div>
                        )
                    })
                }

            </div>

                <ContentBottom />

            </Form>
        )
    }
}

export default withTranslation()(InterestsPage);