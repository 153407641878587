import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tokenProviderService from "../../services/TokenProviderService";
import {loginSuccess, logout} from "../../actions/authActions";
import {loginAttemptSuccess} from "../../actions/authActions";
import * as AuthApi from "../../http/AuthAPI";
import {updateLastLoginTime} from "../../http/UserApi";

let RemoteLogin = (props) => {
    let { credentials } = useParams();
    let history = useHistory();

    credentials = atob(credentials);
    credentials = JSON.parse(credentials);

    let externalPID = null;
    let portalReturnUrl = null;

    if ('portalExternalId' in credentials) {
        externalPID = credentials.portalExternalId;
    }

    if ('returnurl' in credentials) {
        portalReturnUrl = credentials.returnurl;
    }

    (async () => {
        await tokenProviderService.setCredentials(
            credentials.token,
            credentials.refresh_token,
            false,
            externalPID,
            portalReturnUrl
        );

        try {
            const currentUserResponse = await AuthApi.getCurrentUser();
            if (currentUserResponse.status === 200) {
                props.dispatch(loginSuccess());
                props.dispatch(loginAttemptSuccess());
                await updateLastLoginTime();
            }
        } catch (e) {
            props.dispatch(logout());
        } finally {
            if (typeof credentials.forwardurl !== 'undefined') {
                history.push(credentials.forwardurl);
            }else {
                history.push('/');
            }
        }

    })();

    return (
        <div>Loading ...</div>
    )
};

export default connect()(RemoteLogin);
