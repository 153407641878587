import { AuthHttpClient } from "../services/AuthHttpClient";
import {HttpClient} from "../services/HttpClient";

export const getAllUsers = () => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/all');
};

export const changeUserPassword = (oldPassword, newPassword, newPasswordRepeat) => {
    const formData = new FormData();

    if (oldPassword) {
        formData.append('oldPassword', oldPassword);
    }

    formData.append('newPassword', newPassword);
    formData.append('newPasswordRepeat', newPasswordRepeat);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/change-password', {
        method: 'POST',
        body: formData
    });
};

export const getUserInterests = () => {
    return AuthHttpClient( MEMBERS_API_URL + '/api/v1/private/users/interests', {
        method: 'GET'
    })
};

export const addUserInterest = (interestId) => {
    let formData = new FormData();
    formData.append('interestId', interestId);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/interests/add', {
        method: 'POST',
        body: formData
    });
};

export const removeUserInterest = (interestId) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/interests/' + interestId, {
        method: 'DELETE'
    });
};

export const getUserNewsletters = () => {
    return AuthHttpClient( MEMBERS_API_URL + '/api/v1/private/users/newsletters', {
        method: 'GET'
    })
};

export const addUserNewsletter = (magazineId) => {
    let formData = new FormData();
    formData.append('magazineId', magazineId);

    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/newsletters/add', {
        method: 'POST',
        body: formData
    });
};

export const removeUserNewsletter = (magazineId) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/newsletters/' + magazineId, {
        method: 'DELETE'
    });
};

export const deleteUser = () => {
    let queryString = '?restoreUrl=' + encodeURIComponent(RESTORE_DELETED_PROFILE_LINK_URL) +
        '&deleteUrl=' + encodeURIComponent(REMOVE_DELETED_PROFILE_LINK_URL);
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/current-user' + queryString, {
        method: 'DELETE'
    });
};

export const updateLastActivity = (externalPID) => {
    return AuthHttpClient(MEMBERS_API_URL + '/api/v1/private/users/update-last-activity', {
        method: 'PUT'
    });
}

export const updateLastLoginTime = (externalPID = null) => {
    let url = MEMBERS_API_URL + '/api/v1/private/users/update-last-login-time';

    if (externalPID) {
        url += '/' + externalPID
    }

    return AuthHttpClient(url, {
        method: 'PUT'
    });
}

export const restoreProfile = (token) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/restore-deleted-profile/' + token, {
        method: 'GET'
    });
}

export const removeProfile = (token) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/remove-deleted-profile/' + token, {
        method: 'DELETE'
    });
}

export const rejectActivation = (token) => {
    return HttpClient(MEMBERS_API_URL + '/api/v1/public/users/reject-activation/' + token, {
        method: 'DELETE'
    });
}
